import React, { useEffect, useState } from 'react';
import './DeclarationPostDetail.css'
import profile from '../img/profile.png'
import imgex4 from '../img/terrace-detail/imgex4.png'
import good from '../img/terrace-detail/good.png'
import comment from '../img/terrace-detail/comment.png'
import visible_0 from '../img/choice-talk/visible_0.png'
import invisible_0 from '../img/choice-talk/invisible_0.png';
import styled from "styled-components";
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

import ComplainContentsEditPage from './Complain-community/community-edit';
import ComplainSnsContentsPage from './Complain-community/sns-contents';
import ComplainNormalContentsPage from './Complain-community/community-post';


const DeclarationPostUpdate = () => {
  const { id } = useParams(); 
  const midBack = useSelector((state) => state.midBack);  // Redux에서 midBack 데이터를 가져옴
  const [clientData, setClientData] = useState(null);  // 작성자 데이터 상태
  const [complainClientData, setComplainClientData] = useState(null);
  const [content, setContent] = useState(''); 
  const [status, setStatus] = useState('');
  const [serviceId, setServiceId] = useState(null);
  const [loungeData, setLoungeData] = useState(null); 
  const [isEditing, setIsEditing] = useState(false);
  let navigate = useNavigate();


   // API 호출로 데이터 가져오기
   const fetchComplainDetail = async () => {
    try {
      const response = await axios.get(`https://api.midtrc.com/complain/${id}`, {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });
      console.log('Complain Detail Response:', response.data);
      setContent(response.data.content); 
      setClientData(response.data.client);
      setComplainClientData(response.data.complain_client);
      setStatus(response.data.status);
      setServiceId(response.data.service_id);

      // 여기서 바로 fetchLoungeDetail 호출
      fetchLoungeDetail(response.data.service_id);

    } catch (error) {
      console.error('Error fetching complain detail:', error);
    }
};

const fetchLoungeDetail = async (service_id) => {
  try {
    const response = await axios.get(`https://api.midtrc.com/lounge/${service_id}`, {
      headers: {
        'access-token': midBack?.log_data?.access_token
      }
    });
    console.log('Fetched Lounge Data:', response.data);
    setLoungeData(response.data);
  } catch (error) {
    console.error('Error fetching lounge detail:', error);
  }
};

useEffect(() => {
  if (midBack) {
    fetchComplainDetail();
  }
}, [midBack, id]);

useEffect(() => {
  if (serviceId) {
    console.log('Calling fetchLoungeDetail with serviceId:', serviceId);
    fetchLoungeDetail(serviceId);
  }
}, [serviceId]);

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);

    try {
      await axios.put(`https://api.midtrc.com/complain/${id}`, {
        status: newStatus
      }, {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });
      console.log('Status updated successfully');

      // 업데이트 후 데이터 다시 가져오기
      fetchComplainDetail();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };


  const handleEditClick = () => {
    setIsEditing(true); // 수정 버튼 클릭 시 상태 변경
  };


// 작성자 정보 표시
const MyPageContentsBorder = () => {
  return (
    <div className="default-contents-border" style={{ marginBottom: "20px" }}>
      <div className="default-contents-border-top">
        작성자
      </div>
      <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "1fr 4.5fr 4.5fr", gap: "20px" }}>
        <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
          <div><img src={clientData?.profile_url || profile} alt='profile' /></div>
          <div className='default-contents-border-title' style={{ justifyContent: "center" }}>{clientData?.nickname || '없음'}</div>
        </div>
        <div>
          <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
            <div className="default-contents-border-title">
              가입유형
            </div>
            <div style={{ position: "relative" }}>
              <input readOnly={true} className="default-input-line-tag" value={clientData?.client_type === 'user' ? '일반회원' : '사업자회원'} />
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
            <div className="default-contents-border-title">
              이름
            </div>
            <div style={{ position: "relative" }}>
              <input readOnly={true} className="default-input-line-tag" value={clientData?.name || '없음'} />
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
            <div className="default-contents-border-title">
              연락처
            </div>
            <div style={{ position: "relative" }}>
              <input readOnly={true} className="default-input-line-tag" value={clientData?.phone_num || '없음'} />
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
            <div className="default-contents-border-title">
              이메일
            </div>
            <div style={{ position: "relative" }}>
              <input readOnly={true} className="default-input-line-tag" value={clientData?.email || '없음'} />
            </div>
          </div>
        </div>
        <div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
            <div className="default-contents-border-title">
              업종
            </div>
            <div style={{ position: "relative" }}>
              <input readOnly={true} className="default-input-line-tag" value={clientData?.business_industry || '없음'} />
            </div>
          </div>
          <div className='default-contents-border-confirm' style={{ padding: "0", marginTop: "70px" }}>
            <button className='default-contents-border-confirm-button'>더 보기</button>
          </div>
        </div>
      </div>
    </div>
  );
};

    const MyPageContentsBorder2 = () => {
      return(
          <div className="default-contents-border" style={{marginBottom: "20px"}}>
              <div className="default-contents-border-top">
                신고자
              </div>

              <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "1fr 4.5fr 4.5fr", gap: "20px" }}>
                  <div style={{textAlign:"center", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                  <div><img src={complainClientData?.profile_url || profile} alt='profile' /></div>
                    <div className='default-contents-border-title' style={{justifyContent:"center"}}>  {complainClientData?.nickname || "없음"}</div>
                  </div>
                  <div>
                      <div style={{display:"grid", gridTemplateColumns:"1fr 9fr", gap:"20px"}}>
                        <div className="default-contents-border-title">
                          가입유형
                        </div>
                        <div style={{position: "relative"}}>
                          <input readOnly={true} className="default-input-line-tag"   value={complainClientData?.client_type === 'user' ? '일반회원' : '사업자회원'}/>
                        </div>
                      </div>
                      <div style={{display:"grid", gridTemplateColumns:"1fr 9fr", gap:"20px"}}>
                        <div className="default-contents-border-title">
                          이름
                        </div>
                        <div style={{position: "relative"}}>
                          <input readOnly={true} className="default-input-line-tag"value={complainClientData?.name || "없음"}/>
                        </div>
                      </div>
                      <div style={{display:"grid", gridTemplateColumns:"1fr 9fr", gap:"20px"}}>
                        <div className="default-contents-border-title">
                          연락처
                        </div>
                        <div style={{position: "relative"}}>
                          <input readOnly={true} className="default-input-line-tag" value={complainClientData?.phone_num || "없음"} />
                        </div>
                      </div>
                      <div style={{display:"grid", gridTemplateColumns:"1fr 9fr", gap:"20px"}}>
                        <div className="default-contents-border-title">
                          이메일
                        </div>
                        <div style={{position: "relative"}}>
                          <input readOnly={true} className="default-input-line-tag" value={complainClientData?.email || "없음"} />
                        </div>
                      </div>
                      
                  </div>
                  <div>
                      <div style={{display:"grid", gridTemplateColumns:"1fr 9fr", gap:"20px"}}>
                        <div className="default-contents-border-title">
                          신고내용
                        </div>
                        <div style={{position: "relative"}}>
                          <input readOnly={true} className="default-input-line-tag"   value={content || '신고 내용이 없습니다'}  />
                        </div>
                      </div>
                      <div style={{display:"grid", gridTemplateColumns:"1fr 9fr", gap:"20px"}}>
                        <div className="default-contents-border-title">
                          
                        </div>
                        <div style={{position: "relative"}}>
                          <input readOnly={true} className="default-input-tag" value={''}/>
                        </div>
                      </div>
                      <div style={{display:"grid", gridTemplateColumns:"1fr 9fr", gap:"20px"}}>
                        <div className="default-contents-border-title">
                          
                        </div>
                        <div style={{position: "relative"}}>
                          <input readOnly={true} className="default-input-tag" value={''}/>
                        </div>
                      </div>
                      <div style={{display:"grid", gridTemplateColumns:"1fr 9fr", gap:"20px"}}>
                        <div className="default-contents-border-title">
                          처리상태
                        </div>
                        <div style={{display:"flex", alignItems:"center"}}>
                        
                   
                        <select className="default-contents-border-select" style={{textAlign:"center"}} value={status} onChange={handleStatusChange}>
                <option value="waiting">처리전</option>
                <option value="complete">처리완료</option>
              </select>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      )
  }

      const DefaultContentsBorder = () => {
        return(
          <div className='default-contents-border'>
              
              <ComplainContentsEditPage />

      
            {/* 버튼 리스트 */}
            <div className='default-contents-border-confirm'>
              <button className='default-contents-border-confirm-button' onClick={() => {navigate(`/declaration-post-detail/${id}`)}}>취 소</button>
            </div>
            </div>
        )
    }

  return(
    <div>
      <MyPageContentsBorder />
      <MyPageContentsBorder2 />
      <DefaultContentsBorder />
    </div>
  )

}

export default DeclarationPostUpdate;