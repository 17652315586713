import React, { useEffect, useState } from 'react';
import './MessageAddModal.css';
import closeButton from '../img/close-button.png';
import axios from 'axios';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

let ModalContentBorder = styled.div`
    position:relative;
`

const ChoiceModal = ({ modalManager, data }) => {
  const midBack = useSelector((state) => state.midBack);
  
  // 기존 상태 초기값 설정 유지
  const [selectedOption, setSelectedOption] = useState('전체 회원');
  const [messageType, setMessageType] = useState('관리자 메시지');
  const [messageDetail, setMessageDetail] = useState({});
  const [contactId, setContactId] = useState('');
  const [contactLoginId, setContactLoginId] = useState('');

  const fetchMessageDetail = async (id) => {
    try {
      const response = await axios.get(`https://api.midtrc.com/managed_message/${id}`, {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });

      setMessageDetail(response.data);

      // group_type에 따라 메시지 타입 설정
      setMessageType(
        response.data.group_type === 'general' ? '일반' :
        response.data.group_type === 'manage' ? '관리자 메시지' :
        response.data.group_type === 'event' ? '이벤트 메시지' : ''
      );

      // contact_type에 따라 발송 대상 설정
      setSelectedOption(
        response.data.contact_type === 'all' ? '전체 회원' :
        response.data.contact_type === 'general' ? '일반' :
        response.data.contact_type === 'business' ? '사업자' :
        response.data.contact_type === 'female' ? '여성' :
        response.data.contact_type === 'male' ? '남성' : '특정회원'
      );

      if (response.data.contact_type === 'direct') {
        setContactLoginId(response.data.contact_login_id);
        const contactResponse = await axios.get(`https://api.midtrc.com/message/${response.data.contact_id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setContactId(contactResponse.data.contact_id);
      }

    } catch (error) {
      console.error('Error fetching message detail:', error);
    }
  };

  useEffect(() => {
    if (data.messageId) {
      fetchMessageDetail(data.messageId);
    }
  }, [data.messageId]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const ChoiceModalContent = () => {
    return (
      <div style={{ marginLeft: "-150px" }}>
        <div style={{ boxSizing: "border-box", padding: "20px 10px 10px 10px", display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
          <div>
            <div className='default-contents-border-title'>
              메시지 타입
            </div>

            <div className='default-contents-border-title'>
              발송 시간
            </div>

            <div className='default-contents-border-title'>
              발송대상
            </div>
            {selectedOption === '특정회원' && (
              <div className='default-contents-border-title'>
                특정회원 아이디
              </div>
            )}
            <div className='default-contents-border-title'>
              메시지 내용
            </div>
          </div>
          <div>
          <div>
          <select className='default-contents-border-select' value={messageType} onChange={(e) => setMessageType(e.target.value)} disabled>
            <option value="관리자 메시지">관리자 메시지</option>
            <option value="일반">일반</option>
            <option value="이벤트 메시지">이벤트 메시지</option>
          </select>
            </div>

            <div>
                <input className="default-input-tag2" value={new Date(messageDetail.created_at).toLocaleString()} readOnly />
            </div>

            <div>
            <select className='default-contents-border-select' value={selectedOption} onChange={handleOptionChange} disabled style={{ marginTop: "4.5px" }}>
              <option value="전체 회원">전체 회원</option>
              <option value="일반">일반</option>
              <option value="사업자">사업자</option>
              <option value="여성">여성</option>
              <option value="남성">남성</option>
              <option value="특정회원">특정회원</option>
            </select>
            </div>
            {selectedOption === '특정회원' && (
              <div>
                <input className="default-input-tag2" value={contactLoginId || contactId} readOnly />
              </div>
            )}
            <div style={{ marginTop: "10px" }}>
              <textarea className='default-contents-textarea' style={{ height: "400px" }} value={messageDetail.content || ''} readOnly></textarea>
            </div>
          </div>
        </div>
        <div className='default-contents-border-confirm'>
          <button className='default-contents-border-confirm-button' onClick={() => { modalManager(null); }}>삭 제</button>
        </div>
      </div>
    );
  };

  return (
    <div className="default-modal-border" style={{ height: "100vh" }} onClick={(e) => { e.stopPropagation(); modalManager(null); }}>
      <div className="members-detail-modal-border" onClick={(e) => { e.stopPropagation(); }} style={{ background: "#fff" }}>
        <div className="default-contents-border-top" style={{ width: "100%", justifyContent: "space-between", minHeight: "40px", backgroundColor: "white", borderRadius: "4px 4px 0px 0px" }}>
          <div>
            메시지 내용
          </div>
          <img
            onClick={() => { modalManager(null); }}
            src={closeButton} alt="close-box" style={{ width: "14px", height: "14px", cursor: "pointer" }}
          />
        </div>
        <div className="members-detail-modal-contents-border" style={{ background: "#fff" }}>
          <div>
            <ChoiceModalContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoiceModal;
