import { createSlice } from "@reduxjs/toolkit";


const loginSlice = createSlice({
    name: 'midBack',
    initialState: {
        ip: null, // ip 저장
        log_time: null, // 로그인 시간
        is_saved_data: false, // 로그인 저장하기
        is_none_member: false, // 비회원 로그인 여부
        is_sns_member: false, // sns 회원 로그인 여부
        is_id_member: false, // id 회원 로그인 여부
        log_data: null // 로그인 데이터
    },
    reducers : {
        Logined(state, info){
            state.log_data = info.payload
        },
        logout(state, info){
            state.token = null
            state.ip = null
            state.log_time = null
            state.is_saved_data = false
            state.is_none_member = false
            state.log_data = null
        }
    }
})


export let { Logined, logout } = loginSlice.actions;

export default loginSlice.reducer;