import React, { useEffect, useState } from 'react';
import './Announcement.css';
import imgex3 from '../img/annoucement/imgex3.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

const Announcement = () => {
    let navigate = useNavigate();
    const midBack = useSelector((state) => state.midBack);
    const [recruitList, setRecruitList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSet, setCurrentSet] = useState(1);
    const [currentItemsCount, setCurrentItemsCount] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState('전체보기');
    const [showPaused, setShowPaused] = useState(false);
    const [showPopular, setShowPopular] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [searchQuery, setSearchQuery] = useState(''); // 검색어 상태 추가
    const [appliedSearchQuery, setAppliedSearchQuery] = useState(''); // 실제 검색에 사용되는 검색어 상태 추가
    const itemsPerPage = 10;
    const pagesPerSet = 5;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/recruit', {
                    headers: {
                        'access-token': midBack?.log_data?.access_token
                    }
                });
                const sortedData = response.data.map(recruit => ({ ...recruit, isSelected: false })).sort((a, b) => b.id - a.id);
                setRecruitList(sortedData);
            } catch (error) {
                console.error('Error fetching the recruit data:', error);
            }
        };

        fetchData();
    }, []);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNextSet = () => {
        setCurrentSet(prevSet => prevSet + 1);
        setCurrentPage((currentSet * pagesPerSet) + 1);
    };

    const handlePrevSet = () => {
        setCurrentSet(prevSet => prevSet - 1);
        setCurrentPage(((currentSet - 2) * pagesPerSet) + 1);
    };

    const handleFilterChange = (e) => {
        setSelectedFilter(e.target.value);
        setCurrentPage(1);
        setCurrentSet(1);
    };

    const handlePopularChange = () => {
        setShowPopular(!showPopular);
        setCurrentPage(1);
        setCurrentSet(1);
    };

    const handleAllSelect = () => {
        const newSelectedState = !isAllSelected;
        setIsAllSelected(newSelectedState);
        setRecruitList((prevList) =>
            prevList.map((recruit) => ({
                ...recruit,
                isSelected: newSelectedState,
            }))
        );
    };

    const handleRecruitSelect = (id) => {
        setRecruitList((prevList) =>
            prevList.map((recruit) =>
                recruit.id === id ? { ...recruit, isSelected: !recruit.isSelected } : recruit
            )
        );
    };

    const handleDeleteSelected = async () => {
        const selectedIds = recruitList.filter(recruit => recruit.isSelected).map(recruit => recruit.id);
        if (selectedIds.length === 0) {
            alert('삭제할 공고를 선택하세요.');
            return;
        }

        if (!window.confirm('정말 공고를 삭제하시겠습니까?')) {
            return;
        }

        try {
            await Promise.all(
                selectedIds.map(id =>
                    axios.delete(`https://api.midtrc.com/recruit/${id}`, {
                        headers: {
                            'access-token': midBack?.log_data?.access_token
                        }
                    })
                )
            );

            setRecruitList(prevList => prevList.filter(recruit => !selectedIds.includes(recruit.id)));
            setIsAllSelected(false);
        } catch (error) {
            console.error('Error deleting recruit:', error);
            alert('공고 삭제 중 오류가 발생했습니다.');
        }
    };

    const filterRecruitList = () => {
        let filteredList = recruitList;

        if (selectedFilter === '미드나잇 알바') {
            filteredList = filteredList.filter(recruit => recruit.midnight_yn);
        } else if (selectedFilter === '단기 알바') {
            filteredList = filteredList.filter(recruit => recruit.recruit_type === 'short_term');
        } else if (selectedFilter === '상시채용') {
            filteredList = filteredList.filter(recruit => recruit.recruit_type === 'always');
        }

        if (showPaused) {
            filteredList = filteredList.filter(recruit => recruit.status === 'paused');
        }

        if (showPopular) {
            filteredList = filteredList.filter(recruit => recruit.popular_yn);
        }

        if (appliedSearchQuery) {
            filteredList = filteredList.filter(
                recruit =>
                    recruit.recruit_title.toLowerCase().includes(appliedSearchQuery.toLowerCase()) ||
                    recruit.nickname.toLowerCase().includes(appliedSearchQuery.toLowerCase())
            );
        }

        return filteredList;
    };

    const handleSearch = () => {
        setAppliedSearchQuery(searchQuery); // 검색 버튼 클릭 시 검색어를 적용
        setCurrentPage(1);
        setCurrentSet(1);
    };

    const DefaultPagenation = () => {
        const filteredRecruitList = filterRecruitList();
        const totalItems = filteredRecruitList.length;
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const startPage = (currentSet - 1) * pagesPerSet + 1;
        const endPage = Math.min(currentSet * pagesPerSet, totalPages);

        return (
            <div style={{ display: "flex", height: "50px", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                {startPage > 1 && <div onClick={handlePrevSet} className="default-contents-pagenation-button">{"<"}</div>}
                {Array.from({ length: (endPage - startPage + 1) }, (_, i) => startPage + i).map(page => (
                    <div
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className="default-contents-pagenation-button"
                        style={{ color: page === currentPage ? 'black' : 'grey' }}
                    >
                        {page}
                    </div>
                ))}
                {endPage < totalPages && <div onClick={handleNextSet} className="default-contents-pagenation-button">{">"}</div>}
            </div>
        );
    };

    const AnnouncementList = ({ recruit }) => {
        return (
            <div className="default-contents-border-top"
                style={{
                    fontSize: "14px",
                    color: "black",
                    height: "50px",
                    display: "grid",
                    gridTemplateColumns: "0.3fr 0.5fr 2fr 2fr 1fr 1.3fr 1.3fr 0.8fr"
                }}>
                <input
                    type="checkbox"
                    className="default-contents-border-check-box"
                    checked={recruit.isSelected}
                    onChange={() => handleRecruitSelect(recruit.id)}
                />
                <div style={{ width: "100%", textAlign: "center" }}>
                    {recruit.id}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={recruit.main_image_url || imgex3} alt='imgex3' style={{ height: "46px", paddingTop: "4px" }} />
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {recruit.recruit_title}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {recruit.nickname}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {recruit.midnight_yn
                        ? '미드나잇 알바'
                        : recruit.recruit_type === 'short_term'
                            ? '단기 알바'
                            : recruit.recruit_type === 'always'
                                ? '상시채용'
                                : '기타'}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {new Date(recruit.created_at).toLocaleDateString()}
                </div>
                <div onClick={() => navigate(`/announcement-detail/${recruit.id}`)} style={{ width: "100%", textAlign: "center", color: "blue", cursor: "pointer" }}>
                    상세보기
                </div>
            </div>
        )
    };

    const DefaultContentsBorder = () => {
        const filteredRecruitList = filterRecruitList();
        const startIndex = (currentPage - 1) * itemsPerPage;
        const currentItems = filteredRecruitList.slice(startIndex, startIndex + itemsPerPage);
        const currentLength = currentItems?.length

        useEffect(() => {
            const currentLength = currentItems.length;
            setCurrentItemsCount(currentLength);
        }, [currentItems]);

        // setCurrentItemsCount(currentLength); // 현재 페이지의 아이템 수 업데이트

        return (
            <div className="default-contents-border">
                <div className="default-contents-border-top"
                    style={{
                        fontSize: "14px",
                        height: "50px",
                        display: "grid",
                        gridTemplateColumns: "0.3fr 0.5fr 2fr 2fr 1fr 1.3fr 1.3fr 0.8fr"
                    }}>
                    <input
                        type="checkbox"
                        className="default-contents-border-check-box"
                        onChange={handleAllSelect}
                        checked={isAllSelected}
                    />
                    <div style={{ width: "100%", textAlign: "center" }}>번호</div>
                    <div style={{ width: "100%", textAlign: "center" }}>썸네일</div>
                    <div style={{ width: "100%", textAlign: "center" }}>업체 한줄 소개</div>
                    <div style={{ width: "100%", textAlign: "center" }}>아이디</div>
                    <div style={{ width: "100%", textAlign: "center" }}>공고분류</div>
                    <div style={{ width: "100%", textAlign: "center" }}>작성일자</div>
                    <div style={{ width: "100%", textAlign: "center" }}>상세보기</div>
                </div>
                {currentItems.map((recruit) => (
                    <AnnouncementList key={recruit.id} recruit={recruit} />
                ))}
                <DefaultPagenation />
            </div>
        )
    };

    return (
        <div>
            <div style={{ marginBottom: "20px" }}>
                <div className="default-contents-border" style={{ height: "50px", position: "relative" }}>
                    <input 
                        placeholder="검색어를 입력하세요" 
                        style={{ background: "none", outline: "none", border: "none", width: "100%", height: "100%", fontWeight: "400", fontFamily: "'Noto Sans KR', sans-serif", fontSize: "14px", boxSizing: "border-box", paddingLeft: "10px" }} 
                        value={searchQuery} // 검색어 입력값을 상태로 관리
                        onChange={(e) => setSearchQuery(e.target.value)} // 검색어 상태 업데이트
                    />
                    <button 
                        className="members-contents-button" 
                        style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)" }}
                        onClick={handleSearch} // 검색 버튼 클릭 시 필터링 함수 호출
                    >
                        검색
                    </button>
                </div>
            </div>
            <div className="default-contents-border" style={{ height: "50px", marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <button className="members-contents-button" onClick={handleAllSelect}>
                        전체선택
                    </button>
                    <div style={{ fontSize: "14px", fontWeight: "600", color: "#a9a9a9", marginLeft: "20px" }}>
                        {currentItemsCount}건
                    </div>
                    <select className="default-contents-border-select" style={{ width: "100px", marginLeft: "20px", textAlign: "center" }} onChange={handleFilterChange}>
                        <option hidden>공고 분류</option>
                        <option>전체보기</option>
                        <option>미드나잇 알바</option>
                        <option>단기 알바</option>
                        <option>상시채용</option>
                        <option>기타</option>
                    </select>

                    <div className="default-contents-border-check-box-border" style={{ marginRight: "20px", marginLeft: "20px" }}>
                        <div style={{ marginRight: "10px" }}>
                            메인 노출 중 공고
                        </div>
                        <input type="checkbox" className="default-contents-border-check-box" onChange={handlePopularChange} />
                    </div>
                </div>
                <div style={{ display: "flex", gap: "10px" }} >
                    <button className="members-contents-button" style={{ backgroundColor: "red", color: "white" }} onClick={handleDeleteSelected}>
                        삭제
                    </button>

                </div>
            </div>
            <DefaultContentsBorder />
        </div>
    );
};

export default Announcement;
