import React, { useEffect, useState } from 'react';
import './OneInquiry.css';
import axios from 'axios';
import { useSelector } from 'react-redux';

const OneInquiry = ({ modalManager }) => {
  const midBack = useSelector((state) => state.midBack);
  const [totalData, setTotalData] = useState([]);
  const [currentNum, setCurrentNum] = useState(1);
  const [currentPage, setCurrentPage] = useState([]);
  const [isChage, setIsChage] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedType, setSelectedType] = useState('전체보기');
  const [isWaitingOnly, setIsWaitingOnly] = useState(false);
  const [searchQuery, setSearchQuery] = useState(''); // 검색어 상태 추가

  const perPage = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.midtrc.com/counsel', {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          },
        });
        const instList = response.data
          .map(item => ({ ...item, isSelected: false }))
          .sort((a, b) => b.id - a.id); // id 값을 기준으로 내림차순 정렬
        setTotalData(instList);
        filterAndPaginateData(instList, currentNum);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [currentNum, isChage, selectedType, isWaitingOnly]); // 필터링에 따라 데이터를 업데이트

  const filterAndPaginateData = (data, pageNum) => {
    const filteredData = data.filter((item) =>
      (selectedType === '전체보기' || (selectedType === '일반' && item.client_type === 'user') || (selectedType === '사업자' && item.client_type === 'business')) &&
      (!isWaitingOnly || item.status === 'waiting') &&
      (item.content.toLowerCase().includes(searchQuery.toLowerCase()) || item.login_id.toLowerCase().includes(searchQuery.toLowerCase())) // 검색어 필터링 추가
    );

    const indexOfLastItem = pageNum * perPage;
    const indexOfFirstItem = indexOfLastItem - perPage;
    setCurrentPage(filteredData.slice(indexOfFirstItem, indexOfLastItem));
  };

  const handleSearch = () => {
    setCurrentNum(1); // 검색 시 페이지를 첫 페이지로 초기화
    filterAndPaginateData(totalData, 1); // 검색어 적용 후 데이터 다시 설정
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value); // 필터링 상태 변경
    setCurrentNum(1); // 필터링 시 페이지를 첫 페이지로 초기화
    filterAndPaginateData(totalData, 1); // 필터링 적용 후 데이터 다시 설정
  };

  const handleWaitingCheck = (e) => {
    setIsWaitingOnly(e.target.checked); // 대기 상태 필터링 설정
    setCurrentNum(1); // 필터링 시 페이지를 첫 페이지로 초기화
    filterAndPaginateData(totalData, 1); // 필터링 적용 후 데이터 다시 설정
  };

  const selectPageHandler = (pageNum) => {
    setCurrentNum(pageNum);
    filterAndPaginateData(totalData, pageNum);
  };

  const handleAllSelect = () => {
    const newSelectedState = !isAllSelected;
    setIsAllSelected(newSelectedState);
    setTotalData((prevList) =>
      prevList.map((item) => ({
        ...item,
        isSelected: newSelectedState,
      }))
    );
    setCurrentPage((prevList) =>
      prevList.map((item) => ({
        ...item,
        isSelected: newSelectedState,
      }))
    );
  };

  const handleItemSelect = (id) => {
    setTotalData((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, isSelected: !item.isSelected } : item
      )
    );
    setCurrentPage((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, isSelected: !item.isSelected } : item
      )
    );

    const allSelected = currentPage.every((item) =>
      item.id === id ? !item.isSelected : item.isSelected
    );
    setIsAllSelected(allSelected);
  };

  const changeHandler = () => {
    setIsChage(!isChage);
  };

  const detailModal = async (data) => {
    try {
      const response = await axios.get(`https://api.midtrc.com/counsel/${data.id}`, {
        headers: {
          'access-token': midBack?.log_data?.access_token,
        },
      });
      modalManager({ type: '1:1문의', data: response.data });
    } catch (error) {
      console.error('Error fetching member details:', error);
    }
  };

  const InquiryList = ({ data, num }) => {
    const getDisplayedText = () => {
      let instCount = 40;
      let content = data.content || '';

      content = content.replace(/<p>|<\/p>/g, '');

      if (content.length > instCount) {
        return content.slice(0, instCount) + '...';
      }
      return content;
    };

    const formatTime = (postTime) => {
      const now = new Date();
      const postDate = new Date(postTime);

      const diffInSeconds = Math.floor((now - postDate) / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      if (diffInHours < 1) {
        return `${diffInMinutes}분`;
      } else if (diffInHours < 3) {
        return `${diffInHours}시간`;
      } else if (diffInDays < 1) {
        return '오늘';
      } else if (diffInDays < 2) {
        return '어제';
      } else {
        const year = postDate.getFullYear().toString().slice(2);
        const month = (postDate.getMonth() + 1).toString().padStart(2, '0');
        const day = postDate.getDate().toString().padStart(2, '0');
        return `${year}.${month}.${day}`;
      }
    };

    const TimeDisplay = ({ postTime }) => {
      const formattedTime = formatTime(postTime);
      return <div>{formattedTime}</div>;
    };

    const requestStatusHandler = (e) => {
      let requestBody = {
        before: data?.status,
        after: e,
      };

      axios
        .put(`https://api.midtrc.com/counsel/${data?.id}/status`, requestBody, {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          },
        })
        .then((res) => {
          console.log(res.data);
          changeHandler();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <div
        className="default-contents-border-top"
        style={{
          fontSize: '14px',
          color: 'black',
          height: '50px',
          display: 'grid',
          gridTemplateColumns: '0.3fr 0.5fr 3.4fr 1fr 1fr 1.5fr 1.5fr 0.8fr',
        }}
      >
        <input
          type="checkbox"
          className="default-contents-border-check-box"
          checked={data.isSelected}
          onChange={() => handleItemSelect(data.id)}
        />
        <div style={{ width: '100%', textAlign: 'center' }}>
          {num + 1 + perPage * (currentNum - 1)}
        </div>
        <div style={{ width: '100%', textAlign: 'left' }}>
          {getDisplayedText()}
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {data?.login_id}
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          {data?.client_type === 'user' ? '일반 회원' : '사업자 회원'}
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <TimeDisplay postTime={data?.created_at} />
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <select
            value={data?.status}
            onChange={(e) => requestStatusHandler(e.target.value)}
            className="default-contents-border-select"
            style={{ width: '100px', textAlign: 'center' }}
          >
            <option value={'waiting'}>대기</option>
            <option value={'complete'}>답변완료</option>
          </select>
        </div>
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            color: 'blue',
            cursor: 'pointer',
          }}
          onClick={() => detailModal(data)}
        >
          상세보기
        </div>
      </div>
    );
  };

  const DefaultPagenation = () => {
    const totalItems = totalData.filter(
      (item) =>
        (selectedType === '전체보기' ||
          (selectedType === '일반' && item.client_type === 'user') ||
          (selectedType === '사업자' && item.client_type === 'business')) &&
        (!isWaitingOnly || item.status === 'waiting') &&
        (item.content.toLowerCase().includes(searchQuery.toLowerCase()) || item.login_id.toLowerCase().includes(searchQuery.toLowerCase())) // 검색어 필터링 추가
    ).length;
    const pageCount = Math.ceil(totalItems / perPage);
    const pages = [];
    for (let i = 1; i <= pageCount; i++) {
      pages.push(
        <div
          className="default-contents-pagenation-button"
          onClick={() => selectPageHandler(i)}
          key={i}
        >
          {i}
        </div>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          height: '50px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        {pages}
      </div>
    );
  };

  const DefaultContentsBorder = () => {
    return (
      <div className="default-contents-border">
        <div
          className="default-contents-border-top"
          style={{
            fontSize: '14px',
            height: '50px',
            display: 'grid',
            gridTemplateColumns: '0.3fr 0.5fr 3.4fr 1fr 1fr 1.5fr 1.5fr 0.8fr',
          }}
        >
          <input
            type="checkbox"
            className="default-contents-border-check-box"
            onChange={handleAllSelect}
            checked={isAllSelected}
          />
          <div style={{ width: '100%', textAlign: 'center' }}>번호</div>
          <div style={{ width: '100%', textAlign: 'center' }}>문의내용</div>
          <div style={{ width: '100%', textAlign: 'center' }}>아이디</div>
          <div style={{ width: '100%', textAlign: 'center' }}>회원 분류</div>
          <div style={{ width: '100%', textAlign: 'center' }}>작성일자</div>
          <div style={{ width: '100%', textAlign: 'center' }}>처리상태</div>
          <div style={{ width: '100%', textAlign: 'center' }}>상세보기</div>
        </div>
        {currentPage?.map((a, i) => (
          <InquiryList key={i} num={i} data={a} />
        ))}
        <DefaultPagenation />
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: '20px',
          marginBottom: '20px',
        }}
      >
        <div
          className="default-contents-border"
          style={{ height: '50px', position: 'relative' }}
        >
          <input
            placeholder="검색어를 입력하세요"
            style={{
              background: 'none',
              outline: 'none',
              border: 'none',
              width: '100%',
              height: '100%',
              fontWeight: '400',
              fontFamily: "'Noto Sans KR', sans-serif",
              fontSize: '14px',
              boxSizing: 'border-box',
              paddingLeft: '10px',
            }}
            value={searchQuery} // 입력 값을 상태로 관리
            onChange={(e) => setSearchQuery(e.target.value)} // 검색어 상태 업데이트
          />
          <button
            className="members-contents-button"
            style={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
            }}
            onClick={handleSearch} // 검색 버튼 클릭 시 필터링 함수 호출
          >
            검색
          </button>
        </div>
      </div>
      <div
        className="default-contents-border"
        style={{
          height: '50px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button className="members-contents-button" onClick={handleAllSelect}>
            전체선택
          </button>
          <div
            style={{
              fontSize: '14px',
              fontWeight: '600',
              color: '#a9a9a9',
              marginLeft: '20px',
            }}
          >
            {totalData?.length} 건
          </div>
          <select
            className="default-contents-border-select"
            style={{ width: '100px', marginLeft: '20px', textAlign: 'center' }}
            onChange={handleTypeChange}
            value={selectedType}
          >
            <option>전체보기</option>
            <option>일반</option>
            <option>사업자</option>
          </select>
          <div
            className="default-contents-border-check-box-border"
            style={{ marginLeft: '20px' }}
          >
            <div style={{ marginRight: '10px' }}>미답변 문의</div>
            <input
              type="checkbox"
              className="default-contents-border-check-box"
              onChange={handleWaitingCheck}
              checked={isWaitingOnly}
            />
          </div>
        </div>
      </div>
      <DefaultContentsBorder />
    </div>
  );
};

export default OneInquiry;
