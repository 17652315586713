import React, { useEffect, useState } from "react";
import styled from "styled-components";
import OpenEyes from '../img/choice-talk/visible_0.png'
import CloseEyes from '../img/choice-talk/invisible_0.png'
import axios from "axios";
import { useSelector } from "react-redux";

let ChoiceBorder = styled.div`
  width: 100%;
  height: auto;
  border-radius: 4px;
  border-style: solid;
  border-color: #dedede;
  border-width: 1px;
  background-color: white;
  overflow: hidden;
`

let ChoiceTopBorder = styled.div`
  width: 100%;
  height: 50px;
  border-style: solid;
  border-color: #dedede;
  border-width: 0 0 1px 0;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
  font-size: 14px;
`

let BaroInput = styled.input`
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a9a9a9;
  width: 100%;
  height: 100%;
  outline: none;
  background: none;
`

let ChoiceButton =styled.button`
    color: white;
    background-color: #dedede;
    padding: 0 10px;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    width: 80%;
    &:hover{
        background-color: #444444;
    }
`

let ChoiceBottomBorder = styled.div`
  width: 100%;
  height: 50px;
  border-style: solid;
  border-color: #dedede;
  border-width: 0;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: right;
  align-items: center;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
  font-size: 14px;
  column-gap: 10px;
`

let ChoiceBottomButton =styled.button`
    color: white;
    background-color: #444444;
    padding: 0 10px;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    width: 150px;
`

const ChioceGroupNew = ({data, change, modalManager}) => {
    const midBack = useSelector((state) => state.midBack);
    let [steadyData, setSteadyData] = useState(data?.waiting?data?.waiting:'');
    let [talkData, setTalkData] = useState([]);
    let [talkDataFull, setTalkDataFull] = useState([]);
    let [isChage,setIsChage] = useState(false)
    const [group_id, setGroup_Id] = useState();

    useEffect(()=>{
        const fetchData = async () => {
            try {
                let response = await axios.get(`https://api.midtrc.com/talk/back?group_id=${data?.id}&limit=${5}&offset=${0}`,{
                    headers: {
                        'access-token': midBack?.log_data?.access_token,
                    }
                })
                const recentData = response.data.slice(-5);
                setTalkData(recentData);
                setGroup_Id(data?.id)
            } catch (error) {
                
            }
        }
        fetchData();
    },[data, isChage])

    

    const ChoiceList = ({data}) => {
        let [isActive, setIsActive] = useState(data?.status);
        
        const Truncate = (str, maxLength = 10) => {
            if (str.length > maxLength) {
                return str.slice(0, maxLength) + '...';
            }
            return str;
        };

        const talkStatusHandler = (e) => {
            let requestBody = {
                "before": isActive,
                "after": isActive === 'active'? 'paused':'active'
            }
            axios.put(`https://api.midtrc.com/talk/${data?.id}/status`,requestBody,{
                headers: {
                    'access-token': midBack?.log_data?.access_token,
                }
            }).then((res)=>{setIsChage(!isChage)}).then((error)=>{console.log(error)})
        }

        const formatDateAndAdjustTime = (dateString) => {
            // 주어진 날짜 문자열을 Date 객체로 변환
            const date = new Date(dateString);
        
            // 9시간을 더함 (밀리초 단위)
            date.setHours(date.getHours());
        
            // 년, 월, 일, 시간, 분을 각각 추출
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
        
            // 원하는 형식으로 변환
            return `${year}.${month}.${day} ${hours}:${minutes}`;
        };

        return(
            <ChoiceTopBorder style={{display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr"}}>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%', color: "#a9a9a9"}}>
                    {formatDateAndAdjustTime(data?.created_at)}
                </div>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%', color: "#a9a9a9"}}>
                    {Truncate(data?.content)}
                </div>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%', color: "#a9a9a9"}}>
                    <img onClick={()=>{talkStatusHandler(!isActive)}} style={{width: '20px', cursor: "pointer"}} src={isActive ==='active'?OpenEyes:CloseEyes} alt="eye"/>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%', color: "#a9a9a9"}}>
                    <ChoiceButton onClick={()=>{modalManager({type: '채팅 내용 확인', data: group_id})}}>
                        채팅상세
                    </ChoiceButton>
                </div>
            </ChoiceTopBorder>
        )
    }

    const steadyHandler = (e) => {
        setSteadyData(e)
    }

    const steadySubmit = () => {
        if(steadyData !== ''){
            let requestBody = {
                "group_name": data?.name,
                "user_name": data?.client_nickname,
                "content": data?.name+'/'+steadyData
            }
    
            axios.put(`https://api.midtrc.com/talk`,requestBody,{
                headers: {
                    'access-token': midBack?.log_data?.access_token,
                }
            }).then((res)=>{change()}).catch((error)=>{console.log(error)});
        }else{
            alert('바로대기 텍스트를 입력하세요')
        }
    }

    const statusHandler = (e) => {
        let requestBody = {
            "before": data?.status,
            "after": e.target.value
        }


        axios.put(`https://api.midtrc.com/choice/${data?.id}/status`,requestBody,{
            headers: {
                'access-token': midBack?.log_data?.access_token,
            }
        }).then((res)=>{change()}).catch((error)=>{console.log(error)});

    }

    return(
      <ChoiceBorder>
        <ChoiceTopBorder>
          <div>
            {data?.name+' / '+data?.region}
          </div>
          <select value={data?.status} onChange={statusHandler} style={{width: '100px', textAlign: "center"}}>
            <option value={'plenty'||'active'}>
                여유
            </option>
            <option value={'neither'}>
                중간
            </option>
            <option value={'crowded'}>
                혼잡
            </option>
            <option value={'paused'}>
                숨김
            </option>
          </select>
        </ChoiceTopBorder>
        <ChoiceTopBorder style={{backgroundColor: "#f9f9f9", display: 'grid', gridTemplateColumns: "2fr 7fr 1fr"}}>
          <div>
            바로대기
          </div>
          <BaroInput value={steadyData} onChange={(e)=>{steadyHandler(e.target.value)}} placeholder='바로대기 택스르를 입력하세요'/>
          <div onClick={(e)=>{e.stopPropagation(); steadySubmit()}} style={{display: 'flex', justifyContent: 'right', width: '100%', cursor: 'pointer'}}>
            변경
          </div>
        </ChoiceTopBorder>
        <ChoiceTopBorder style={{display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr"}}>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%', color: "#a9a9a9"}}>
                업데이트
            </div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%', color: "#a9a9a9"}}>
                내용
            </div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%', color: "#a9a9a9"}}>
                상태
            </div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%', color: "#a9a9a9"}}>
                상세보기
            </div>
        </ChoiceTopBorder>
        {
            talkData?.map((a, i)=>(
                <ChoiceList data={a} key={i} />
            ))
        }
        <ChoiceBottomBorder>
            <ChoiceBottomButton onClick={()=>{modalManager({type: "채팅 수동 입력", data: data})}}>
                채팅방 수동입력
            </ChoiceBottomButton>
            <ChoiceBottomButton onClick={()=>{modalManager({type: "가게 상세 보기", data: data})}}>
                채팅방 상세보기
            </ChoiceBottomButton>
        </ChoiceBottomBorder>
      </ChoiceBorder>
    )
  }


export default ChioceGroupNew