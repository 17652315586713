import React, { useEffect, useState } from 'react';
import './Resume.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import visible_0 from '../img/choice-talk/visible_0.png';
import CloseEyes from '../img/choice-talk/invisible_0.png';

const Resume = () => {
    let navigate = useNavigate();
    const midBack = useSelector((state) => state.midBack);
    const [resumeList, setResumeList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSet, setCurrentSet] = useState(1);
    const [currentItemsCount, setCurrentItemsCount] = useState(0);
    const [showPaused, setShowPaused] = useState(false);
    const [showActive, setShowActive] = useState(true); // 초기 상태에서 노출중 이력서가 체크됨
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredResumeList, setFilteredResumeList] = useState([]);
    const itemsPerPage = 10;
    const pagesPerSet = 5;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/resume', {
                    headers: {
                        'access-token': midBack?.log_data?.access_token
                    }
                });
                const filteredData = response.data.filter(item => ['active', 'paused', 'represent'].includes(item.status));
                const sortedData = filteredData.map(item => ({ ...item, isSelected: false })).sort((a, b) => b.id - a.id);
                setResumeList(sortedData);
                setFilteredResumeList(sortedData); // 초기에는 전체 리스트를 표시
            } catch (error) {
                console.error('Error fetching the resume data:', error);
            }
        };

        fetchData();
    }, [midBack]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNextSet = () => {
        setCurrentSet(prevSet => prevSet + 1);
        setCurrentPage((currentSet * pagesPerSet) + 1);
    };

    const handlePrevSet = () => {
        setCurrentSet(prevSet => prevSet - 1);
        setCurrentPage(((currentSet - 2) * pagesPerSet) + 1);
    };

    const handlePausedChange = () => {
        setShowPaused(!showPaused);
        setCurrentPage(1);
        setCurrentSet(1);
        filterResumeList(!showPaused, showActive, searchQuery); // 상태 변경 시 필터 적용
    };

    const handleActiveChange = () => {
        setShowActive(!showActive);
        setCurrentPage(1);
        setCurrentSet(1);
        filterResumeList(showPaused, !showActive, searchQuery); // 상태 변경 시 필터 적용
    };

    const handleAllSelect = () => {
        const newSelectedState = !isAllSelected;
        setIsAllSelected(newSelectedState);
        setFilteredResumeList((prevList) =>
            prevList.map((resume) => ({
                ...resume,
                isSelected: newSelectedState,
            }))
        );
    };

    const handleResumeSelect = (id) => {
        setFilteredResumeList((prevList) =>
            prevList.map((resume) =>
                resume.id === id ? { ...resume, isSelected: !resume.isSelected } : resume
            )
        );
    };

    const handleDeleteSelected = async () => {
        const selectedIds = filteredResumeList.filter(resume => resume.isSelected).map(resume => resume.id);
        if (selectedIds.length === 0) {
            alert('삭제할 이력서를 선택하세요.');
            return;
        }

        if (!window.confirm('정말 이력서를 삭제하시겠습니까?')) {
            return;
        }

        try {
            await Promise.all(
                selectedIds.map(id =>
                    axios.delete(`https://api.midtrc.com/resume/${id}`, {
                        headers: {
                            'access-token': midBack?.log_data?.access_token
                        }
                    })
                )
            );

            setFilteredResumeList(prevList => prevList.filter(resume => !selectedIds.includes(resume.id)));
            setResumeList(prevList => prevList.filter(resume => !selectedIds.includes(resume.id))); 
            setIsAllSelected(false);
        } catch (error) {
            console.error('Error deleting resume:', error);
            alert('이력서 삭제 중 오류가 발생했습니다.');
        }
    };

    const filterResumeList = (paused, active, query) => {
        let filteredList = resumeList;

        if (paused && active) {
            filteredList = filteredList.filter(resume => 
                ['paused', 'active', 'represent'].includes(resume.status));
        } else if (paused) {
            filteredList = filteredList.filter(resume => resume.status === 'paused');
        } else if (active) {
            filteredList = filteredList.filter(resume => ['active', 'represent'].includes(resume.status));
        } else {
            filteredList = [];
        }

        if (query) {
            filteredList = filteredList.filter(resume => 
                resume.resume_title.toLowerCase().includes(query.toLowerCase()) || 
                resume.nickname.toLowerCase().includes(query.toLowerCase())
            );
        }

        setFilteredResumeList(filteredList);
    };

    const handleSearch = () => {
        setCurrentPage(1);
        setCurrentSet(1);
        filterResumeList(showPaused, showActive, searchQuery); // 검색 버튼 클릭 시 필터링 적용
    };

    const DefaultPagenation = () => {
        const totalItems = filteredResumeList.length;
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const startPage = (currentSet - 1) * pagesPerSet + 1;
        const endPage = Math.min(currentSet * pagesPerSet, totalPages);

        return (
            <div style={{ display: "flex", height: "50px", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                {startPage > 1 && <div onClick={handlePrevSet} className="default-contents-pagenation-button">{"<"}</div>}
                {Array.from({ length: (endPage - startPage + 1) }, (_, i) => startPage + i).map(page => (
                    <div
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className="default-contents-pagenation-button"
                        style={{ color: page === currentPage ? 'black' : 'grey' }}
                    >
                        {page}
                    </div>
                ))}
                {endPage < totalPages && <div onClick={handleNextSet} className="default-contents-pagenation-button">{">"}</div>}
            </div>
        );
    };

    const ResumeList = ({ resume }) => {
        const getStatusImage = (status) => {
            switch (status) {
                case 'represent':
                case 'active':
                    return visible_0;
                case 'paused':
                    return CloseEyes;
                default:
                    return visible_0;
            }
        };

        return (
            <div className="default-contents-border-top"
                style={{
                    fontSize: "14px",
                    color: "black",
                    height: "50px",
                    display: "grid",
                    gridTemplateColumns: "0.3fr 0.5fr 4fr 1fr 1fr 1fr 1fr"
                }}>
                <input
                    type="checkbox"
                    className="default-contents-border-check-box"
                    checked={resume.isSelected}
                    onChange={() => handleResumeSelect(resume.id)}
                />
                <div style={{ width: "100%", textAlign: "center" }}>
                    {resume.id}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {resume.resume_title}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {resume.nickname}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {new Date(resume.updated_at).toLocaleDateString()}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={getStatusImage(resume.status)} alt='status' style={{ height: "20px", paddingTop: "4px" }} />
                </div>
                <div onClick={() => navigate(`/resume-detail/${resume.id}`)} style={{ width: "100%", textAlign: "center", color: "blue", cursor: "pointer" }}>
                    상세보기
                </div>
            </div>
        )
    };

    const DefaultContentsBorder = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const currentItems = filteredResumeList.slice(startIndex, startIndex + itemsPerPage);
        setCurrentItemsCount(currentItems.length); // 현재 페이지의 아이템 수 업데이트

        return (
            <div className="default-contents-border">
                <div className="default-contents-border-top"
                    style={{
                        fontSize: "14px",
                        height: "50px",
                        display: "grid",
                        gridTemplateColumns: "0.3fr 0.5fr 4fr 1fr 1fr 1fr 1fr"
                    }}>
                    <input
                        type="checkbox"
                        className="default-contents-border-check-box"
                        onChange={handleAllSelect}
                        checked={isAllSelected}
                    />
                    <div style={{ width: "100%", textAlign: "center" }}>번호</div>
                    <div style={{ width: "100%", textAlign: "center" }}>이력서 제목</div>
                    <div style={{ width: "100%", textAlign: "center" }}>아이디</div>
                    <div style={{ width: "100%", textAlign: "center" }}>최종 업데이트</div>
                    <div style={{ width: "100%", textAlign: "center" }}>상태</div>
                    <div style={{ width: "100%", textAlign: "center" }}>상세보기</div>
                </div>
                {currentItems.map((resume) => (
                    <ResumeList key={resume.id} resume={resume} />
                ))}
                <DefaultPagenation />
            </div>
        )
    };

    return (
        <div>
            <div style={{ marginBottom: "20px" }}>
                <div className="default-contents-border" style={{ height: "50px", position: "relative" }}>
                    <input 
                        placeholder="검색어를 입력하세요" 
                        style={{ background: "none", outline: "none", border: "none", width: "100%", height: "100%", fontWeight: "400", fontFamily: "'Noto Sans KR', sans-serif", fontSize: "14px", boxSizing: "border-box", paddingLeft: "10px" }} 
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)} // 검색어 입력
                    />
                    <button 
                        className="members-contents-button" 
                        style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)" }}
                        onClick={handleSearch} // 검색 버튼 클릭 시 필터링 적용
                    >
                        검색
                    </button>
                </div>
            </div>
            <div className="default-contents-border" style={{ height: "50px", marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <button className="members-contents-button" onClick={handleAllSelect}>
                        전체선택
                    </button>
                    <div style={{ fontSize: "14px", fontWeight: "600", color: "#a9a9a9", marginLeft: "20px" }}>
                        {currentItemsCount}건
                    </div>
                    <div className="default-contents-border-check-box-border" style={{ marginLeft: "20px" }}>
                        <div style={{ marginRight: "10px" }}>
                            숨겨진 이력서
                        </div>
                        <input type="checkbox" className="default-contents-border-check-box" onChange={handlePausedChange} checked={showPaused} />
                    </div>
                    <div className="default-contents-border-check-box-border" style={{ marginLeft: "20px" }}>
                        <div style={{ marginRight: "10px" }}>
                            노출중 이력서
                        </div>
                        <input type="checkbox" className="default-contents-border-check-box" onChange={handleActiveChange} checked={showActive} />
                    </div>
                </div>
                <div style={{ display: "flex", gap: "10px" }} >
                    <button className="members-contents-button" style={{ backgroundColor: "red", color: "white" }} onClick={handleDeleteSelected}>
                        삭제
                    </button>
                </div>
            </div>
            <DefaultContentsBorder />
        </div>
    );
};

export default Resume;
