import React, { useState } from 'react'
import './Authority.css'

const Authority = () => {
  let [clickedIndex, setClickedIndex] = useState('자주 묻는 질문');
    // 공통 인덱스
    const MyPageIndexBorder = () => {
    
      const IndexButton = ({name, url}) => {
          return(
            <div>
              {
                clickedIndex === name?
                <div className="default-index-border-button" style={{backgroundColor: "#EDF5FF"}} onClick={()=> {setClickedIndex(name)}}>
                    {name}
                </div>
                :
                <div className="default-index-border-button" onClick={()=> {setClickedIndex(name)}}>
                    {name}
                </div>
              }
            </div>
          )
      }
      return(
          <div className="default-index-border" style={{paddingBottom: "5px"}}>
              <IndexButton name={'이벤트'}/>
              <IndexButton name={'공지사항'}/>
              <IndexButton name={'자주 묻는 질문'}/>
          </div>
      )
    }

      // 스위치
  const ContentSwitcher = () => {
    switch(clickedIndex) {
      case "이벤트" :
        return(
          <AuthorityInfo />
        )
      case "공지사항" :
      return(
        <AuthorityInfo />
      )
      case "자주 묻는 질문" :
      return(
        <AuthorityInfo />
      )
      default : 
        return (
          <AuthorityInfo />
        )
    }
  }

  const AuthorityInfo = () => {
    return (
      <div className='default-contents-border'>
      <div className='default-contents-border-top'>게시판 관리</div>
      <div style={{boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px"}}>
        <div>
          <div className="default-contents-border-title" style={{marginBottom:"20px"}}>
            게시판 이름
          </div>
          <div className="default-contents-border-title">
            읽기/쓰기 권한
          </div>
        </div>
        <div>
          <div style={{position: "relative",marginBottom:"20px"}}>
            <input readOnly={true} className="default-input-line-tag" value={'이벤트'}/>
            <div className="default-input-tag-edit">
                수정
            </div>
          </div>
          <div style={{display: "grid", gridTemplateColumns: "2.5fr 3.5fr 3.5fr", columnGap: "20px"}}>
            <div className="default-contents-border-title">
              읽기
            </div>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "22px", fontWeight: "600"}}>
                    모든회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div/>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "35px", fontWeight: "600"}}>
                    비회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "22px", fontWeight: "600"}}>
                    일반회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    사업자회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "22px", fontWeight: "600"}}>
                    여성회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-title">
              댓글 쓰기
            </div>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "22px", fontWeight: "600"}}>
                    모든회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div/>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "35px", fontWeight: "600"}}>
                    비회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "22px", fontWeight: "600"}}>
                    일반회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    사업자회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "22px", fontWeight: "600"}}>
                    여성회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-title">
              게시글 쓰기
            </div>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "22px", fontWeight: "600"}}>
                    모든회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div/>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "35px", fontWeight: "600"}}>
                    비회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "22px", fontWeight: "600"}}>
                    일반회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    사업자회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border">
                <div style={{color: "#a9a9a9", marginRight: "22px", fontWeight: "600"}}>
                    여성회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
        </div>
        </div>
      </div>
      <div className='default-contents-border-confirm'>
        <button className='default-contents-border-confirm-button'>취 소</button>
        <button className='default-contents-border-confirm-button' style={{background:"#ff0000", color : "#fff"}}>삭 제</button>
        <button className='default-contents-border-confirm-button' style={{background:"#183b5b", color : "#fff"}}>저 장</button>
      </div>
      </div>
    )
  }

    return (
      <div className='Authority'>
        <div>
          <MyPageIndexBorder />
        </div>
        <ContentSwitcher />
      </div>
    )
}

export default Authority