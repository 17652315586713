import React from 'react'
import './DeclarationSubBox.css'
import setting2 from '../img/setting2.png'
import setting from '../img/side-bar/00-setting.png'
import { useNavigate } from 'react-router-dom'
const DeclarationSubBox = () => {
  let navigate = useNavigate(null);

  return (
    <div className='DeclarationSubBox'>
      <div className='DeclarationSubBoxCover'>
        <div className='DeclarationSubBoxArea'> 
          <div onClick={()=>{navigate('/declaration')}}>
            <img src={setting} alt='setting' />
            <p>신고된 공고</p>
          </div>
          <div onClick={()=>{navigate('/declaration-resume')}}>
            <img src={setting2} alt='setting2' />
            <p>신고된 이력서</p>
          </div>
          <div onClick={()=>{navigate('/declaration-post')}}>
            <img src={setting2} alt='setting2' />
            <p>신고된 게시글</p>
          </div>
          <div onClick={()=>{navigate('/declaration-chat')}}>
            <img src={setting2} alt='setting2' />
            <p>신고된 채팅</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeclarationSubBox