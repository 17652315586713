import React, { useEffect, useState } from 'react';
import './BoardManagement.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';

const BoardManagement = () => {
  let navigate = useNavigate();
  const midBack = useSelector((state) => state.midBack);
  const [boardList, setBoardList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSet, setCurrentSet] = useState(1);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [filter, setFilter] = useState('전체보기');
  const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태 추가
  const [filteredBoardList, setFilteredBoardList] = useState([]); // 필터링된 리스트 상태 추가
  const itemsPerPage = 10;
  const pagesPerSet = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.midtrc.com/notice', {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        const sortedData = response.data.map(item => ({ ...item, isSelected: false })).sort((a, b) => b.id - a.id);
        setBoardList(sortedData);
        setFilteredBoardList(sortedData); // 초기 필터링된 리스트 설정
      } catch (error) {
        console.error('Error fetching the board data:', error);
      }
    };

    fetchData();
  }, [midBack]);

  const handleSearch = () => {
    const searchResult = boardList.filter(item => 
      item.notice_title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBoardList(searchResult);
    setCurrentPage(1); // 검색 후 첫 페이지로 이동
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextSet = () => {
    setCurrentSet(prevSet => prevSet + 1);
    setCurrentPage((currentSet * pagesPerSet) + 1);
  };

  const handlePrevSet = () => {
    setCurrentSet(prevSet => prevSet - 1);
    setCurrentPage(((currentSet - 2) * pagesPerSet) + 1);
  };

  const handleAllSelect = () => {
    const newSelectedState = !isAllSelected;
    setIsAllSelected(newSelectedState);
    setFilteredBoardList((prevList) =>
      prevList.map((item) => ({
        ...item,
        isSelected: newSelectedState,
      }))
    );
  };

  const handleBoardSelect = (id) => {
    setFilteredBoardList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };

  const handleDeleteSelected = async () => {
    const selectedIds = filteredBoardList.filter(item => item.isSelected).map(item => item.id);
    if (selectedIds.length === 0) {
      alert('삭제할 게시글을 선택하세요.');
      return;
    }

    if (!window.confirm('정말 게시글을 삭제하시겠습니까?')) {
      return;
    }

    try {
      await Promise.all(
        selectedIds.map(id =>
          axios.delete(`https://api.midtrc.com/notice/${id}`, {
            headers: {
              'access-token': midBack?.log_data?.access_token
            }
          })
        )
      );

      setFilteredBoardList(prevList => prevList.filter(item => !selectedIds.includes(item.id)));
      setIsAllSelected(false);
    } catch (error) {
      console.error('Error deleting board:', error);
      alert('게시글 삭제 중 오류가 발생했습니다.');
    }
  };

  const filterBoardList = () => {
    let filteredList = filteredBoardList;

    switch (filter) {
      case '이벤트':
        filteredList = filteredList.filter(item => item.notice_type === 'event');
        break;
      case '공지사항':
        filteredList = filteredList.filter(item => item.notice_type === 'notice');
        break;
      case '자주 묻는 질문':
        filteredList = filteredList.filter(item => item.notice_type === 'question');
        break;
      default:
        break;
    }

    return filteredList;
  };

  const DefaultPagenation = () => {
    const totalItems = filterBoardList().length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startPage = (currentSet - 1) * pagesPerSet + 1;
    const endPage = Math.min(currentSet * pagesPerSet, totalPages);

    return (
      <div style={{ display: "flex", height: "50px", justifyContent: "center", alignItems: "center", gap: "10px" }}>
        {startPage > 1 && <div onClick={handlePrevSet} className="default-contents-pagenation-button">{"<"}</div>}
        {Array.from({ length: (endPage - startPage + 1) }, (_, i) => startPage + i).map(page => (
          <div
            key={page}
            onClick={() => handlePageChange(page)}
            className="default-contents-pagenation-button"
            style={{ color: page === currentPage ? 'black' : 'grey' }}
          >
            {page}
          </div>
        ))}
        {endPage < totalPages && <div onClick={handleNextSet} className="default-contents-pagenation-button">{">"}</div>}
      </div>
    );
  };

  const BoardManageList = ({ item }) => {
    const getNoticeType = (type) => {
      switch (type) {
        case 'notice':
          return '공지사항';
        case 'event':
          return '이벤트';
        case 'question':
          return '자주 묻는 질문';
        default:
          return '기타';
      }
    };

    return (
      <div className="default-contents-border-top"
        style={{
          fontSize: "14px",
          color: "black",
          height: "50px",
          display: "grid",
          gridTemplateColumns: "0.3fr 0.5fr 6fr 1fr 1fr 1fr"
        }}>
        <input
          type="checkbox"
          className="default-contents-border-check-box"
          checked={item.isSelected}
          onChange={() => handleBoardSelect(item.id)}
        />
        <div style={{ width: "100%", textAlign: "center" }}>
          {item.id}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          {item.notice_title}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          {getNoticeType(item.notice_type)}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          {new Date(item.created_at).toLocaleDateString()}
        </div>
        <div onClick={() => { navigate(`/boardmanage-detail/${item.id}`) }} style={{ width: "100%", textAlign: "center", color: "blue", cursor: "pointer" }}>
          상세보기
        </div>
      </div>
    )
  };

  const DefaultContentsBorder = () => {
    const filteredItems = filterBoardList();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = filteredItems.slice(startIndex, startIndex + itemsPerPage);

    return (
      <div className="default-contents-border">
        <div className="default-contents-border-top"
          style={{
            fontSize: "14px",
            height: "50px",
            display: "grid",
            gridTemplateColumns: "0.3fr 0.5fr 6fr 1fr 1fr 1fr"
          }}>
          <input
            type="checkbox"
            className="default-contents-border-check-box"
            onChange={handleAllSelect}
            checked={isAllSelected}
          />
          <div style={{ width: "100%", textAlign: "center" }}>번호</div>
          <div style={{ width: "100%", textAlign: "center" }}>텍스트 내용</div>
          <div style={{ width: "100%", textAlign: "center" }}>분류</div>
          <div style={{ width: "100%", textAlign: "center" }}>작성 일자</div>
          <div style={{ width: "100%", textAlign: "center" }}>상세보기</div>
        </div>
        {currentItems.map((item) => (
          <BoardManageList key={item.id} item={item} />
        ))}
        <DefaultPagenation />
      </div>
    )
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <div className="default-contents-border" style={{ height: "50px", position: "relative" }}>
          <input 
            placeholder="검색어를 입력하세요" 
            style={{ background: "none", outline: "none", border: "none", width: "100%", height: "100%", fontWeight: "400", fontFamily: "'Noto Sans KR', sans-serif", fontSize: "14px", boxSizing: "border-box", paddingLeft: "10px" }} 
            value={searchTerm} // 검색어 상태 값 사용
            onChange={(e) => setSearchTerm(e.target.value)} // 입력 시 검색어 상태 업데이트
          />
          <button className="members-contents-button" style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)" }} onClick={handleSearch}>
            검색
          </button>
        </div>
      </div>
      <div className="default-contents-border" style={{ height: "50px", marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button className="members-contents-button" onClick={handleAllSelect}>
            전체선택
          </button>
          <div style={{ fontSize: "14px", fontWeight: "600", color: "#a9a9a9", marginLeft: "20px" }}>
            {boardList.length}건
          </div>
          <select className="default-contents-border-select" style={{ marginLeft: "20px", textAlign: "center" }} value={filter} onChange={(e) => setFilter(e.target.value)}>
            <option>전체보기</option>
            <option>이벤트</option>
            <option>공지사항</option>
            <option>자주 묻는 질문</option>
          </select>
        </div>
        <div style={{ display: "flex", gap: "10px" }} >
          <button className="members-contents-button" style={{ backgroundColor: "red", color: "white" }} onClick={handleDeleteSelected}>
            삭제
          </button>
          <button className="members-contents-button" style={{ backgroundColor: "#183B5B", color: "white" }} onClick={() => {navigate(`/board-management-add`)}}>
            추가
          </button>
        </div>
      </div>
      <DefaultContentsBorder />
    </div>
  )
}

export default BoardManagement;
