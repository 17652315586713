import React from 'react'
import './AllianceSubBox.css'
import setting from '../img/side-bar/00-setting.png'
import { useNavigate } from 'react-router-dom'

const AllianceSubBox = () => {
  let navigate = useNavigate(null);
  return (
    <div className='AllianceSubBox'>
    <div className='AllianceSubBoxCover'>
      <div className='AllianceSubBoxArea'> 
        <div onClick={()=>{navigate('/alliance')}}>
          <img src={setting} alt='setting' />
          <p>제휴/입점 문의</p>
        </div>
        <div onClick={()=>{navigate('/alliance-manage')}}>
          <img src={setting} alt='setting' />
          <p>제휴/입점 안내사항 관리</p>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AllianceSubBox