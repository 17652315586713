import React from 'react'
import './DeclarationChatInfo.css'
import { Link, useNavigate } from 'react-router-dom'
import PageArrowL from '../img/PageArrowL.png'
import PageArrowR from '../img/PageArrowR.png'

const DeclarationChatInfo = () => {
  let navigate = useNavigate(null);
  return (
    <div className='DeclarationChatInfo'>
    <div className='DeclarationChatInfoCover'>
      <h2>신고된 채팅</h2>
      {/* 테이블 */}
      <div className='DeclarationChatInfoTable'>
      <table>
        {/* 제목 */}
          <thead>
            <tr>
              <th><input type='checkbox' /></th>
              <th>번호</th>
              <th>신고 내용</th>
              <th>신고자</th>
              <th>처리상태</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {/* 테이블 1번쨰 줄 */}
            <tr>
              <td><input type='checkbox' /></td>
              <td>1</td>
              <td>신고 내용이 표기됩니다</td>
              <td>PARK12312312</td>
              <td>
                <select>
                  <option value="처리 전">처리 전</option>
                  <option value="처리완료">처리완료</option>
                </select>
              </td>
              <td onClick={()=>{navigate('/declaration-chat-detail')}}>상세보기</td>
            </tr>
            {/* 테이블 2번쨰 줄 */}
            <tr>
              <td><input type='checkbox' /></td>
              <td>2</td>
              <td>신고 내용이 표기됩니다</td>
              <td>PARK12312312</td>
              <td>
                <select>
                  <option value="처리 전">처리 전</option>
                  <option value="처리완료">처리완료</option>
                </select>
              </td>
              <td onClick={()=>{navigate('/declaration-chat-detail')}}>상세보기</td>
            </tr>
            <tr>
              <td><input type='checkbox' /></td>
              <td>3</td>
              <td>신고 내용이 표기됩니다</td>
              <td>PARK12312312</td>
              <td>
                <select>
                  <option value="처리 전">처리 전</option>
                  <option value="처리완료">처리완료</option>
                </select>
              </td>
              <td onClick={()=>{navigate('/declaration-chat-detail')}}>상세보기</td>
            </tr>
            <tr>
              <td><input type='checkbox' /></td>
              <td>4</td>
              <td>신고 내용이 표기됩니다</td>
              <td>PARK12312312</td>
              <td>
                <select>
                  <option value="처리 전">처리 전</option>
                  <option value="처리완료">처리완료</option>
                </select>
              </td>
              <td onClick={()=>{navigate('/declaration-chat-detail')}}>상세보기</td>
            </tr>
            <tr>
              <td><input type='checkbox' /></td>
              <td>5</td>
              <td>신고 내용이 표기됩니다</td>
              <td>PARK12312312</td>
              <td>
                <select>
                  <option value="처리 전">처리 전</option>
                  <option value="처리완료">처리완료</option>
                </select>
              </td>
              <td onClick={()=>{navigate('/declaration-chat-detail')}}>상세보기</td>
            </tr>
            <tr>
              <td><input type='checkbox' /></td>
              <td>6</td>
              <td>신고 내용이 표기됩니다</td>
              <td>PARK12312312</td>
              <td>
                <select>
                  <option value="처리 전">처리 전</option>
                  <option value="처리완료">처리완료</option>
                </select>
              </td>
              <td onClick={()=>{navigate('/declaration-chat-detail')}}>상세보기</td>
            </tr>
            <tr>
              <td><input type='checkbox' /></td>
              <td>7</td>
              <td>신고 내용이 표기됩니다</td>
              <td>PARK12312312</td>
              <td>
                <select>
                  <option value="처리 전">처리 전</option>
                  <option value="처리완료">처리완료</option>
                </select>
              </td>
              <td onClick={()=>{navigate('/declaration-chat-detail')}}>상세보기</td>
            </tr>
            <tr>
              <td><input type='checkbox' /></td>
              <td>8</td>
              <td>신고 내용이 표기됩니다</td>
              <td>PARK12312312</td>
              <td>
                <select>
                  <option value="처리 전">처리 전</option>
                  <option value="처리완료">처리완료</option>
                </select>
              </td>
              <td onClick={()=>{navigate('/declaration-chat-detail')}}>상세보기</td>
            </tr>
            <tr>
              <td><input type='checkbox' /></td>
              <td>9</td>
              <td>신고 내용이 표기됩니다</td>
              <td>PARK12312312</td>
              <td>
                <select>
                  <option value="처리 전">처리 전</option>
                  <option value="처리완료">처리완료</option>
                </select>
              </td>
              <td onClick={()=>{navigate('/declaration-chat-detail')}}>상세보기</td>
            </tr>
            <tr>
              <td><input type='checkbox' /></td>
              <td>10</td>
              <td>신고 내용이 표기됩니다</td>
              <td>PARK12312312</td>
              <td>
                <select>
                  <option value="처리 전">처리 전</option>
                  <option value="처리완료">처리완료</option>
                </select>
              </td>
              <td onClick={()=>{navigate('/declaration-chat-detail')}}>상세보기</td>
            </tr>
          </tbody>
        </table>
        {/* 페이지네이션 */}
        <div className='pagenation'>
          <ul>
            <li><Link to="#"><img src={PageArrowL} alt='PageArrowL'/></Link></li>
            <li><Link to="#">1</Link></li>
            <li><Link to="#">2</Link></li>
            <li><Link to="#">3</Link></li>
            <li><Link to="#">4</Link></li>
            <li><Link to="#">5</Link></li>
            <li><Link to="#"><img src={PageArrowR} alt='PageArrowR'/></Link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  )
}

export default DeclarationChatInfo