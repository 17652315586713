import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import bannerInst from "./../@images/banner-inst.png"


let BannerBorder = styled.div`
    background-color: white;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 20px;
`

let BannerImage = styled.div`
    max-width: 300px;
    max-height: 200px;
    min-width: 300px;
    min-height: 200px;
    border-radius: 4px;
    border-style: solid;
    border-color: #dedede;
    border-width: 1px;
    object-fit: cover;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`

let InputTag = styled.input`
    width: 400px;
    height: 40px;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #dedede;
    outline: none;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 14px;
    font-weight: 400;
`

let ButtonSubmit = styled.button`
    width: 100px;
    height: 50px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: grey;
    border-radius: 4px;
    border: none;
    color: white;
    cursor: pointer;
`

let DelButton =styled.button`
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    color: #a9a9a9;
`

let InputTag01 = styled.input`
    width: 100%;
    height: 40px;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #dedede;
    outline: none;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 14px;
    font-weight: 400;
`
const Banner = () => {
    const midBack = useSelector((state) => state.midBack);
    let [instBanner, setInstBanner] = useState(bannerInst); //뷰 용 배너
    let [banner, setBanner] = useState(''); //서버용 배너
    let [discription, setDiscription] = useState('');
    let [bannerUrl, setBannerUrl] = useState('');
    let [bannerData, setBannerData] = useState([]);

    let [ischange, setIsChange] = useState(false)

    useEffect(()=>{
        const fetchData = async () => {
            try {
              const response = await axios.get('https://api.midtrc.com/banner', {
                headers: {
                  'access-token': midBack?.log_data?.access_token,
                },
              });
              
              setBannerData(response.data);
            } catch (error) {
              console.error(error);
            }
          };
      
          fetchData();
    },[ischange])

    // 배너등록 데이터 핸들러
    let ref01 = useRef();
    const addBannerHandler = (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setInstBanner(reader.result);
          };
          reader.readAsDataURL(file);
          setBanner(file);
        }
        ref01.current.value = null;
    };

    const siteDescription = (e) => {
        setDiscription(e.target.value)
    }

    const bannerUrlHandler = (e) => {
        setBannerUrl(e.target.value)
    }

    const submitHandler = () => {
        let requestBody  = {
            "banner_type": "banner",
            "description": discription || '',
            "size": "string",
            "click_url": bannerUrl || ''
          }
          const jsonRequestData = JSON.stringify(requestBody );
          const formData = new FormData();
          formData.append('req_body',jsonRequestData);
          if(banner){
            formData.append('image',banner);
          }
          axios.post('https://api.midtrc.com/banner', formData, {
            headers: {
              'access-token': midBack?.log_data?.access_token,
            },
          }).then((res)=>{
            setBannerUrl('')
            setDiscription('');
            setBanner('');
            setInstBanner('');
            setIsChange(!ischange)
        }).catch((error)=>console.log(error))
    }

    const CurrentBanner = ({data}) => {
        const deletHandler = () => {
            axios.delete(`https://api.midtrc.com/banner/${data.id}`,{
                headers: {
                  'access-token': midBack?.log_data?.access_token,
                },
            }).then((res)=>{setIsChange(!ischange)}).catch((error)=>{console.log(error)})


        }

        return(
            <div>
                <BannerImage>
                <img style={{width: '100%', height: "100%", objectFit: "contain"}} src={data?.img_url} alt='banner01'/> 
                    <DelButton onClick={()=>{deletHandler()}}>
                        x
                    </DelButton>
                </BannerImage>
                <InputTag01 readOnly value={data?.description} onChange={()=>{}} placeholder="사이트 설명"/>
                <InputTag01 readOnly value={data?.click_url} onChange={()=>{}} placeholder="링크 주소"/>
            </div>
        )
    }


    return(
        <BannerBorder>
            <div style={{display: "flex", columnGap: "20px", position: "relative"}}>
                <input ref={ref01} onChange={addBannerHandler} type="file" style={{display: "none"}}/>
                <BannerImage onClick={()=>{ref01.current.click()}}>
                    <img style={{width: '100%', height: "100%", objectFit: "contain"}} src={instBanner} alt='banner01'/> 
                </BannerImage>
                <div style={{display: "grid", gridTemplateColumns: "2fr 8fr", height: "40px"}}>
                    <div style={{height: '100%', display: "flex", alignItems: "center", justifyContent: "left"}}>
                        사이트 설명
                    </div>
                    <InputTag value={discription} onChange={siteDescription} placeholder="사이트 한줄 설명을 입력하세요"/>
                    <div style={{height: '100%', display: "flex", alignItems: "center", justifyContent: "left"}}>
                        링크 주소
                    </div>
                    <InputTag value={bannerUrl} onChange={bannerUrlHandler} placeholder="링크 주소를 입력하세요"/>
                </div>
                <ButtonSubmit onClick={()=>{submitHandler()}}>
                    배너 등록
                </ButtonSubmit>
            </div>
            <div style={{width:"100%", borderStyle: "solid", borderColor: "#dedede", borderWidth: "0 0 1px 0", marginTop: "20px"}} />
            <div style={{display: "grid", gridTemplateColumns: '1fr 1fr 1fr 1fr', columnGap: "10px", rowGap: "50px", marginTop: "20px"}}>
                {
                    bannerData?.map((a, i)=> (
                        <CurrentBanner data={a} key={i}/>
                    ))
                }
            </div>
        </BannerBorder>
    )
}


export default Banner;