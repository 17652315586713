import React, { useEffect, useState } from 'react';
import './DeclarationDetail.css';
import profile from '../img/profile.png';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ComplainJobDetailPage from './@complain-job-detail';

const DeclarationDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams(); 
  const midBack = useSelector((state) => state.midBack);  // Redux에서 midBack 데이터를 가져옴
  const [clientData, setClientData] = useState(null);  // 작성자 데이터 상태
  const [complainClientData, setComplainClientData] = useState(null);
  const [content, setContent] = useState(''); 
  const [status, setStatus] = useState('');
  const [serviceId, setServiceId] = useState(null);
   
  // API 호출로 데이터 가져오기
  const fetchComplainDetail = async () => {
    try {
      const response = await axios.get(`https://api.midtrc.com/complain/${id}`, {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });
  
      console.log(response.data);  // 데이터 구조 확인
      setContent(response.data.content); 
      setClientData(response.data.client);  // 작성자 데이터 설정
      setComplainClientData(response.data.complain_client);  // 신고자 데이터 설정
      setStatus(response.data.status);
      setServiceId(response.data.service_id); 
    } catch (error) {
      console.error('Error fetching complain detail:', error);
    }
  };
  
  useEffect(() => {
    if (midBack) {
      fetchComplainDetail();
    }
  }, [midBack, id]);
  

  const handleStatusChange = async (e) => {
    const newStatus = e.target.value;
  
    try {
      const requestBody = {
        "before": status,  // 기존 상태를 before에 저장
        "after": newStatus // 새로운 상태를 after에 저장
      };
  
      console.log('Request Body:', requestBody);  // 요청 바디를 로그로 출력
  
      // 정확한 엔드포인트를 사용하여 요청을 보냅니다.
      const response = await axios.put(`https://api.midtrc.com/complain/${id}/status`, requestBody, {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });
  
      console.log('Response:', response.data);  // 서버 응답 로그 출력
      console.log('Status updated successfully');
  
      // 상태 업데이트 후 데이터 다시 가져오기
      fetchComplainDetail();
    } catch (error) {
      console.error('Error updating status:', error);
      console.error('Error details:', error.response?.data);  // 에러 응답의 자세한 내용을 출력
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('정말 공고를 삭제하시겠습니까?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`https://api.midtrc.com/recruit/${id}`, {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });
      alert('공고가 성공적으로 삭제되었습니다.');
      navigate('/declaration');
    } catch (error) {
      console.error('Error deleting recruit:', error);
      alert('공고 삭제 중 오류가 발생했습니다.');
    }
  };

  // 작성자 정보 표시
  const MyPageContentsBorder = () => {
    return (
      <div className="default-contents-border" style={{ marginBottom: "20px" }}>
        <div className="default-contents-border-top">
          작성자
        </div>
        <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "1fr 4.5fr 4.5fr", gap: "20px" }}>
          <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div><img src={clientData?.profile_url || profile} alt='profile' /></div>
            <div className='default-contents-border-title' style={{ justifyContent: "center" }}>{clientData?.nickname || '없음'}</div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                가입유형
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.client_type === 'user' ? '일반회원' : '사업자회원'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이름
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.name || '없음'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                연락처
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.phone_num || '없음'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이메일
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.email || '없음'} />
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                업종
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.business_industry || '없음'} />
              </div>
            </div>
  
          </div>
        </div>
      </div>
    );
  };

  const MyPageContentsBorder2 = () => {
    return (
      <div className="default-contents-border" style={{ marginBottom: "20px" }}>
        <div className="default-contents-border-top">
          신고자
        </div>

        <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "1fr 4.5fr 4.5fr", gap: "20px" }}>
          <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div><img src={complainClientData?.profile_url || profile} alt='profile' /></div>
            <div className='default-contents-border-title' style={{ justifyContent: "center" }}>{complainClientData?.nickname || "없음"}</div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                가입유형
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={complainClientData?.client_type === 'user' ? '일반회원' : '사업자회원'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이름
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={complainClientData?.name || "없음"} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                연락처
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={complainClientData?.phone_num || "없음"} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이메일
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={complainClientData?.email || "없음"} />
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                신고내용
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={content || '신고 내용이 없습니다'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-tag" value={''} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-tag" value={''} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                처리상태
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <select className="default-contents-border-select" style={{ textAlign: "center" }} value={status} onChange={handleStatusChange}>
                  <option value="waiting">처리전</option>
                  <option value="complete">처리완료</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DefaultContentsBorder = () => {
    return (
      <div className="default-contents-border" style={{ padding: "20px 10px 10px 10px" }}>
        <ComplainJobDetailPage serviceId={serviceId} />
        
        {/* 수정 취소 탭 */}
        <div className='default-contents-border-confirm'>
          <button className='default-contents-border-confirm-button' onClick={() => {navigate(`/declaration`)}}>취 소</button>
          <button 
            className='default-contents-border-confirm-button' 
            style={{background:"#ff0000", color:"#fff"}} 
            onClick={handleDelete} // 삭제 핸들러 연결
          >
            삭 제
          </button>
        </div>
      </div>
    );
  };

  return (
    <div>
      <MyPageContentsBorder />
      <MyPageContentsBorder2 />
      <DefaultContentsBorder />
    </div>
  );
};

export default DeclarationDetail;
