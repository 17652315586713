import React, { useState, useEffect } from 'react';
import closeButton from '../img/close-button.png';
import styled from 'styled-components';
import profile from '../img/profile.png';
import axios from 'axios';
import { useSelector } from 'react-redux';

const ContentBorder = styled.div`
  border-style: solid;
  border-color: #dedede;
  border-radius: 4px;
  border-width: 1px;
`;

const ContentBorder2 = styled.div`
  border-style: solid;
  border-color: #dedede;
  border-radius: 4px;
  border-width: 1px;
  background-color:white;
`;

const ContentMargin = styled.div`
   width:100%;
   height:20px;
   background-color:#ECF0F4;
`;

const CounselContentContainer = styled.div`
   width:100%;
  box-sizing: border-box;
  padding: 20px 20px 20px 20px;
`;

const CounselContent = styled.textarea`
  width:100%;
  box-sizing: border-box;
  font-size:14px;
  background-color: #f2f2f2;
  border-color: #dedede;
   border-width: 1px;
    border-radius: 4px;
    resize:none;
   height:150px;
`;

const CounselContenttitle = styled.div`
  font-weight: 500;
  font-family: 'Noto Sans KR', sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  color: #a9a9a9;
  font-size: 14px;
`;

const CounselContentfile = styled.div`
  font-weight: 500;
  font-family: 'Noto Sans KR', sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  color: #a9a9a9;
  font-size: 14px;
  background-color: #f2f2f2;
  border-color: #dedede;
   border-width: 1px;
    border-radius: 4px;
    width:100%;
    height:40px;
      box-sizing: border-box;
  padding-left:20px;
  padding-right:20px;
`;

const CounselContentcreatedate = styled.div`
  font-weight: 500;
  font-family: 'Noto Sans KR', sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  color: #a9a9a9;
  font-size: 14px;
  background-color: #f2f2f2;
  border-color: #dedede;
   border-width: 1px;
    border-radius: 4px;
    width:100%;
    height:40px;
      box-sizing: border-box;
  padding-left:20px;
  padding-right:20px;
`;

const CounselReply = styled.textarea`
  width:100%;
  box-sizing: border-box;
  font-size:14px;
  background-color: #f2f2f2;
  border-color: #dedede;
   border-width: 1px;
    border-radius: 4px;
    resize:none;
    height:150px;
`;

const CounselReplyFileUpload = styled.div`
  font-weight: 500;
  font-family: 'Noto Sans KR', sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  color: #a9a9a9;
  font-size: 14px;
  background-color: #f2f2f2;
  border-color: #dedede;
   border-width: 1px;
    border-radius: 4px;
    width:100%;
    height:40px;
    box-sizing: border-box;
    padding-left:20px;
    padding-right:20px;
    cursor:pointer;
`;

const CounselSaveBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 45px;
  border: none;
  background-color: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    background-color: #183B5B;
    color: white;
  }
`;

let PaddingBorder = styled.div`
  padding: 20px;
  overflow: auto;

`

const OneinquiryModal = ({ modalManager, data }) => {
  const [clientData, setClientData] = useState(null);
  const [reply, setReply] = useState(data.reply || '');
  const [fileName, setFileName] = useState(data.sub_file_url ? '파일 업로드됨' : '파일 업로드');
  const midBack = useSelector((state) => state.midBack);
  const [replyFile, setreplyFile] = useState(null);

  useEffect(() => {
    const fetchClientData = async () => {
      try {
        const response = await axios.get(`https://api.midtrc.com/client/${data.client_id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setClientData(response.data);
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };

    fetchClientData();
  }, [data.client_id, midBack]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      setreplyFile(file);
    }
  };

  const replyHandler = () => {

    let requestBody = {
      "status": "complete",
      "counsel_type": "one_on_one",
      "content": data.content,
      "reply": reply
    }

    const jsonRequestData = JSON.stringify(requestBody);
    const formData = new FormData();
    formData.append('req_body', jsonRequestData);
    if(replyFile){
      formData.append('sub_image', replyFile);
    }
    
    axios.put(`https://api.midtrc.com/counsel/${data.id}`,formData,{
      headers: {
        'access-token': midBack?.log_data?.access_token,
      },
    }).then((res)=>{modalManager(null);}).catch((error)=>{console.log(error)});
  }

  return (
    <div className="default-modal-border" style={{ height: "100vh" }} onClick={(e) => { e.stopPropagation(); modalManager(null); }}>
      <div className="members-detail-modal-border" onClick={(e) => { e.stopPropagation(); }} style={{ background: "#ECF0F4" }}>
        <div className="default-contents-border-top" style={{ width: "100%", justifyContent: "space-between", minHeight: "40px", backgroundColor: "white", borderRadius: "4px 4px 0px 0px" }}>
          <div>문의 상세보기</div>
          <img
            onClick={() => { modalManager(null); }}
            src={closeButton} alt="close-box" style={{ width: "14px", height: "14px", cursor: "pointer" }}
          />
        </div>
        <PaddingBorder>
          <div className="members-detail-modal-contents-border" style={{ background: "#fff"}}>
            <div className="default-contents-border" style={{marginBottom: "20px" }}>
              <div style={{boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "1fr 4.5fr 4.5fr", gap: "20px"}}>
                <div style={{textAlign:"center", display:"flex",flexDirection:"column",justifyContent:"center"}}>
                  <div>
                    <img src={clientData?.profile_url || profile} alt='profile' />
                  </div>
                  <div className='default-contents-border-title' style={{justifyContent:"center"}}>
                    {clientData?.nickname || '없음'}
                  </div>
                </div>
                <div>
                  <div style={{display:"grid", gridTemplateColumns:"2fr 8fr", gap:"20px"}}>
                    <div className="default-contents-border-title">가입유형</div>
                    <div style={{position: "relative"}}>
                      <input readOnly={true} className="default-input-line-tag" value={clientData?.client_type === 'user' ? '일반회원' : clientData?.client_type === 'business' ? '사업자회원' : '매니저'} />
                    </div>
                  </div>
                  <div style={{display:"grid", gridTemplateColumns:"2fr 8fr", gap:"20px"}}>
                    <div className="default-contents-border-title">이름</div>
                    <div style={{position: "relative"}}>
                      <input readOnly={true} className="default-input-line-tag" value={clientData?.name || '없음'} />
                    </div>
                  </div>
                  <div style={{display:"grid", gridTemplateColumns:"2fr 8fr", gap:"20px"}}>
                    <div className="default-contents-border-title">연락처</div>
                    <div style={{position: "relative"}}>
                      <input readOnly={true} className="default-input-line-tag" value={clientData?.phone_num || '없음'} />
                    </div>
                  </div>
                  <div style={{display:"grid", gridTemplateColumns:"2fr 8fr", gap:"20px"}}>
                    <div className="default-contents-border-title">이메일</div>
                    <div style={{position: "relative"}}>
                      <input readOnly={true} className="default-input-line-tag" value={clientData?.email || '없음'} />
                    </div>
                  </div>
                </div>
                <div>
                  <div style={{display:"grid", gridTemplateColumns:"2fr 8fr", gap:"20px"}}>
                    <div className="default-contents-border-title">생년월일</div>
                    <div style={{position: "relative"}}>
                      <input readOnly={true} className="default-input-line-tag" value={clientData?.birthday || '없음'} />
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <ContentBorder>
            </ContentBorder>
            <ContentMargin/>
            <ContentBorder2>
              <div className="default-contents-border-top" style={{ width: "100%", justifyContent: "space-between", minHeight: "40px", backgroundColor: "white", borderRadius: "4px 4px 0px 0px" }}>
                <div>문의 내용</div>
              </div>
              <CounselContentContainer>
                {/* 문의 및 답변 부분은 수정하지 않고 그대로 유지 */}
                <CounselContenttitle>작성시간</CounselContenttitle>
                <CounselContentcreatedate>
                  {data.created_at}
                </CounselContentcreatedate>
                <CounselContenttitle>문의 내용</CounselContenttitle>
                <CounselContent readOnly={true} value={data.content} />
                <CounselContenttitle>첨부 파일</CounselContenttitle>
                <CounselContentfile>
                  {data.main_file_url ? <a href={data.main_file_url} download target="_blank">{data.main_file_url.split('/').pop()}</a> : '없음'}
                </CounselContentfile>
              </CounselContentContainer>
            </ContentBorder2>
            <ContentMargin/>
            <ContentBorder2>
              <div className="default-contents-border-top" style={{ width: "100%", justifyContent: "space-between", minHeight: "40px", backgroundColor: "white", borderRadius: "4px 4px 0px 0px" }}>
                <div>답변 내용</div>
              </div>
              <CounselContentContainer>
                <CounselContenttitle>답변 등록</CounselContenttitle>
                <CounselReply value={reply} onChange={(e) => setReply(e.target.value)} />
                <CounselContenttitle>첨부 파일</CounselContenttitle>
                <CounselReplyFileUpload onClick={() => document.getElementById('file-upload').click()}>
                  <input type="file" style={{ display: 'none'}} id="file-upload" onChange={handleFileChange} />
                  {fileName}
                </CounselReplyFileUpload>
                <div className="default-contents-border-confirm">
                  <CounselSaveBtn onClick={()=>{replyHandler()}}>
                    답변 저장 후 처리 완료
                  </CounselSaveBtn>
                </div>
              </CounselContentContainer>
            </ContentBorder2>
          </div>
        </PaddingBorder>
      </div>
    </div>
  );
};

export default OneinquiryModal;
