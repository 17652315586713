import React, { useEffect, useState } from 'react';
import './Message.css';
import axios from 'axios';
import { useSelector } from 'react-redux';

const Message = ({ modalManager }) => {
  const midBack = useSelector((state) => state.midBack);
  const [messageList, setMessageList] = useState([]);
  const [filteredMessageList, setFilteredMessageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSet, setCurrentSet] = useState(1);
  const [clickedIndex, setClickedIndex] = useState('관리자 메시지');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const itemsPerPage = 10;
  const pagesPerSet = 5;

  const fetchMessages = async (groupType) => {
    try {
      const response = await axios.get(`https://api.midtrc.com/managed_message?group_type=${groupType}`, {
        headers: {
          'access-token': midBack?.log_data?.access_token,
        },
      });
      const sortedData = response.data.sort((a, b) => b.id - a.id).map((msg) => ({
        ...msg,
        isSelected: false,
      }));
      setMessageList(sortedData);
      setFilteredMessageList(sortedData);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setMessageList([]);
      setFilteredMessageList([]); // 에러가 발생하더라도 빈 리스트로 초기화
    }
  };

  useEffect(() => {
    const groupType = clickedIndex === '관리자 메시지' ? 'manage' : 'event';
    fetchMessages(groupType);
  }, [clickedIndex, midBack]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextSet = () => {
    const totalItems = filteredMessageList.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const endPage = currentSet * pagesPerSet;
    if (endPage < totalPages) {
      setCurrentSet((prevSet) => prevSet + 1);
    }
  };

  const handlePrevSet = () => {
    if (currentSet > 1) {
      setCurrentSet((prevSet) => prevSet - 1);
    }
  };

  const handleAllSelect = () => {
    const newSelectedState = !isAllSelected;
    setIsAllSelected(newSelectedState);
    setFilteredMessageList((prevList) =>
      prevList.map((message) => ({
        ...message,
        isSelected: newSelectedState,
      }))
    );
  };

  const handleMessageSelect = (id) => {
    setFilteredMessageList((prevList) =>
      prevList.map((message) =>
        message.id === id ? { ...message, isSelected: !message.isSelected } : message
      )
    );
  };

  const handleSearch = () => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = messageList.filter(
      (message) =>
        message.content.toLowerCase().includes(lowerCaseQuery) ||
        (message.contact_type === 'direct' && message.contact_login_id.toLowerCase().includes(lowerCaseQuery))
    );
    setFilteredMessageList(filtered);
    setCurrentPage(1);
    setCurrentSet(1);
  };

  const handleDeleteSelected = async () => {
    const selectedIds = filteredMessageList
      .filter((message) => message.isSelected)
      .map((message) => message.id);

    if (selectedIds.length === 0) {
      alert('삭제할 메시지를 선택하세요.');
      return;
    }

    if (!window.confirm('정말 메시지를 삭제하시겠습니까?')) {
      return;
    }

    try {
      await axios.delete('https://api.midtrc.com/managed_message', {
        headers: {
          'access-token': midBack?.log_data?.access_token,
        },
        data: selectedIds,
      });

      setMessageList((prevList) => prevList.filter((message) => !selectedIds.includes(message.id)));
      setFilteredMessageList((prevList) => prevList.filter((message) => !selectedIds.includes(message.id)));
      setIsAllSelected(false);
    } catch (error) {
      console.error('Error deleting messages:', error);
      alert('메시지 삭제 중 오류가 발생했습니다.');
    }
  };

  const MyPageIndexBorder = () => {
    const IndexButton = ({ name }) => {
      const handleClick = () => {
        setClickedIndex(name);
        setSearchQuery(''); // 검색어 초기화
        setCurrentPage(1); // 페이지도 초기화
        setCurrentSet(1); // 페이지 셋도 초기화
      };

      return (
        <div>
          {clickedIndex === name ? (
            <div className="default-index-border-button" style={{ backgroundColor: '#EDF5FF' }} onClick={handleClick}>
              {name}
            </div>
          ) : (
            <div className="default-index-border-button" onClick={handleClick}>
              {name}
            </div>
          )}
        </div>
      );
    };

    return (
      <div className="default-index-border" style={{ paddingBottom: '5px' }}>
        <IndexButton name={'관리자 메시지'} />
        <IndexButton name={'이벤트 메시지'} />
      </div>
    );
  };

  const DefaultPagenation = () => {
    const totalItems = filteredMessageList.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startPage = (currentSet - 1) * pagesPerSet + 1;
    const endPage = Math.min(currentSet * pagesPerSet, totalPages);

    return (
      <div style={{ display: 'flex', height: '50px', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
        {startPage > 1 && <div onClick={handlePrevSet} className="default-contents-pagenation-button">{'<'}</div>}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
          <div
            key={page}
            onClick={() => handlePageChange(page)}
            className="default-contents-pagenation-button"
            style={{ color: page === currentPage ? 'black' : 'grey' }}
          >
            {page}
          </div>
        ))}
        {endPage < totalPages && <div onClick={handleNextSet} className="default-contents-pagenation-button">{'>'}</div>}
      </div>
    );
  };

  const MessageList = ({ modalManager }) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = filteredMessageList.slice(startIndex, startIndex + itemsPerPage);

    if (currentItems.length === 0) {
      return (
        <div className="default-contents-border-top" style={{ textAlign: "center", padding: "20px" }}>
          데이터가 없습니다.
        </div>
      );
    }

    return (
      <>
        {currentItems.map((message) => (
          <div
            key={message.id}
            className="default-contents-border-top"
            style={{
              fontSize: '14px',
              color: 'black',
              height: '50px',
              display: 'grid',
              gridTemplateColumns: '0.3fr 2.5fr 4fr 1fr 1fr 1fr',
            }}
          >
            <input
              type="checkbox"
              className="default-contents-border-check-box"
              checked={message.isSelected}
              onChange={() => handleMessageSelect(message.id)}
            />
            <div style={{ width: '100%', textAlign: 'center' }}>{message.id}</div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              {message.content.length > 30 ? `${message.content.slice(0, 30)}...` : message.content}
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>
              {message.contact_type === 'all' && '전체회원'}
              {message.contact_type === 'general' && '일반회원'}
              {message.contact_type === 'business' && '사업자회원'}
              {message.contact_type === 'female' && '여성회원'}
              {message.contact_type === 'male' && '남성회원'}
              {message.contact_type === 'direct' && `특정회원(${message.contact_login_id})`}
            </div>
            <div style={{ width: '100%', textAlign: 'center' }}>{new Date(message.created_at).toLocaleDateString()}</div>
            <div
              onClick={() => {
                modalManager({ type: '발송보기', data: { messageId: message.id } });
              }}
              style={{ width: '100%', textAlign: 'center', color: 'blue', cursor: 'pointer' }}
            >
              발송보기
            </div>
          </div>
        ))}
      </>
    );
  };

  const DefaultContentsBorder = () => {
    return (
      <div className="default-contents-border">
        <div
          className="default-contents-border-top"
          style={{
            fontSize: '14px',
            height: '50px',
            display: 'grid',
            gridTemplateColumns: '0.3fr 2.5fr 4fr 1fr 1fr 1fr',
          }}
        >
          <input
            type="checkbox"
            className="default-contents-border-check-box"
            onChange={handleAllSelect}
            checked={isAllSelected}
          />
          <div style={{ width: '100%', textAlign: 'center' }}>번호</div>
          <div style={{ width: '100%', textAlign: 'center' }}>발송 내용</div>
          <div style={{ width: '100%', textAlign: 'center' }}>발송 대상</div>
          <div style={{ width: '100%', textAlign: 'center' }}>발송 일자</div>
          <div style={{ width: '100%', textAlign: 'center' }}>상세보기</div>
        </div>
        <MessageList modalManager={modalManager} />
        <DefaultPagenation />
      </div>
    );
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '2fr 8fr', gap: '20px' }}>
      <div>
        <MyPageIndexBorder />
      </div>
      <div>
        <div style={{ marginBottom: '20px' }}>
          <div className="default-contents-border" style={{ height: '50px', position: 'relative' }}>
            <input
              placeholder="검색어를 입력하세요"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                background: 'none',
                outline: 'none',
                border: 'none',
                width: '100%',
                height: '100%',
                fontWeight: '400',
                fontFamily: "'Noto Sans KR', sans-serif",
                fontSize: '14px',
                boxSizing: 'border-box',
                paddingLeft: '10px',
              }}
            />
            <button
              className="members-contents-button"
              style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
              onClick={handleSearch}
            >
              검색
            </button>
          </div>
        </div>
        <div
          className="default-contents-border"
          style={{
            height: '50px',
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '10px',
            paddingRight: '10px',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button className="members-contents-button" onClick={handleAllSelect}>전체선택</button>
            <div style={{ fontSize: '14px', fontWeight: '600', color: '#a9a9a9', marginLeft: '20px' }}>{filteredMessageList.length}건</div>
            <input readOnly={true} className="default-input-tag" value={'메시지'} style={{ width: 'auto', marginLeft: '20px' }} />
          </div>
          <div style={{ display: 'flex', gap: '10px' }}>
            <button
              className="members-contents-button"
              style={{ backgroundColor: 'red', color: 'white' }}
              onClick={handleDeleteSelected}
            >
              삭제
            </button>
            <button
              className="members-contents-button"
              style={{ backgroundColor: '#183B5B', color: 'white' }}
              onClick={() => {
                modalManager({ type: '메시지추가' });
              }}
            >
              추가
            </button>
          </div>
        </div>
        <DefaultContentsBorder />
      </div>
    </div>
  );
};

export default Message;
