import React, { useEffect, useState } from 'react'
import './ChoiceTalk.css'
import OpenEyes from '../img/choice-talk/visible_0.png'
import CloseEyes from '../img/choice-talk/invisible_0.png'
import styled from 'styled-components'
import ChioceGroupNew from './ChoiceTalkGroup'
import axios from 'axios'
import { useSelector } from 'react-redux'


const ChoiceTalk = ({modalManager}) => {
  const midBack = useSelector((state) => state.midBack);
  let [groupData, setGroupData] = useState([]);
  let [change, setChange] = useState(false)

  useEffect(()=>{
    const fetchData = async () => {
      try {
        let response = await axios.get('https://api.midtrc.com/choice',{
          headers: {
            'access-token': midBack?.log_data?.access_token,
          }
        })
        setGroupData(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData();
  },[change])


  useEffect(()=>{
    const fetchData = async () => {
      try {
        let response = await axios.get('https://api.midtrc.com/choice',{
          headers: {
            'access-token': midBack?.log_data?.access_token,
          }
        })
        setGroupData(response.data)
      } catch (error) {
        console.log(error)
      }
    }
    
    const interval = setInterval(() => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();

      // 오후 7시부터 다음날 오전 11:30분 사이에만 fetchData 실행
      if ((currentHour >= 11 && currentHour < 24) || 
          (currentHour >= 0 && (currentHour < 11 || (currentHour === 11 && currentMinutes <= 30)))) {
          // fetchData();
      }
    }, 5000);

    // 컴포넌트 언마운트 시 타이머 정리
    return () => clearInterval(interval);
  },[change])




  const changeHandler = () => {
    setChange(!change)
  }

  return (
    <div className='ChoiceTalk'>
      <div className='ChoiceTalkCover'>
        {/* 탭 모음 */}
        <div className='ChoiceTalkArea'>
          {
            groupData?.map((a, i)=>(
              <ChioceGroupNew modalManager={modalManager} change={changeHandler} key={i} data={a}/>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default ChoiceTalk