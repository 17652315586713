import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import './App.css';
import Header from './Components/Header';
import SideGnb from './SideGnb/SideGnb';
import DashBoard from './DashBoard/DashBoard';
import Ceo from './Ceo/Ceo';
import Login from './Login/Login';
import Management from './ManagerManagement/Management';
import ManagerDetail from './ManagerManagement/ManagerDetail';
import LogoBanner from './LogoBanner/LogoBanner';
import MemberCheck from './MemberCheck/MemberCheck';
import MemberDetail from './MemberCheck/MemberDetail';
import Membership from './Membership/Membership';
import OneInquiry from './OneInquiry/OneInquiry';
import ChoiceTalk from './ChoiceTalk/ChoiceTalk';
import Announcement from './Announcement/Announcement';
import AnnoucementDetail from './Announcement/AnnoucementDetail';
import AnnoucementUpdate from './Announcement/AnnoucementUpdate';
import JobandKeyWords from './Announcement/JobKeywordSettings';
import Resume from './Resume/Resume';
import ResumeDetail from './Resume/ResumeDetail';
import Community from './Community/Community';
import Terrace from './Community/Terrace';
import TerraceDetail from './Community/TerraceDetail';
import TerraceUpdate from './Community/TerraceUpdate';
import Authority from './ManagerBoard/Authority';
import BoardManagement from './ManagerBoard/BoardManagement';
import BoardManageDetail from './ManagerBoard/BoardManageDetail';
import BoardManageUpdate from './ManagerBoard/BoardManageUpdate';
import BoardManageAdd from './ManagerBoard/BoardManage-add';
import BestPick from './Community/BestPick';
import BestPickUpdate from './Community/BestPickUpdate';
import Declaration from './Declaration/Declaration';
import DeclarationDetail from './Declaration/DeclarationDetail';
import DeclarationPost from './Declaration/DeclarationPost';
import DeclarationPostDetail from './Declaration/DeclarationPostDetail';
import DeclarationPostUpdate from './Declaration/DeclarationPostUpdate';
import DeclarationResume from './Declaration/DeclarationResume';
import DeclarationResumeDetail from './Declaration/DeclarationResumeDetail';
import DeclarationChat from './Declaration/DeclarationChat';
import DeclarationChatDetail from './Declaration/DeclarationChatDetail';
import Alliance from './Alliance/Alliance';
import AllianceDetail from './Alliance/AllianceDetail';
import AllianceManage from './Alliance/AllianceManage';
import MessageManage from './Message/Message';
import { useEffect, useState } from 'react';
import ChoiceModal from './ChoiceModal/ChoiceModal';
import ChatDetailModal from './ChoiceModal/ChatDetailModal';
import StoreDetailModal from './ChoiceModal/StoreDetailModal';
import MessageDetailModal from './Message/MessageDetailModal';
import MessageAddModal from './Message/MessageAddModalplus';
import { useDispatch, useSelector } from 'react-redux';
import OneinquiryModal from './OneInquiry/Oneinquiry-modal';
import { createGlobalStyle } from 'styled-components';
import { logout } from './redux/slice/login-slice';
import Banner from './LogoBanner/banner';


const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  html {
    font-size: 62.5%;
    // font-size: 60%;
    line-height: 1.285;
    font-family: 'Pretendard-Regular';
  }
  body {
    font-size: 1.6rem;
    line-height: 1.285;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const App = () => {
  let [currentModal, setCurrentModal] = useState(null);
  let midBack = useSelector((state)=>state.midBack);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let params =useParams();
  let location = useLocation();

  useEffect(()=>{
    if(midBack?.log_data?.access_token){
      
    }else{
      navigate('/login');
    }
  },[location.pathname])


  const ModalManager = (props) => {
    setCurrentModal(props);
  };

  const ModalStatus = () => {
    switch (currentModal?.type) {
      case '회원정보':
        return <MemberDetail modalManager={ModalManager} member={currentModal.data} />;
        case '채팅 수동 입력':
          return <ChoiceModal modalManager={ModalManager} data={currentModal.data}/>;
        case '채팅 내용 확인':
          return <ChatDetailModal modalManager={ModalManager} data={currentModal.data}/>;
        case '가게 상세 보기':
          return <StoreDetailModal modalManager={ModalManager} data={currentModal.data}/>;
      case '제휴 입점 문의':
        return <AllianceDetail modalManager={ModalManager} />;
      case '발송보기':
        return <MessageDetailModal modalManager={ModalManager} data={currentModal.data}/>;
      case '메시지추가':
        return <MessageAddModal modalManager={ModalManager} />;
      case '1:1문의':
        return <OneinquiryModal modalManager={ModalManager} data={currentModal.data}/>;
      default:
        return null;
    }
  };

  return (
    <div id="wrap">
      <GlobalStyle />
      <Header />
      <ModalStatus />
      <div className="content">
        <SideGnb />
        <div className='ch_content'>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/' element={<DashBoard />} />
            <Route path='/ceo' element={<Ceo />} />
            <Route path='/management' element={<Management />} />
            <Route path='/message' element={<MessageManage modalManager={ModalManager} />} />
            <Route path='/manager-detail' element={<ManagerDetail />} />
            <Route path='/logo-banner' element={<Banner />} />
            <Route path='/member-check' element={<MemberCheck modalManager={ModalManager} />} />
            <Route path='/member-detail' element={<MemberDetail />} />
            <Route path='/membership' element={<Membership />} />
            <Route path='/one-inquiry' element={<OneInquiry modalManager={ModalManager} />} />
            <Route path='/choice-talk' element={<ChoiceTalk modalManager={ModalManager} />} />
            <Route path='/announcement' element={<Announcement />} />
            <Route path='/announcement-detail/:recruitid' element={<AnnoucementDetail />} />
            <Route path='/annoucement-update/:recruitid' element={<AnnoucementUpdate />} />
            <Route path='/jobandkeywords' element={<JobandKeyWords />} />
            <Route path='/resume' element={<Resume />} />
            <Route path="/resume-detail/:resumeid" element={<ResumeDetail />} />
            <Route path='/community' element={<Community />} />
            <Route path='/best-pick' element={<BestPick />} />
            <Route path='/best-pick/update' element={<BestPickUpdate />} />
            <Route path='/terrace' element={<Terrace />} />
            <Route path='/terrace-detail/:id' element={<TerraceDetail />} />
            <Route path='/terrace-update/:id' element={<TerraceUpdate />} />
            <Route path='/authority' element={<Authority />} />
            <Route path='/board-management' element={<BoardManagement />} />
            <Route path='/board-management-add' element={<BoardManageAdd />} />
            <Route path='/boardmanage-detail/:id' element={<BoardManageDetail />} />
            <Route path='/boardmanage-update/:id' element={<BoardManageUpdate />} />
            <Route path='/declaration' element={<Declaration />} />
            <Route path='/declaration-detail/:id' element={<DeclarationDetail />} />
            <Route path='/declaration-resume-detail/:id' element={<DeclarationResumeDetail />} />
            <Route path='/declaration-post' element={<DeclarationPost />} />
            <Route path='/declaration-post-detail/:id' element={<DeclarationPostDetail />} />
            <Route path='/declaration-post-update/:id' element={<DeclarationPostUpdate />} />
            <Route path='/declaration-chat' element={<DeclarationChat />} />
            <Route path='/declaration-chat-detail/:id' element={<DeclarationChatDetail />} />
            <Route path='/alliance' element={<Alliance modalManager={ModalManager} />} />
            <Route path='/alliance-detail' element={<AllianceDetail />} />
            <Route path='/alliance-manage' element={<AllianceManage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
