import React, { useRef, useState } from 'react'
import './StoreDetailModal.css'
import plusImg2 from '../img/plusImg2.png'
import imgex8 from '../img/imgex8.png'
import imgex2 from '../img/imgex2.png'
import closeButton from '../img/close-button.png'
import axios from 'axios'
import { useSelector } from 'react-redux'

const StoreDetailModal = ({modalManager, data}) => {
  const midBack = useSelector((state) => state.midBack);
  let [profile, setProfile] = useState(null);
  let [profileInst, setProfileInst] = useState(data?.profile_url?data?.profile_url:null);
  let [nickname, setNickname] = useState(data?.client_nickname) // 닉네임
  let [gorup_title, setGroup_title] = useState(data?.name); // 그룹명
  let [choice_title, setChoice_title] = useState(data?.choice_title)
  let [notice, setNotice] = useState(data?.notice); // 접었다 펴지는 공지
  let [description, setdescription] = useState(data?.description);
  let [sub_description, setsub_description] = useState(data?.sub_description);
  let [region, setregion] = useState(data?.region); // 지역
  let [wordTitle, setWordTitle] = useState(data?.word_title); // 용어설명 제목
  let [isEvent, setIsEvent] = useState(data?.is_event); // 이벤트 유무

  let [wordImage, setWordImage] = useState(null); // 용어설명 이미지
  let [wordImageInst, setWordImageInst] = useState(data?.word_img_url?data?.word_img_url:null); 

  let [option01, setOption01] = useState(data?.option1);
  let [title01, setTitle01] = useState(data?.option1?.title);
  let [content01, setContent01] = useState(data?.option1?.content);
  let [option02, setOption02] = useState(data?.option2);
  let [title02, setTitle02] = useState(data?.option2?.title);
  let [content02, setContent02] = useState(data?.option2?.content);

  let ref01 = useRef(null);

  const profileHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileInst(reader.result);
      };
      reader.readAsDataURL(file);
      setProfile(file);
    }
    ref01.current.value = null;
  }

  const noticeHandler = (e) => {
    setNotice(e.target.value);
  }

  const descriptionHandler = (e) => {
    setdescription(e.target.value);
  }

  const sub_descriptionHandler = (e) => {
    setsub_description(e.target.value);
  }

  const titleHandler = (e) => {
    setChoice_title(e.target.value)
  }

  const option01TitleHandler = (e) => {
    setTitle01(e.target.value);
    setOption01({"title": e.target.value});
  }

  const option01ContentHandler = (e) => {
    setContent01(e.target.value);
    setOption01({"title": title01, "content": e.target.value});
  }

  const option02TitleHandler = (e) => {
    setTitle02(e.target.value);
    setOption02({"title": e.target.value});
  }

  const option02ContentHandler = (e) => {
    setContent02(e.target.value);
    setOption02({"title": title02, "content": e.target.value});
  }

  const regionHandler = (e) => {
    setregion(e.target.value)
  }

  const eventHandler = (e) => {
      setIsEvent(e.target.checked)
  }

  const wordTitleHandler = (e) => {
    setWordTitle(e.target.value);
  }

  let ref02 = useRef(null);

  const wordHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setWordImageInst(reader.result);
      };
      reader.readAsDataURL(file);
      setWordImage(file);
    }
    ref02.current.value = null;
  }

  const submitHandler = () => {

    let reqeustBody = {
      "client_id": data?.client_id,
      "name": gorup_title,
      "region": region,
      "choice_title": choice_title,
      "notice": notice,
      "description": description,
      "sub_description": sub_description,
      "option1": option01,
      "option2": option02,
      "is_event": isEvent,
      "word_title": wordTitle
    }

    const jsonRequestData = JSON.stringify(reqeustBody);
    const formData = new FormData();
    formData.append('req_body', jsonRequestData);
    if(profile){
      formData.append('profile_image', profile);
    }
    if(wordImage){
      formData.append('word_image', wordImage);
    }

    axios.put(`https://api.midtrc.com/choice/${data?.id}`, formData,{
      headers: {
        'access-token': midBack?.log_data?.access_token,
      }
    }).then((res)=>{
      modalManager(null)
      window.location.reload();
    }).catch((error)=>{console.log(error)});
  }

  

  const StoreDetailContent = () => {
    return(
      <div className='StoreDetailModalArea'>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>대표 이미지</div>
        <div className='StoreDetailModalTabRight'>
          <div className='ExBox'>
            <input ref={ref01} onChange={profileHandler} style={{display: 'none'}} type='file'/>
            <img onClick={()=>{ref01.current.click()}} style={{width: '100%', objectFit:'cover', cursor: 'pointer'}} src={profileInst} alt='plusImg2' />
          </div>
        </div>
      </div>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>방제목</div>
        <div className='StoreDetailModalTabRight'>
          <div style={{position: "relative"}}>
              <input readOnly={true} value={gorup_title} type="text" className="default-input-line-tag" />
          </div>
        </div>
      </div>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>부제목</div>
        <div className='StoreDetailModalTabRight' style={{marginTop:"5px"}}>
          <div style={{position: "relative"}}>
              <input readOnly={false} value={choice_title} onChange={titleHandler} type="text" className="default-input-line-tag"/>
          </div>
        </div>
      </div>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>공지사항</div>
        <div className='StoreDetailModalTabRight' style={{marginTop:"5px"}}>
          <div style={{position: "relative"}}>
              <input readOnly={false} value={notice} onChange={noticeHandler} type="text" className="default-input-line-tag"/>
          </div>
        </div>
      </div>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>설명</div>
        <div className='StoreDetailModalTabRight' style={{marginTop:"5px"}}>
          <div style={{position: "relative",marginTop:"0"}}>
              <input readOnly={false} value={description} onChange={descriptionHandler} type="text" className="default-input-line-tag"/>
          </div>
        </div>
      </div>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>설명 서브</div>
        <div className='StoreDetailModalTabRight'>
          <div style={{position: "relative"}}>
              <input readOnly={false} onChange={sub_descriptionHandler} type="text" className="default-input-line-tag" value={sub_description}/>
          </div>
        </div>
      </div>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>옵션1</div>
        <div className='StoreDetailModalTabRight'>
          <div className='DF'>
          <div style={{position: "relative"}}>
              <input readOnly={false} type="text" onChange={option01TitleHandler} className="default-input-line-tag" value={option01?.title||''}/>
          </div>
          {/* <div style={{position: "relative"}}>
              <input readOnly={false} type="text" onChange={option01ContentHandler} className="default-input-line-tag" value={option01?option01.content:''}/>
          </div> */}
          </div>
        </div>
      </div>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>옵션2</div>
        <div className='StoreDetailModalTabRight'>
          <div className='DF'>
          <div style={{position: "relative"}}>
              <input readOnly={false} type="text" onChange={option02TitleHandler} className="default-input-line-tag" value={option02?.title||''}/>
          </div>
          {/* <div style={{position: "relative"}}>
              <input readOnly={false} type="text" onChange={option02ContentHandler} className="default-input-line-tag" value={option02?option02.content:''}/>
          </div> */}
          </div>
        </div>
      </div>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>지역구</div>
        <div className='StoreDetailModalTabRight'>
        <div style={{position: "relative"}}>
              <input style={{marginTop:"10px"}} readOnly={false} onChange={regionHandler} type="text" className="default-input-line-tag" value={region}/>
          </div>
        </div>
      </div>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>이벤트 태그</div>
        <div className='StoreDetailModalTabRight'>
          {/* <img src={imgex8} alt='imgex8' /> */}
          <input style={{width: '20px',marginTop:"14px"}} type='checkbox' onChange={eventHandler} checked={isEvent}/>
        </div>
      </div>
      <div className='StoreDetailModalTab'>
        <div className='default-contents-border-title'>용어 설명</div>
        <div className='StoreDetailModalTabRight' style={{marginTop:"10px"}}>
          <div className='Terms'>
            <input readOnly={false} type="text" className="default-input-line-tag" style={{backgroundColor: "white"}} value={wordTitle} onChange={wordTitleHandler}/>
            {/* <div className='TermsPoa'>0/30</div> */}
          </div>
          <div className='ExBox' style={{marginTop: "20px"}}>
            <input ref={ref02} onChange={wordHandler} style={{display: 'none'}} type='file'/>
            <img onClick={()=>{ref02.current.click()}} style={{width: '100%', height: 'auto', objectFit:'contain', cursor: 'pointer'}} src={wordImageInst} alt='plusImg2' />
          </div>
        </div>
      </div>
      <div className='default-contents-border-confirm'>
        <button onClick={()=>{ modalManager(null)}} className='default-contents-border-confirm-button'>취 소</button>
        <button onClick={()=>{submitHandler()}} className='default-contents-border-confirm-button' style={{background:"#183b5b",color:"#fff"}}>저 장</button>
      </div>
    </div>
    )
  }

  return(
    <div className="default-modal-border" style={{height: "100vh"}} onClick={(e)=>{e.stopPropagation(); modalManager(null)}}>
    <div className="members-detail-modal-border"  onClick={(e)=>{e.stopPropagation()}} style={{background:"#fff"}}>
        <div className="default-contents-border-top" style={{width: "100%", justifyContent: "space-between", minHeight: "40px", backgroundColor: "white", borderRadius: "4px 4px 0px 0px"}}>
            <div>
                채팅방 상세 보기
            </div>
            <img 
            onClick={()=>{modalManager(null)}}
            src={closeButton} alt="close-box" style={{width: "14px", height: "14px", cursor: "pointer"}}
            />
        </div>
        <div className="members-detail-modal-contents-border" style={{background:"#fff"}}>
            <div>
              {StoreDetailContent()}
            </div>
        </div>
    </div>
  </div>
  )
}

export default StoreDetailModal