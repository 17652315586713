import React, { useEffect, useState } from 'react';
import './Terrace.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import imgex3 from '../img/annoucement/imgex3.png';
import openEyes from '../img/choice-talk/visible_0.png';
import closedEyes from '../img/choice-talk/invisible_0.png';

const Terrace = () => {
    let navigate = useNavigate();
    const midBack = useSelector((state) => state.midBack);
    const [terraceList, setTerraceList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentSet, setCurrentSet] = useState(1);
    const [showPaused, setShowPaused] = useState(false);
    const [showActive, setShowActive] = useState(true);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [filter, setFilter] = useState('전체 보기');
    const [searchQuery, setSearchQuery] = useState(''); // 검색어 상태 추가
    const [filteredTerraceList, setFilteredTerraceList] = useState([]); // 필터링된 리스트 상태 추가
    const itemsPerPage = 10;
    const pagesPerSet = 5;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://api.midtrc.com/lounge', {
                    headers: {
                        'access-token': midBack?.log_data?.access_token
                    }
                });
                const sortedData = response.data
                    .map(item => ({ ...item, isSelected: false }))
                    .sort((a, b) => b.id - a.id); // id 값으로 내림차순 정렬
                setTerraceList(sortedData);
                setFilteredTerraceList(sortedData); // 초기 데이터 설정
            } catch (error) {
                console.error('Error fetching the terrace data:', error);
            }
        };

        fetchData();
    }, [midBack]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleNextSet = () => {
        setCurrentSet(prevSet => prevSet + 1);
        setCurrentPage((currentSet * pagesPerSet) + 1);
    };

    const handlePrevSet = () => {
        setCurrentSet(prevSet => prevSet - 1);
        setCurrentPage(((currentSet - 2) * pagesPerSet) + 1);
    };

    const handlePausedChange = () => {
        setShowPaused(!showPaused);
        setCurrentPage(1);
        setCurrentSet(1);
    };

    const handleActiveChange = () => {
        setShowActive(!showActive);
        setCurrentPage(1);
        setCurrentSet(1);
    };

    const handleAllSelect = () => {
        const newSelectedState = !isAllSelected;
        setTerraceList((prevList) =>
            prevList.map((item) => ({
                ...item,
                isSelected: newSelectedState,
            }))
        );
        setIsAllSelected(newSelectedState);
    };
    const handleTerraceSelect = (id) => {
        setTerraceList((prevList) =>
            prevList.map((item) =>
                item.id === id ? { ...item, isSelected: !item.isSelected } : item
            )
        );
    };

    useEffect(() => {
        const filteredList = filterTerraceList();
        setFilteredTerraceList(filteredList);
    }, [terraceList, showPaused, showActive, filter, searchQuery]);

    const handleDeleteSelected = async () => {
        const selectedIds = terraceList.filter(item => item.isSelected).map(item => item.id);
        if (selectedIds.length === 0) {
            alert('삭제할 게시글을 선택하세요.');
            return;
        }

        if (!window.confirm('정말 게시글을 삭제하시겠습니까?')) {
            return;
        }

        try {
            await Promise.all(
                selectedIds.map(id =>
                    axios.delete(`https://api.midtrc.com/lounge/${id}`, {
                        headers: {
                            'access-token': midBack?.log_data?.access_token
                        }
                    })
                )
            );

            setTerraceList(prevList => prevList.filter(item => !selectedIds.includes(item.id)));
            setIsAllSelected(false);
        } catch (error) {
            console.error('Error deleting terrace:', error);
            alert('게시글 삭제 중 오류가 발생했습니다.');
        }
    };

    const filterTerraceList = () => {
        let filteredList = terraceList;
    
        if (showPaused && !showActive) {
            filteredList = filteredList.filter(item => item.status === 'paused');
        } else if (!showPaused && showActive) {
            filteredList = filteredList.filter(item => item.status !== 'paused');
        } // showPaused와 showActive가 둘 다 true이면 전체를 보여줌
    
        switch (filter) {
            case '베스트픽-포토':
                filteredList = filteredList.filter(item => item.status === 'best_pick_photo');
                break;
            case '베스트픽':
                filteredList = filteredList.filter(item => item.status === 'best_pick');
                break;
            case '핫이슈':
                filteredList = filteredList.filter(item => item.hot_issue === true);
                break;
            case '라운지 - SNS형':
                filteredList = filteredList.filter(item => item.lounge_type === 'sns');
                break;
            case '라운지 - 일반형':
                filteredList = filteredList.filter(item => item.lounge_type === 'general');
                break;
            default:
                break;
        }
    
        if (searchQuery) {
            filteredList = filteredList.filter(item =>
                item.lounge_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.nickname.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
    
        return filteredList;
    };

    const DefaultPagenation = () => {
        const totalItems = filteredTerraceList.length;
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        const startPage = (currentSet - 1) * pagesPerSet + 1;
        const endPage = Math.min(currentSet * pagesPerSet, totalPages);

        return (
            <div style={{ display: "flex", height: "50px", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                {startPage > 1 && <div onClick={handlePrevSet} className="default-contents-pagenation-button">{"<"}</div>}
                {Array.from({ length: (endPage - startPage + 1) }, (_, i) => startPage + i).map(page => (
                    <div
                        key={page}
                        onClick={() => handlePageChange(page)}
                        className="default-contents-pagenation-button"
                        style={{ color: page === currentPage ? 'black' : 'grey' }}
                    >
                        {page}
                    </div>
                ))}
                {endPage < totalPages && <div onClick={handleNextSet} className="default-contents-pagenation-button">{">"}</div>}
            </div>
        );
    };

    const TerraceList = ({ item }) => {
        const getStatusImage = (status) => {
            switch (status) {
                case 'active':
                case 'best_pick':
                case 'best_pick_photo':
                    return openEyes;
                case 'paused':
                    return closedEyes;
                default:
                    return openEyes;
            }
        };

        return (
            <div className="default-contents-border-top"
                style={{
                    fontSize: "14px",
                    color: "black",
                    height: "50px",
                    display: "grid",
                    gridTemplateColumns: "0.3fr 0.5fr 1fr 2fr 1fr 1.3fr 1.3fr 0.8fr"
                }}>
                <input
                    type="checkbox"
                    className="default-contents-border-check-box"
                    checked={item.isSelected}
                    onChange={() => handleTerraceSelect(item.id)}
                />
                <div style={{ width: "100%", textAlign: "center" }}>
                    {item.id}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src={item.image_urls[0] || imgex3} alt='imgex3' style={{ height: "46px", paddingTop: "4px" }} />
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {item.lounge_title}
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {item.nickname}
                </div>
                <div style={{ width: "100%", textAlign: "center", cursor: "pointer" }} onClick={() => handleTerraceSelect(item.id)}>
                    <img src={getStatusImage(item.status)} alt='visibility' style={{ height: "20px", paddingTop: "4px" }} />
                </div>
                <div style={{ width: "100%", textAlign: "center" }}>
                    {new Date(item.created_at).toLocaleDateString()}
                </div>
                <div onClick={() => { navigate(`/terrace-detail/${item.id}`) }} style={{ width: "100%", textAlign: "center", color: "blue", cursor: "pointer" }}>
                    상세보기
                </div>
            </div>
        )
    };

    const DefaultContentsBorder = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const currentItems = filteredTerraceList.slice(startIndex, startIndex + itemsPerPage);

        return (
            <div className="default-contents-border">
                <div className="default-contents-border-top"
                    style={{
                        fontSize: "14px",
                        height: "50px",
                        display: "grid",
                        gridTemplateColumns: "0.3fr 0.5fr 1fr 2fr 1fr 1.3fr 1.3fr 0.8fr"
                    }}>
                    <input
                        type="checkbox"
                        className="default-contents-border-check-box"
                        onChange={handleAllSelect}
                        checked={isAllSelected}
                    />
                    <div style={{ width: "100%", textAlign: "center" }}>번호</div>
                    <div style={{ width: "100%", textAlign: "center" }}>이미지</div>
                    <div style={{ width: "100%", textAlign: "center" }}>본문 내용</div>
                    <div style={{ width: "100%", textAlign: "center" }}>아이디</div>
                    <div style={{ width: "100%", textAlign: "center" }}>상태</div>
                    <div style={{ width: "100%", textAlign: "center" }}>작성 일자</div>
                    <div style={{ width: "100%", textAlign: "center" }}>상세보기</div>
                </div>
                {currentItems.map((item) => (
                    <TerraceList key={item.id} item={item} />
                ))}
                <DefaultPagenation />
            </div>
        )
    };

    const handleSearch = () => {
        const filteredList = filterTerraceList(); // 검색어와 기타 필터링을 적용한 리스트 가져오기
        setFilteredTerraceList(filteredList); // 필터링된 리스트 상태 업데이트
        setCurrentPage(1); // 검색 시 페이지를 첫 페이지로 초기화
        setCurrentSet(1); // 검색 시 페이지 세트를 첫 세트로 초기화
    };

    return (
        <div>
            <div style={{ marginBottom: "20px" }}>
                <div className="default-contents-border" style={{ height: "50px", position: "relative" }}>
                    <input
                        placeholder="검색어를 입력하세요"
                        style={{ background: "none", outline: "none", border: "none", width: "100%", height: "100%", fontWeight: "400", fontFamily: "'Noto Sans KR', sans-serif", fontSize: "14px", boxSizing: "border-box", paddingLeft: "10px" }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)} // 검색어 상태 업데이트
                    />
                    <button
                        className="members-contents-button"
                        style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)" }}
                        onClick={handleSearch} // 검색 버튼 클릭 시 필터링 함수 호출
                    >
                        검색
                    </button>
                </div>

            </div>
            <div className="default-contents-border" style={{ height: "50px", marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <button className="members-contents-button" onClick={handleAllSelect}>
                        전체선택
                    </button>
                    <div style={{ fontSize: "14px", fontWeight: "600", color: "#a9a9a9", marginLeft: "20px" }}>
                        {terraceList.length}건
                    </div>
                    <select className="default-contents-border-select" style={{ marginLeft: "20px", textAlign: "center" }} value={filter} onChange={(e) => setFilter(e.target.value)}>
                        <option>전체 보기</option>
                        <option>베스트픽-포토</option>
                        <option>베스트픽</option>
                        <option>핫이슈</option>
                        <option>라운지 - SNS형</option>
                        <option>라운지 - 일반형</option>
                    </select>
                    <div className="default-contents-border-check-box-border" style={{ marginLeft: "20px" }}>
                        <div style={{ marginRight: "10px" }}>
                            숨김 게시글
                        </div>
                        <input type="checkbox" className="default-contents-border-check-box" onChange={handlePausedChange} />
                    </div>
                    <div className="default-contents-border-check-box-border" style={{ marginLeft: "20px" }}>
                        <div style={{ marginRight: "10px" }}>
                            노출중 게시글
                        </div>
                        <input type="checkbox" className="default-contents-border-check-box" onChange={handleActiveChange} checked={showActive} />
                    </div>
                </div>
                <div style={{ display: "flex", gap: "10px" }} >
                    <button className="members-contents-button" style={{ backgroundColor: "red", color: "white" }} onClick={handleDeleteSelected}>
                        삭제
                    </button>

                </div>
            </div>
            <DefaultContentsBorder />
        </div>
    )
}

export default Terrace;
