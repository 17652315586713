/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import more from "./../../@images/more-24.svg";
import like from "./../../@images/sns-like-black-40.svg";
import likeActive from './../../@images/like-active.svg';
import comment from "./../../@images/sns-comment-black-40.svg";
import arrow from "./../../@images/arrow-line-24.svg";
import emoji from "./../../@images/chat-emoji.svg";
import lock from "./../../@images/lock-40.svg";
import lockActive from "./../../@images/lock-active-24.svg";
import commentLike from "./../../@images/comment_like_24.svg";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import parse from 'html-react-parser';

let MypageBackground = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
`;

let MyNoticeBoardPostBorder = styled.div`
    margin-top: 0.8rem;
    width: 60rem;
    gap: 4rem;
    box-sizing: border-box;
    padding-bottom: 0.2rem;
    background-color: #fff;
    padding-top: 3rem;
`;

let MyNoticeBoardPostSection = styled.div`
    width: 60rem;
    padding-top: -2rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 2rem;
`;

let MypageNoticeBoardPostinfo = styled.div`
    padding-top: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: black;
    box-sizing: border-box;
    padding-bottom: 4rem;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #D2D2D2;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

let MypageTitleBar = styled.div`
    color: #171717;
    width: 50rem;
    display: flex;
    justify-content: space-between;
    align-items: center; 
`;

let MypageMyNoticeBoardPostwriter = styled.div`
    color: #171717;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 600;
`;

let MypageMyNoticeBoardPostcategory = styled.div`
    color: #6a6a6a;
    font-family: 'Pretendard-Regular';
    font-size: 1.2rem;
    font-weight: 600;
    cursor:pointer
`;

let MypageMyNoticeBoardPostdivider = styled.div`
    width:50rem;
    border-bottom: 1px solid #E8E8E8;
    margin-top:2rem;
    margin-bottom:2rem;
`;

let NoticeboardpostContainer = styled.div`
    width:50rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

let NoticeboardpostTitle = styled.div`
    width:50rem;
    font-size:1.4rem;
    margin-bottom: 2rem;
    font-weight:600;
    font-family: 'Pretendard-Regular';
`;

let Noticeboardpostingcontent = styled.div`
   width:50rem;
    font-size:1.3rem;
    gap: 2rem;
    margin-bottom: 2rem;
    font-family: 'Pretendard-Regular';
    line-height: 1.5;
`;

let ContentsBoarder = styled.div`
   width:50rem;
    box-sizing: border-box;
    padding: 3.2rem 3.2rem 1.6rem 3.2rem;
    border-top: solid;
    border-width: 0.1rem;
    border-color: #d2d2d2;
    border-radius: 0.4rem;
    margin-bottom: 1.6rem;
`;

let StatusBorder = styled.div`
  width:50rem;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

let SnsIconBorder = styled.div`
    display: flex;
    gap: 0.4rem;
    align-items: center;
`;

let SnsIcon = styled.img`
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    cursor: pointer;
`;

let SnsIconText = styled.div`
    height: auto;
    width: 3rem;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.4rem;
    color: #171717;
    text-align: left;
`;




// 코멘트
let CommentBorder = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
    border: none;
    background-color: #F7F7F7;
    padding: 1.6rem 2.4rem;
`;

let CommentsDropBorder = styled.div`
    display: flex;
    gap: 0.8rem;
`;

let CommentsDrop = styled.div`
    cursor: pointer;
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: white;
    position: relative;
`;

let CommentsNick = styled.input`
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: #E8E8E8;
    outline: none;
`

let FilterIconArrow = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
`;

let CommentsInputBorder= styled.div`
    width: 100%;
    min-height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 1.6rem;
    box-sizing: border-box;
    background-color: white;
    position: relative;
`;

let CommentsIconBorder = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.6rem;
    top: 0.8rem;
`;

let CommentsIcon = styled.img`
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    cursor: pointer;
`;

let CommentsSubmitButton = styled.button`
    height: 3.6rem;
    background-color: #CD1B1B;
    box-sizing: border-box;
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
`;

let CommentsInput = styled.textarea`
    box-sizing: border-box;
    width: 42.9rem;
    min-height: 5.6rem;
    resize: none;
    border: none;
    outline: none;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    box-sizing: border-box;
    overflow: hidden;
    padding: 1.6rem 0;
    line-height: 1.5;
`;

// 댓글
let ReplyMainBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
`;

let ReplyBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 0.8rem;
    box-sizing: border-box;
`;

let ReplyProfileImage = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    background-color: black;
`;

let ReplyContentsBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-contetn: right;
    box-sizing: border-box;
`;

let ReplyNickText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #171717;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
`;

let ReplyTimeText = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    margin-left: 0.8rem;
`;

let ReplyContentsText = styled.textarea`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #444444;
    width: 100%;
    resize: none;
    outline: none;
    border: none;
    padding: 0;
`;

let ReplyShowMore = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    cursor: pointer;
`;

let ReplyToolBorder = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 0.8rem;
    justify-content: right;
    align-items: flex-start;
`;

let ReplyIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`;

let ReplyCount = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    color: #9e9e9e;
    width: 100%;
    text-align: center;
`;

let ReReplyListBorder = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding-left: 3.2rem;
`;

let DropBorder = styled.div`
    width: 14rem;
    box-sizing: border-box;
    padding: 0 2rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    left: 0;
    z-index: 30;
`

let DropBorderItem = styled.div`
    font-size: 1.4rem;
    text-align: left;
    padding: 1rem 0;
    &:hover{
        color: red;
    }
`

let EmojisBorder = styled.div`
    
    height: 24rem;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    right: 0;
    z-index: 30;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow-y: auto;
    overflow-x: hidden;
`

let EmojisItems = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    font-size: 1.8rem;
    cursor: pointer;
    &:hover{
        background-color: #f2f2f2;
    }
`

let ShowmoreButton = styled.button`
    width: 100%;
    height: 3rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #a9a9a9;
    background: none;
    border: none;
    cursor: pointer;
`

let PhotoImage = styled.img`
    width: 100%;
    object-fit: cover;
    background-color: #f5f5f5;
    cursor: pointer;
`

const CommunityPostA = () => {
    const midBack = useSelector((state) => state.midBack);
    const [commetnsValue, setCommetnsValue] = useState('');
    const textareaRef = useRef(null);
    const params = useParams();
    let [data, setdata] = useState();
    let [changeData, setChangeData] = useState(false);
    let [notice_id, setnotice_id] = useState();
    let navigate = useNavigate();

    // 코멘트 작성
    let [isRandom, setIsRandom] = useState('랜덤 닉네임');
    let [isDrop, setIsDrop] = useState(false);
    let [isSecret, setIsSecret] = useState(false);
    let [commentsNick, setCommentsNick] = useState(''); // 닉네임 서버용
    let randomFirstNick = ['파랑', '빨강', '초록', '검정', '노랑', '분홍']
    let randomSecondNick = ['곰', '토끼', '여우', '팬더', '강아지', '고양이']
    let [comments, setComments] = useState(''); // 코멘트 서버용
    let [isCommentsDrop, setIsCommentsDrop] = useState(false);
    let emojis= [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯',
    ]
    console.log(params.id)
    useEffect(()=>{
        if(params.id){
            const fetchData = async () => {
                try {
                    const response = await axios.get(`https://api.midtrc.com/notice/${params.id}`,{
                        headers: {
                            'access-token': midBack?.log_data?.access_token
                        }
                    })
                    setdata(response.data);
                    setnotice_id(response.data.id);
                    
                } catch (error) {
                    console.log(error)
                }
            }
            fetchData();
        }

    },[params.id, midBack?.log_data?.access_token, changeData])


    useEffect(()=>{nicknameHandler({type: '랜덤 닉네임', text: ''})},[])

    let commentsHandler = (e) => {
        setComments(e.target.value);
    }

    let commentsEmojisHandler = (e) => {
        setComments(prevComments => prevComments + e);
    }
    
    let dropRef01 = useRef();
    
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setIsDrop(false);
        }else{
            setIsDrop(true);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    let dropRef02 = useRef();
    const dropRef02Handler = (event) => {
        if (dropRef02.current && !dropRef02.current.contains(event.target)) {
            setIsCommentsDrop(false);
        }else{
            setIsCommentsDrop(true);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', dropRef02Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef02Handler);
        };
    }, []);


    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [comments]);


    const nicknameHandler = (e) => {
        if(e.type === '랜덤 닉네임'){
            const firstPart = randomFirstNick[Math.floor(Math.random() * randomFirstNick.length)];
            const secondPart = randomSecondNick[Math.floor(Math.random() * randomSecondNick.length)];
            setCommentsNick(firstPart+secondPart);
        }else if(e.type === '고정 닉네임'){
            setCommentsNick(midBack?.log_data?.nickname?midBack?.log_data?.nickname:'')
        }else{
            setCommentsNick(e.text)
        }
    }

    const CommentSubmitHandler = () => {
        let reqeustBody = {
            "client_id": midBack?.log_data?.id,
            "notice": data?.id,
            "nickname": commentsNick,
            "is_private": isSecret,
            "content": comments
          }
          console.log(reqeustBody);

        axios.post('https://api.midtrc.com/comment',reqeustBody,{
            headers: {
                'access-token': midBack?.log_data?.access_token
            }
        }).then((res)=>{
            // isChange(); 
            nicknameHandler({type: '랜덤 닉네임', text: ''});
            setComments('');
            setIsSecret(false);
            isChange();
        }).catch((error)=>{console.log(error)});
    }


    const CommentSubmit = () => {
        return(
            <CommentBorder style={{marginBottom: "1.6rem"}}>
                {/* 코멘트 */}
                <CommentsDropBorder style={{marginBottom: "0.8rem"}}>
                        {/* 코멘트 드랍다운 */}
                        <div style={{display: "flex", gap: '0.8rem'}}>
                        <CommentsDrop ref={dropRef01} onClick={dropRef01Handler}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                {
                                    isRandom === '랜덤 닉네임'?
                                    <span>랜덤 닉네임</span>
                                    :
                                    isRandom === '고정 닉네임'?
                                    <span>고정 닉네임</span>
                                    :
                                    <span>닉네임</span>
                                }
                            </div>
                            <FilterIconArrow style={{transform: isDrop? 'rotate(180deg)' : 'rotate(0deg)'}} src={arrow} alt="arrow"/>
                            {
                                isDrop?
                                <DropBorder>
                                    <DropBorderItem onClick={(e)=>{e.stopPropagation(); setIsRandom('랜덤 닉네임'); setIsDrop(false); nicknameHandler({type: '랜덤 닉네임', text: ''})}}>랜덤 닉네임</DropBorderItem>
                                    <DropBorderItem onClick={(e)=>{e.stopPropagation(); setIsRandom('고정 닉네임'); setIsDrop(false); nicknameHandler({type: '고정 닉네임', text: ''})}}>고정 닉네임</DropBorderItem>
                                    <DropBorderItem onClick={(e)=>{e.stopPropagation(); setIsRandom('닉네임'); setIsDrop(false); nicknameHandler({type: '닉네임', text: ''})}}>닉네임</DropBorderItem>
                                </DropBorder>
                                :
                                <div style={{display: "none"}}/>
                            }
                        </CommentsDrop>
                        {/* 닉네인 뷰 */}
                        <CommentsNick value={commentsNick} onChange={(e)=>{nicknameHandler({type: '닉네임', text: e.target.value})}} readOnly={isRandom !== '닉네임'} placeholder="닉네임 작성" />
                    </div>
                    {/* {
                        isSecret?
                        <CommentsPwd type="password"/>
                        :
                        <div />
                    } */}
                </CommentsDropBorder>
                {/* 코멘트 인풋 */}
                <CommentsInputBorder>
                    <CommentsInput 
                    ref={textareaRef} 
                    value={comments} 
                    onChange={commentsHandler} 
                    rows="1"
                    placeholder="댓글을 입력해주세요"
                    />
                    <CommentsIconBorder>
                        <div style={{display: "flex", alignItems: "center", position: "relative"}} ref={dropRef02} onClick={dropRef02Handler}>
                            <CommentsIcon src={emoji} alt="icon04"/>
                            {
                                isCommentsDrop?
                                <EmojisBorder >
                                    {
                                        emojis.map((a, i)=>(
                                            <EmojisItems onClick={(e)=>{e.stopPropagation(); commentsEmojisHandler(a); setIsCommentsDrop(false)}} key={i}>
                                                {a}
                                            </EmojisItems>
                                        ))
                                    }
                                </EmojisBorder>
                                :
                                <div style={{display: "none"}}/>
                            }
                            
                        </div>
                            <CommentsIcon onClick={()=>{setIsSecret(!isSecret)}} src={isSecret? lockActive : lock} alt="icon05" style={{marginRight: "0.8rem"}}/>
                            <CommentsSubmitButton onClick={()=>{CommentSubmitHandler()}}>
                                등록
                            </CommentsSubmitButton>
                            
                    </CommentsIconBorder>
                </CommentsInputBorder>
            </CommentBorder>
        )
    }

    const ReReply = () => {
        let [isClickedReply, setIsClickedReply] = useState(false);
        return(
            <ReplyMainBorder>
                <ReplyBorder>
                    {/* 프로필 이미지 */}
                    <ReplyProfileImage alt="re-profile"/>
                    {/* 닉네임, 시간, 내용, 대댓글 */}
                    <ReplyContentsBorder>
                        <ReplyNickText>
                            춘식이덧버선
                            <ReplyTimeText>
                                30분전
                            </ReplyTimeText>
                        </ReplyNickText>
                        <ReplyContentsText value={'논현역 근처도 추천해주세요'} readOnly onChange={()=>{}} />
                        <ReplyShowMore>
                            댓글
                        </ReplyShowMore>
                    </ReplyContentsBorder>
                    <ReplyToolBorder>
                        <div>
                            <ReplyIcon src={commentLike} alt="icon08"/>
                            <ReplyCount>
                                124
                            </ReplyCount>
                        </div>
                        
                        <ReplyIcon src={more} alt="icon08"/>
                    </ReplyToolBorder>
                </ReplyBorder>
                {
                    isClickedReply?
                    <CommentBorder style={{marginBottom: "0.8rem"}}>
                        {/* 코멘트 */}
                        <CommentsDropBorder style={{marginBottom: "0.8rem"}}>
                            {/* 코멘트 드랍다운 */}
                            <CommentsDrop>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span>랜덤 닉네임</span>
                                </div>
                                <FilterIconArrow src={arrow} alt="arrow"/>
                            </CommentsDrop>
                            {/* 닉네인 뷰 */}
                            <CommentsNick>
                                춘식이덧버선
                            </CommentsNick>
                        </CommentsDropBorder>
                        {/* 코멘트 인풋 */}
                        <CommentsInputBorder>
                            <CommentsInput 
                            // ref={textareaRef} 
                            // value={value} 
                            // onChange={handleChange} 
                            // rows="1"
                            onChange={()=>{}}
                            placeholder="댓글을 입력해주세요"
                            />
                            <CommentsIconBorder>
                                <CommentsIcon src={emoji} alt="icon04"/>
                                <CommentsIcon src={lock} alt="icon05" style={{marginRight: "0.8rem"}}/>
                                <CommentsSubmitButton>
                                    등록
                                </CommentsSubmitButton>
                            </CommentsIconBorder>
                        </CommentsInputBorder>
                    </CommentBorder>
                    :
                    <div/>
                }
            </ReplyMainBorder>
        )
    }

    const Reply = ({data}) => {
        let [isClickedReply, setIsClickedReply] = useState(false);
        const [contentHeight, setContentHeight] = useState('auto');
        const clickedReplyHandler = () => {
            setIsClickedReply(!isClickedReply)
        }
        const replyContentsRef = useRef();

        useEffect(() => {
            if (replyContentsRef.current) {
                setContentHeight(replyContentsRef.current.scrollHeight + 10 +'px');
            }
        }, [data?.content]);

        const replyCount = () => {
            axios.put(`https://api.midtrc.com/comment/${data?.id}/like?notice_id=${notice_id}`,{},{
                headers: {
                    'access-token': midBack?.log_data?.access_token
                }
            }).then((res)=>{ isChange(); console.log(res.data)}).catch((error)=>{console.log(error)});
        }

        const formatTime = (postTime) => {
            const now = new Date();
            const postDate = new Date(postTime);
        
            const diffInSeconds = Math.floor((now - postDate) / 1000);
            const diffInMinutes = Math.floor(diffInSeconds / 60);
            const diffInHours = Math.floor(diffInMinutes / 60);
            const diffInDays = Math.floor(diffInHours / 24);
        
            if (diffInHours < 1) {
                return `${diffInMinutes}분`;
            } else if (diffInHours < 3) {
                return `${diffInHours}시간`;
            } else if (diffInDays < 1) {
                return '오늘';
            } else if (diffInDays < 2) {
                return '어제';
            } else {
                const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
                const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
                const day = postDate.getDate().toString().padStart(2, '0');
                return `${year}.${month}.${day}`;
            }
        }
    
        const TimeDisplay = ({ postTime }) => {
            const formattedTime = formatTime(postTime);
            return <div>{formattedTime}</div>;
        };

        return(
            <ReplyMainBorder>
                <ReplyBorder>
                    {/* 프로필 이미지 */}
                    <ReplyProfileImage alt="re-profile"/>
                    {/* 닉네임, 시간, 내용, 대댓글 */}
                    <ReplyContentsBorder>
                        <ReplyNickText>
                                {data?.nickname}
                            <ReplyTimeText>
                                <TimeDisplay postTime={data?.updated_at}/>
                            </ReplyTimeText>
                        </ReplyNickText>
                        <ReplyContentsText ref={replyContentsRef} value={data?.content?data?.content:''} readOnly onChange={()=>{}} style={{ height: contentHeight }}/>
                        <ReplyShowMore style={{marginBottom: "1rem"}} onClick={(e)=>{e.stopPropagation(); clickedReplyHandler()}}>
                            {
                                isClickedReply?
                                <div>닫기</div>:<div>댓글</div>
                            }
                        </ReplyShowMore>
                    </ReplyContentsBorder>
                    <ReplyToolBorder>
                        <div>
                            <ReplyIcon src={commentLike} alt="icon08" onClick={()=>{replyCount()}}/>
                            <ReplyCount>
                                {data?.like_count}
                            </ReplyCount>
                        </div>
                        <ReplyIcon src={more} alt="icon08"/>
                    </ReplyToolBorder>
                </ReplyBorder>
                {
                    isClickedReply?
                    <CommentBorder style={{marginBottom: "0.8rem"}}>
                        {/* 코멘트 */}
                        <CommentsDropBorder style={{marginBottom: "0.8rem", justifyContent: "left", gap: "0.8rem"}}>
                            {/* 코멘트 드랍다운 */}
                            <CommentsDrop>
                                <div style={{display: "flex", alignItems: "center"}}>
                                    <span>랜덤 닉네임</span>
                                </div>
                                <FilterIconArrow src={arrow} alt="arrow"/>
                            </CommentsDrop>
                            {/* 닉네인 뷰 */}
                            <CommentsNick />
                        </CommentsDropBorder>
                        {/* 코멘트 인풋 */}
                        <CommentsInputBorder>
                            <CommentsInput 
                            // ref={textareaRef} 
                            value={'asdfasdfasdf'} 
                            // rows="1"
                            onChange={()=>{}}
                            placeholder="댓글을 입력해주세요"
                            />
                            <CommentsIconBorder>
                                <CommentsIcon src={emoji} alt="icon04"/>
                                <CommentsIcon src={lock} alt="icon05" style={{marginRight: "0.8rem"}}/>
                                <CommentsSubmitButton>
                                    등록
                                </CommentsSubmitButton>
                            </CommentsIconBorder>
                        </CommentsInputBorder>
                    </CommentBorder>
                    :
                    <div/>
                }
                {/* <ReReplyListBorder style={{marginTop: "0.8rem"}}>
                    <ReReply />
                </ReReplyListBorder> */}
                
            </ReplyMainBorder>
        )
    }

    const isChange = () => {
        setChangeData(!changeData)
    }

    const likeHandler = () => {
        axios.put(`https://api.midtrc.com/notice/${data?.id}/like`,{},{
            headers: {
                'access-token': midBack?.log_data?.access_token
            }
        }).then((res)=>{isChange();}).catch((error)=>{console.log(error)});
    }

    const handleEdit = () => {
        if(data?.client_id === midBack?.log_data?.id){
            navigate(`/community-edit/${data.id}`);
        }
        
    };
    // 데이터가 없을 때는 아무것도 렌더링하지 않음
    if (!data) {
        return null; 
    }

    return (
        <MypageBackground>
            <MyNoticeBoardPostBorder>
                <MyNoticeBoardPostSection>
                    <MypageNoticeBoardPostinfo>

                        <NoticeboardpostContainer>
                            <NoticeboardpostTitle>{data?.notice_title}</NoticeboardpostTitle>
                            <MypageMyNoticeBoardPostdivider />
                            <Noticeboardpostingcontent>
                                {parse(data?.data[0]?.content)}
                            </Noticeboardpostingcontent>
                            <div>
                                {
                                    data?.image_urls?.map((a,i) => (
                                        <PhotoImage key={i} alt={`alt${i}`} src={a}/>
                                    ))
                                    
                                }
                            </div>

                        </NoticeboardpostContainer>
                    </MypageNoticeBoardPostinfo>
                </MyNoticeBoardPostSection>
            </MyNoticeBoardPostBorder>
        </MypageBackground>
    );
};

export default CommunityPostA;
