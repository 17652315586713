import React from 'react'
import './AllianceManage.css'

import AllianceSubBox from './AllianceSubBox'
import AllianceManageInfo from './AllianceManageInfo'

const AllianceManage = () => {
  return (
    <div className='AllianceManage'>
      <div className='AllianceManageCover'>
        <AllianceSubBox />
        <AllianceManageInfo />
      </div>
    </div>
  )
}

export default AllianceManage