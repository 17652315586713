import React, { useState } from 'react'
import './Alliance.css'
const Alliance = ({modalManager}) => {
  let [clickedIndex, setClickedIndex] = useState('제휴/입점 문의');
      // 공통 인덱스
      const MyPageIndexBorder = () => {
        const IndexButton = ({name, url}) => {

            return(
                <div>
                    {
                        clickedIndex === name?
                        <div className="default-index-border-button" style={{backgroundColor: "#EDF5FF"}} onClick={()=> {setClickedIndex(name)}}>
                            {name}
                        </div>
                        :
                        <div className="default-index-border-button" onClick={()=> {setClickedIndex(name)}}>
                            {name}
                        </div>
                    }
                </div>
            )
        }
        return(
            <div className="default-index-border" style={{paddingBottom: "5px"}}>
                <IndexButton name={'제휴/입점 문의'}/>
                <IndexButton name={'제휴/입점 안내사항 관리'}/>
            </div>
        )
      }

          // 스위치
    const ContentsSwitcher = () => {
      switch(clickedIndex){
          case '제휴/입점 문의':
              return(
                <div className="default-contents-border">
                  <div className="default-contents-border-top">
                    제휴/입점 문의
                  </div>
                  <DefaultContentsBorder />
                </div>
              );

          case '제휴/입점 안내사항 관리':
              return(
                <div className="default-contents-border">
                  <div className="default-contents-border-top">
                    입점 기준 안내
                  </div>
                  <DefaultContentsBorder2 />
              </div>
              )
      }
      
  }

    // 페이지 네이션
    const DefaultPagenation = () => {
      return(
        <div style={{display: "flex", height: "50px", justifyContent: "center", alignItems: "center", gap: "10px"}}>
            <div>
                
            </div>
            <div className="default-contents-pagenation-button">
                1
            </div>
            <div className="default-contents-pagenation-button">
                2
            </div>
            <div className="default-contents-pagenation-button">
                3
            </div>
            <div className="default-contents-pagenation-button">
                4
            </div>
            <div>
                
            </div>
        </div>
      )
    }

    // 제휴/입점 문의
  const AllianceList = () => {
    return(
      <div className="default-contents-border-top" 
            style={{fontSize: "14px", 
            color: "black",
            height: "50px",
            display: "grid",
            gridTemplateColumns: "0.3fr 0.5fr 2fr 2fr 1fr 1fr 1fr 1fr"
            }}>
                <input type="checkbox" className="default-contents-border-check-box"/>
                <div style={{width: "100%", textAlign: "center"}}>
                    번호
                </div>
                <div style={{width: "100%", textAlign: "center"}}>
                    달토(달리는 토끼)
                </div>
                <div style={{width: "100%", textAlign: "center"}}>
                    문의 내용이 표기됩니다
                </div>
                <div style={{width: "100%", textAlign: "center"}}>
                    park@naver.com
                </div>
                <div style={{width: "100%", textAlign: "center"}}>
                    24.1.12 09:32
                </div>
                <div style={{width: "100%", textAlign: "center"}}>
                    미확인
                </div>
                <div onClick={()=>{modalManager('제휴 입점 문의')}} style={{width: "100%", textAlign: "center", color: "blue", cursor: "pointer"}}>
                    상세보기
                </div>
      </div>
    )
  }

  const DefaultContentsBorder = () => {
    return (
      <div className="default-contents-border">
        <div className="default-contents-border-top" 
        style={{fontSize: "14px", 
        height: "50px",
        display: "grid",
        gridTemplateColumns: "0.3fr 0.5fr 2fr 2fr 1fr 1fr 1fr 1fr"
        }}>
            <input type="checkbox" className="default-contents-border-check-box"/>
            <div style={{width: "100%", textAlign: "center"}}>
                번호
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                업체명
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                문의 내용
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                담당자 연락처
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                문의 일시
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                처리상태
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                상세보기
            </div>
        </div>
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <AllianceList />
        <DefaultPagenation />
    </div>
    )
  }

  // 제휴/입점 관리 안내

  const DefaultContentsBorder2 = () => {
    return(
      <div className="default-contents-border">
        <div style={{boxSizing: "border-box", padding: "10px", height: "600px"}}>
            <textarea className="default-contents-textarea" style={{backgroundColor: "#EDF5FF", height: "100%"}} placeholder="메시지 내용을 입력하세요"/>
        </div>
        <div className="default-contents-border-confirm">
            <button className="default-contents-border-confirm-button">
                취소
            </button>
            <button className="default-contents-border-confirm-button" style={{backgroundColor: "#183B5B", color: "white"}}>
                저장
            </button>
        </div>
    </div>
    )
  }

  return (
    <div className='Alliance'>
      <div>
        <MyPageIndexBorder />
      </div>
      <ContentsSwitcher />
    </div>  
  )

}

export default Alliance