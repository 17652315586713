import React, { useState, useRef, useEffect } from 'react';
import './MemberDetail.css';
import profile from '../img/profile.png';
import closeButton from '../img/close-button.png';
import { useSelector } from 'react-redux';
import axios from 'axios';

const MemberDetail = ({ modalManager, member }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [isPasswordEditing, setIsPasswordEditing] = useState(false);

  const emailRef = useRef('');
  const nameRef = useRef('');
  const phoneNumRef = useRef('');
  const inviteNumRef = useRef('');
  const nicknameRef = useRef('');
  const birthdayRef = useRef('');
  const genderRef = useRef('');
  const businessIndustryRef = useRef('');
  const passwordRef = useRef('');

  const [notiSms, setNotiSms] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [currentMember, setCurrentMember] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [businessImage, setBusinessImage] = useState(null);

  const midBack = useSelector((state) => state.midBack);

  useEffect(() => {
    if (member) {
      emailRef.current = member.email || '';
      nameRef.current = member.name || '';
      phoneNumRef.current = member.phone_num || '';
      inviteNumRef.current = member.invite_num || '';
      nicknameRef.current = member.nickname || '';
      birthdayRef.current = member.birthday || '';
      genderRef.current = member.gender || '';
      businessIndustryRef.current = member.business_industry || '';
      passwordRef.current = '';
      setSelectedStatus(member.status || '');
      setCurrentMember(member);
      setNotiSms(member.noti_sms || false);
      setIsLoading(false);
    }
  }, [member]);

  const handleBlur = () => {
    setCurrentMember({
      ...currentMember,
      email: emailRef.current,
      name: nameRef.current,
      phone_num: phoneNumRef.current,
      invite_num: inviteNumRef.current,
      nickname: nicknameRef.current,
      birthday: birthdayRef.current,
      gender: genderRef.current,
      business_industry: businessIndustryRef.current,
      password: passwordRef.current,
      noti_sms: notiSms,
    });
  };

  const handleSave = async (updatedMember = currentMember) => {
    const client_id = member.id;
    const client_type = member.client_type;

    const request_user = {
      req_user: {
        email: updatedMember.email,
        pwd: updatedMember.password,
        name: updatedMember.name,
        nickname: updatedMember.nickname,
        phone_num: updatedMember.phone_num,
        birthday: updatedMember.birthday,
        gender: updatedMember.gender,
        invite_num: updatedMember.invite_num,
        default_profile: updatedMember.default_profile || member.default_profile,
        business_industry: updatedMember.business_industry,
        accepted_terms1: updatedMember.accepted_terms1 || member.accepted_terms1,
        accepted_terms2: updatedMember.accepted_terms2 || member.accepted_terms2,
        noti_sms: updatedMember.noti_sms,
      },
    };

    const formData = new FormData();
    formData.append('req_body', JSON.stringify(request_user));
    if (profileImage) {
      formData.append('profile_image', profileImage);
    }
    if (businessImage) {
      formData.append('business_image', businessImage);
    }

    try {
      const response = await axios.put(`https://api.midtrc.com/client/${client_id}?client_type=${client_type}`, formData, {
        headers: {
          'access-token': midBack?.log_data?.access_token,
        },
      });

      setIsEditing(false);
      setIsPasswordEditing(false);
      setCurrentMember(response.data.req_user);
    } catch (error) {
      console.error('PUT 요청 에러:', error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('정말 회원을 삭제하시겠습니까?')) {
      try {
        await axios.delete(`https://api.midtrc.com/client/${member.id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          },
        });
        alert('회원이 삭제되었습니다.');
        modalManager(null);  // 삭제 후 모달을 닫습니다.
      } catch (error) {
        console.error('DELETE 요청 에러:', error);
        alert('회원 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const handleProfileImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleBusinessImageChange = (e) => {
    setBusinessImage(e.target.files[0]);
  };

  const handlePasswordEdit = () => {
    setIsPasswordEditing(true);
  };

  const handlePasswordSave = async () => {
    try {
      // 비밀번호 변경 요청
      await axios.put('https://api.midtrc.com/auth/update-password', {
        login_id: currentMember.login_id,
        phone_num: currentMember.phone_num,
        pwd: passwordRef.current
      }, {
        headers: {
          'access-token': midBack?.log_data?.access_token,
        },
      });

      alert('비밀번호가 성공적으로 업데이트되었습니다.');
      setIsPasswordEditing(false);
      passwordRef.current = '';  // 비밀번호 필드를 비워줌
    } catch (error) {
      console.error('비밀번호 업데이트 실패:', error);
      alert('비밀번호 업데이트 중 오류가 발생했습니다.');
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'approved':
        return '활성';
      case 'disapproved':
        return '비활성';
      case 'unsubscribe':
        return '강제탈퇴';
      case 'day_paused':
        return '1일정지';
      case 'week_paused':
        return '1주정지';
      case 'month_paused':
        return '1달정지';
      default:
        return '';
    }
  };

  const getTypeLabel = (client_type) => {
    switch (client_type) {
      case 'user':
        return '일반회원';
      case 'business':
        return '사업자회원';
      default:
        return '';
    }
  };

  const handleStatusChange = async () => {
    if (window.confirm('정말 회원의 상태를 변경하시겠습니까?')) {
      const client_id = member.id;
      try {
        const response = await axios.put(`https://api.midtrc.com/client/${client_id}/status`, {
          before: currentMember.status,
          after: selectedStatus
        }, {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          },
        });
        setCurrentMember({ ...currentMember, status: selectedStatus });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleForceUnsubscribe = async () => {
    if (window.confirm('정말 회원을 강제탈퇴시키겠습니까?')) {
      const client_id = member.id;
      try {
        const response = await axios.put(`https://api.midtrc.com/client/${client_id}/status`, {
          before: currentMember.status,
          after: 'unsubscribe'
        }, {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          },
        });
        console.log(response.data);
        setCurrentMember({ ...currentMember, status: 'unsubscribe' });
      } catch (error) {
        console.error(error);
      }
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const MembersContentsBorder = () => (
    <div className="default-contents-border" style={{ marginBottom: "20px" }}>
      <div className="default-contents-border-top">
        회원정보
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "20px", marginBottom: "20px" }}>
        <div style={{ borderRadius: "60px", backgroundColor: "#f5f5f5", width: "100px", height: "100px" }}>
          {member.profile_url && <img src={member.profile_url} alt="profile" style={{ width: '100%', height: '100%', borderRadius: '60px' }} />}
        </div>
        <div style={{ fontFamily: "'Noto Sans KR', sans-serif", fontSize: "14px", fontWeight: "600", color: "#a9a9a9", marginTop: "10px" }}>
          {member.login_id}
        </div>
      </div>
      <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
        <div>
          <div className="default-contents-border-title">회원상태</div>
          <div className="default-contents-border-title">가입일시</div>
          <div className="default-contents-border-title">최종 로그인</div>
          <div className="default-contents-border-title">가입유형</div>
          <div className="default-contents-border-title">회원 이메일</div>
          <div className="default-contents-border-title">이름</div>
          <div className="default-contents-border-title">연락처</div>
          <div className="default-contents-border-title">초대번호</div>
          <div className="default-contents-border-title">닉네임</div>
          <div className="default-contents-border-title">생년월일</div>
          <div className="default-contents-border-title">성별</div>
          <div className="default-contents-border-title">비밀번호</div>
        </div>
        <div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
            <input readOnly={true} className="default-input-tag" value={getStatusLabel(currentMember?.status || 'unknown')} name="status" />
            <div className='default-contents-border-title' style={{ justifyContent: 'left' }}>정지 일 수</div>
            <select className="default-contents-border-select" style={{ width: "100px", marginLeft: "-30px", textAlign: "center" }} value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)} name="status">
              <option value="approved">활성</option>
              <option value="day_paused">1일 정지</option>
              <option value="week_paused">일주일 정지</option>
              <option value="month_paused">한달 정지</option>
            </select>
            <div className='default-contents-border-confirm' style={{ padding: "0" }}>
              <button className='default-contents-border-confirm-button' style={{ width: "80px", height: "40px", background: "#183b5b", color: "#fff" }} onClick={handleStatusChange}>변경</button>
              <button className='default-contents-border-confirm-button' style={{ width: "80px", height: "40px", background: "#ff0000", color: "#fff" }} onClick={handleForceUnsubscribe}>강제 탈퇴</button>
            </div>
          </div>
          <input readOnly={true} className="default-input-tag" value={new Date(member.created_at).toLocaleString()} />
          <input readOnly={true} className="default-input-tag" value={new Date(member.latest_login_at).toLocaleString()} />
          <input readOnly={true} className="default-input-line-tag" value={getTypeLabel(member.client_type)} name="client_type" />
          <input
            type="text"
            readOnly={!isEditing}
            className="default-input-line-tag"
            defaultValue={emailRef.current}
            onChange={(e) => (emailRef.current = e.target.value)}
            onBlur={handleBlur}
            name="email"
          />
          <input
            readOnly={!isEditing}
            className="default-input-line-tag"
            defaultValue={nameRef.current}
            onChange={(e) => (nameRef.current = e.target.value)}
            onBlur={handleBlur}
            name="name"
          />
          <input
            readOnly={!isEditing}
            className="default-input-line-tag"
            defaultValue={phoneNumRef.current}
            onChange={(e) => (phoneNumRef.current = e.target.value)}
            onBlur={handleBlur}
            name="phone_num"
          />
          <input
            readOnly={!isEditing}
            className="default-input-line-tag"
            defaultValue={inviteNumRef.current}
            onChange={(e) => (inviteNumRef.current = e.target.value)}
            onBlur={handleBlur}
            name="invite_num"
          />
          <input
            readOnly={!isEditing}
            className="default-input-line-tag"
            defaultValue={nicknameRef.current}
            onChange={(e) => (nicknameRef.current = e.target.value)}
            onBlur={handleBlur}
            name="nickname"
          />
          <input
            readOnly={!isEditing}
            className="default-input-line-tag"
            defaultValue={birthdayRef.current}
            onChange={(e) => (birthdayRef.current = e.target.value)}
            onBlur={handleBlur}
            name="birthday"
          />
          <input
            readOnly={!isEditing}
            className="default-input-line-tag"
            defaultValue={genderRef.current}
            onChange={(e) => (genderRef.current = e.target.value)}
            onBlur={handleBlur}
            name="gender"
          />
          <div style={{ position: "relative" }}>
            <input
              readOnly={!isPasswordEditing}
              type="password"
              className="default-input-line-tag"
              defaultValue={passwordRef.current}
              onChange={(e) => (passwordRef.current = e.target.value)}
              onBlur={handleBlur}
              name="password"
            />
            <div className="default-input-tag-edit">
              <button className='default-index-border-button' style={{ background: "#ff0000", fontSize: "10px", height: "30px", color: "#fff", marginBottom: "0" }} onClick={isPasswordEditing ? handlePasswordSave : handlePasswordEdit}>
                {isPasswordEditing ? '저장' : '임시 비밀번호 발급'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="default-contents-border-confirm">
        <button className="default-contents-border-confirm-button" onClick={handleDelete}>
          삭제
        </button>
        {isEditing ? (
          <button className="default-contents-border-confirm-button" onClick={() => handleSave(currentMember)}>
            저장
          </button>
        ) : (
          <button className="default-contents-border-confirm-button" onClick={() => setIsEditing(true)}>
            편집
          </button>
        )}
      </div>
    </div>
  );

  return (
    <div className="default-modal-border" style={{ height: "100vh" }} onClick={(e) => { e.stopPropagation(); modalManager(null) }}>
      <div className="members-detail-modal-border" onClick={(e) => { e.stopPropagation() }}>
        <div className="default-contents-border-top" style={{ width: "100%", justifyContent: "space-between", minHeight: "40px", backgroundColor: "white", borderRadius: "4px 4px 0px 0px" }}>
          <div>{member.client_type === 'user' ? '일반 회원 정보' : '사업자 회원 정보'}</div>
          <img
            onClick={() => { modalManager({ type: null }) }}
            src={closeButton} alt="close-box" style={{ width: "14px", height: "14px", cursor: "pointer" }}
          />
        </div>
        <div className="members-detail-modal-contents-border">
          <div style={{ boxSizing: "border-box", padding: "20px", width: "100%", height: "100%", backgroundColor: "#ECF0F4", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
            <div>
              <MembersContentsBorder />
            </div>
            <div>
              <MoreInfoContentsBorder 
                member={currentMember} 
                currentMember={currentMember} 
                handleSave={handleSave} 
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MoreInfoContentsBorder = ({ member, currentMember, handleSave }) => {
  const [isSmsEditing, setIsSmsEditing] = useState(false);
  const [notiSms, setNotiSms] = useState(member?.noti_sms || false);

  const handleSmsEdit = () => {
    setIsSmsEditing(true);
  };

  const handleSmsSave = async () => {
    const updatedMember = {
      ...currentMember,
      noti_sms: notiSms,
    };

    try {
      await handleSave(updatedMember);
      setIsSmsEditing(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="default-contents-border" style={{ marginBottom: "20px" }}>
      <div className="default-contents-border-top">추가정보</div>
      <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
        <div>
          <div className="default-contents-border-title">SMS 수신동의</div>
        </div>
        <div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
            <div className="default-contents-border-check-box-border">
              <input
                className="default-contents-border-check-box"
                type="checkbox"
                checked={notiSms === true}
                readOnly={!isSmsEditing}
                onChange={() => setNotiSms(true)}
              />
              <div style={{ color: "#a9a9a9", marginLeft: "10px", fontWeight: "600" }}>동의</div>
            </div>
            <div className="default-contents-border-check-box-border">
              <input
                className="default-contents-border-check-box"
                type="checkbox"
                checked={notiSms === false}
                readOnly={!isSmsEditing}
                onChange={() => setNotiSms(false)}
              />
              <div style={{ color: "#a9a9a9", marginLeft: "10px", fontWeight: "600" }}>비동의</div>
            </div>
          </div>
        </div>
      </div>
      <div className="default-contents-border-confirm">
        <button className="default-contents-border-confirm-button" onClick={isSmsEditing ? handleSmsSave : handleSmsEdit}>
          {isSmsEditing ? '저장' : '편집'}
        </button>
      </div>
    </div>
  );
};

export default MemberDetail;
