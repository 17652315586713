import React from 'react'
import SubBox1 from '../Ceo/SubBox1'
import MManagement from './MManagement'

const Management = () => {
  return (
    <>
      <SubBox1 />
      <MManagement />
    </>
  )
}

export default Management