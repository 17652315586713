import React, { useEffect, useState } from 'react';
import './MemberCheck.css';
import axios from 'axios';
import { useSelector } from 'react-redux';
import MemberDetail from './MemberDetail'; // MemberDetail 컴포넌트를 임포트합니다.

const MemberCheck = ({ modalManager }) => {
  const midBack = useSelector((state) => state.midBack);
  const [memberList, setMemberList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSet, setCurrentSet] = useState(1);
  const [selectedType, setSelectedType] = useState('전체보기');
  const itemsPerPage = 10;
  const [isFemaleOnly, setIsFemaleOnly] = useState(false);
  const [isSmsAgreedOnly, setIsSmsAgreedOnly] = useState(false);
  const [isPausedOnly, setIsPausedOnly] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [refresh, setRefresh] = useState(false); // 새로고침 상태 추가
  const [searchQuery, setSearchQuery] = useState(''); // 검색어 상태 추가
  const [filteredMemberList, setFilteredMemberList] = useState([]); // 필터링된 리스트 상태 추가

  const handleFemaleCheck = (e) => {
    setIsFemaleOnly(e.target.checked);
  };
  
  const handleSmsCheck = (e) => {
    setIsSmsAgreedOnly(e.target.checked);
  };
  
  const handlePausedCheck = (e) => {
    setIsPausedOnly(e.target.checked);
  };

  const handleAllSelect = () => {
    setMemberList((prevList) => {
      const newSelectedState = !isAllSelected;
      return prevList.map((member) => ({
        ...member,
        isSelected: newSelectedState,
      }));
    });
    setIsAllSelected(!isAllSelected);
  };

  const handleMemberSelect = (id) => {
    setMemberList((prevList) => 
      prevList.map((member) =>
        member.id === id ? { ...member, isSelected: !member.isSelected } : member
      )
    );
  };

  const handleDeleteSelected = async () => {
    const selectedIds = memberList.filter(member => member.isSelected).map(member => member.id);
    if (selectedIds.length === 0) {
      alert('삭제할 회원을 선택하세요.');
      return;
    }

    if (!window.confirm('정말 회원을 삭제하시겠습니까?')) {
      return;
    }

    try {
      await Promise.all(
        selectedIds.map(id => 
          axios.delete(`https://api.midtrc.com/client/${id}`, {
            headers: {
              'access-token': midBack?.log_data?.access_token
            }
          })
        )
      );

      setMemberList(prevList => prevList.filter(member => !selectedIds.includes(member.id)));
      setIsAllSelected(false);
    } catch (error) {
      console.error('Error deleting members:', error);
      alert('회원 삭제 중 오류가 발생했습니다.');
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.midtrc.com/client', {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });
      const instData = response.data.map(member => ({ ...member, isSelected: false }));
      const filterData = instData.filter(obj => obj.client_type !== 'admin' && obj.client_type !== 'manager');
      const sortedData = filterData.sort((a, b) => b.id - a.id); // id 값에 따라 내림차순으로 정렬
      setMemberList(sortedData);
      setFilteredMemberList(sortedData); // 초기 상태에서 전체 리스트를 필터링된 리스트로 설정
      
    } catch (error) {
      console.error('Error fetching the member data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [midBack]);

  useEffect(() => {
    setIsAllSelected(false);
  }, [selectedType, isFemaleOnly, isSmsAgreedOnly, isPausedOnly]);

  useEffect(() => {
    if (refresh) {
      fetchData();
      setRefresh(false);
    }
  }, [refresh]);


  useEffect(() => {
    let filtered = memberList;
  
    if (selectedType !== '전체보기') {
      filtered = filtered.filter(member => 
        selectedType === '일반' ? member.client_type === 'user' : member.client_type === 'business'
      );
    }
  
    if (isFemaleOnly) {
      filtered = filtered.filter(member => member.gender === 'female');
    }
  
    if (isSmsAgreedOnly) {
      filtered = filtered.filter(member => member.is_sms_agreed);
    }
  
    if (isPausedOnly) {
      filtered = filtered.filter(member => member.is_paused);
    }
  
    setFilteredMemberList(filtered);
  }, [selectedType, isFemaleOnly, isSmsAgreedOnly, isPausedOnly, memberList]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setCurrentPage(1); // 필터링 시 페이지를 첫 페이지로 초기화
  };

  const handleMemberClick = async (member) => {
    try {
      const response = await axios.get(`https://api.midtrc.com/client/${member.id}`, {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });
      modalManager({ type: '회원정보', data: response.data });
    } catch (error) {
      console.error('Error fetching member details:', error);
    }
  };

  const handleSearch = () => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = memberList.filter(member => 
      member.phone_num.includes(searchQuery) || member.login_id.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredMemberList(filtered);
    setCurrentPage(1); // 검색 시 페이지를 첫 페이지로 초기화
  };

  const DefaultPagenation = () => {
    const totalItems = filteredMemberList.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pagesPerSet = 5;
    const startPage = (currentSet - 1) * pagesPerSet + 1;
    const endPage = Math.min(currentSet * pagesPerSet, totalPages);

    const handleNextSet = () => {
        if (endPage < totalPages) {
            setCurrentSet(prevSet => prevSet + 1);
        }
    };

    const handlePrevSet = () => {
        if (startPage > 1) {
            setCurrentSet(prevSet => prevSet - 1);
        }
    };

    return (
      <div style={{ display: "flex", height: "50px", justifyContent: "center", alignItems: "center", gap: "10px" }}>
        {startPage > 1 && <div onClick={handlePrevSet} className="default-contents-pagenation-button">{"<"}</div>}
        {Array.from({ length: (endPage - startPage + 1) }, (_, i) => startPage + i).map(page => (
          <div
            key={page}
            onClick={() => handlePageChange(page)}
            className="default-contents-pagenation-button"
            style={{ color: page === currentPage ? 'black' : 'grey' }}
          >
            {page}
          </div>
        ))}
        {endPage < totalPages && <div onClick={handleNextSet} className="default-contents-pagenation-button">{">"}</div>}
      </div>
    );
  };

  const MembersList = ({ member, num }) => {
    return (
      <div className="default-contents-border-top"
        style={{
          fontSize: "14px",
          color: "black",
          height: "50px",
          display: "grid",
          gridTemplateColumns: "0.3fr 0.5fr 2fr 1fr 1fr 1.8fr 1.3fr 1.3fr 0.8fr"
        }}>
        <input 
          type="checkbox" 
          className="default-contents-border-check-box" 
          checked={member.isSelected} 
          onChange={() => handleMemberSelect(member.id)} 
        />
        <div style={{ width: "100%", textAlign: "center" }}>
          {member.id} {/* id 값으로 번호를 대체 */}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          {member.login_id}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          {member.name}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          {member.client_type === 'user' ? '일반' : '사업자'}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          {member.phone_num}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          {new Date(member.created_at).toLocaleDateString()}
        </div>
        <div style={{ width: "100%", textAlign: "center" }}>
          {new Date(member.latest_login_at).toLocaleDateString()}
        </div>
        <div onClick={() => handleMemberClick(member)} style={{ width: "100%", textAlign: "center", color: "blue", cursor: "pointer" }}>
          상세보기
        </div>
      </div>
    );
  };

  const DefaultContentsBorder = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const currentItems = filteredMemberList.slice(startIndex, startIndex + itemsPerPage);
  
    return (
      <div className="default-contents-border">
        <div className="default-contents-border-top"
          style={{
            fontSize: "14px",
            height: "50px",
            display: "grid",
            gridTemplateColumns: "0.3fr 0.5fr 2fr 1fr 1fr 1.8fr 1.3fr 1.3fr 0.8fr"
          }}>
          <input 
            type="checkbox" 
            className="default-contents-border-check-box" 
            onChange={handleAllSelect} 
            checked={isAllSelected} 
          />
          <div style={{ width: "100%", textAlign: "center" }}>
            번호
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            회원 아이디
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            이름
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            회원유형
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            전화번호
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            가입일자
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            최종 로그인
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            상세보기
          </div>
        </div>
        {currentItems.map((member, i) => (
          <MembersList key={member.id} num={i} member={member} />
        ))}
        <DefaultPagenation />
      </div>
    );
  };

  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr", gap: "20px", marginBottom: "20px" }}>
        <div className="default-contents-border" style={{ height: "50px", position: "relative" }}>
          <input 
            placeholder="검색어를 입력하세요" 
            style={{ background: "none", outline: "none", border: "none", width: "100%", height: "100%", fontWeight: "400", fontFamily: "'Noto Sans KR', sans-serif", fontSize: "14px", boxSizing: "border-box", paddingLeft: "10px" }} 
            value={searchQuery} // 입력 값 관리
            onChange={(e) => setSearchQuery(e.target.value)} // 입력 시 상태 업데이트
          />
          <button 
            className="members-contents-button" 
            style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)" }}
            onClick={handleSearch} // 검색 버튼 클릭 시 필터링 함수 호출
          >
            검색
          </button>
        </div>
      </div>
      <div className="default-contents-border" style={{ height: "50px", marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <button className="members-contents-button" onClick={handleAllSelect}>
            전체선택
          </button>

          <div style={{ fontSize: "14px", fontWeight: "600", color: "#a9a9a9", marginLeft: "20px" }}>
            {filteredMemberList.length}명
          </div>
          <select className="default-contents-border-select" style={{ width: "100px", marginLeft: "20px", textAlign: "center" }} onChange={handleTypeChange}>
            <option>전체보기</option>
            <option>일반</option>
            <option>사업자</option>
          </select>
          <div className="default-contents-border-check-box-border" style={{ marginLeft: "20px" }}>
            <div style={{ marginRight: "10px" }}>여성 회원</div>
            <input type="checkbox" className="default-contents-border-check-box" onChange={handleFemaleCheck} />
          </div>
          <div className="default-contents-border-check-box-border" style={{ marginRight: "20px", marginLeft: "20px" }}>
            <div style={{ marginRight: "10px" }}>SMS 수신동의</div>
            <input type="checkbox" className="default-contents-border-check-box" onChange={handleSmsCheck} />
          </div>
          <div className="default-contents-border-check-box-border" style={{ marginLeft: "20px" }}>
            <div style={{ marginRight: "10px" }}>이용정지 회원</div>
            <input type="checkbox" className="default-contents-border-check-box" onChange={handlePausedCheck} />
          </div>
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <button className="members-contents-button" onClick={handleDeleteSelected} style={{ backgroundColor: "red", color: "white", marginLeft: "10px" }}>
            삭제
          </button>
        
        </div>
      </div>
      <DefaultContentsBorder />
    </div>
  );
};

export default MemberCheck;
