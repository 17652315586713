import React from 'react'
import './BoardManageUpdate.css'
import visible_0 from '../img/choice-talk/visible_0.png'
import plus2 from '../img/plus2.svg'
import imgex2 from '../img/imgex2.png'
import CommunityEditPage from './ManagerboardPost/community-edit-a';
import { useNavigate, useParams } from 'react-router-dom';


const BoardManageUpdate = () => {
  let navigate = useNavigate();

  const DefaultContentsBorder = () => {
    return(
      <div className='BoardManageUpdateCoverArea3'>
        <CommunityEditPage />
     
        <div className='default-contents-border-confirm'>
          <button className='default-contents-border-confirm-button' onClick={() => {navigate(`/board-management`)}}>취 소</button>
        </div>
      </div>

    )
}

return(
  <div>
    <DefaultContentsBorder />
  </div>
)

}

export default BoardManageUpdate