import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { PureComponent } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';



const ChartApi = ({date}) =>  {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const daysAgo = 7;
    const today = dayjs();
    
    if(!date){
      return;
    }
    // 마지막 7일간의 날짜 생성
    const last7Days = Array.from({ length: daysAgo }, (_, i) => {
      return today.subtract(i, 'day').format('MM-DD');
    }).reverse();  // 날짜 순서를 정렬하여 과거 -> 오늘 순으로 정렬
    
    // 데이터 매핑
    const data = last7Days.map(day => {
      const visitData = date?.find(d => dayjs(d.visit_date).format('MM-DD') === day);
      return {
        name: day,
        "방문자 수": visitData ? visitData.count : 0,
      };
    });

    setChartData(data);
  }, [date]);
  
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={500} height={100} data={chartData}>
        <CartesianGrid strokeDasharray="0 0"/>
        <XAxis dataKey="name" padding={{ left: 10, right: 30 }} />
        <YAxis  type="number" domain={[0,70]} tickCount={8} />
        <Tooltip />
        {/* <Legend /> */}
        <Line type="line" dataKey="방문자 수" stroke="#fc5f64" activeDot={{ r: 8 }} />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default ChartApi