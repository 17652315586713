import React, { useState, useRef, useCallback } from 'react';
import '../Community/Community.css';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const KeywordTag = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 2px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
  background-color: #f0f0f0;
  box-sizing: border-box;
`;

const KeywordLimit = styled.div`
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #888;
`;

const JobandKeyWords = () => {
  const [clickedIndex, setClickedIndex] = useState('테라스 라운지');
  const [keywords, setKeywords] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleAddKeyword = useCallback(() => {
    if (inputValue.trim() !== '') {
      setKeywords(prevKeywords => [...prevKeywords, inputValue.trim()]);
      setInputValue('');
    }
  }, [inputValue]);

  const handleRemoveKeyword = useCallback((index) => {
    setKeywords(prevKeywords => prevKeywords.filter((_, i) => i !== index));
  }, []);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length <= 4) {
      setInputValue(newValue);
    }
    console.log(e.target.value)
  }

  const MyPageIndexBorder = () => {
    const IndexButton = ({ name, url }) => (
      <div
        className="default-index-border-button"
        style={clickedIndex === name ? { backgroundColor: '#EDF5FF' } : {}}
        onClick={() => {
          setClickedIndex(name);
          navigate(url);
        }}
      >
        {name}
      </div>
    );

    return (
      <div className="default-index-border" style={{ paddingBottom: '5px' }}>
        <IndexButton name={'면책조항'} />
        <IndexButton name={'이력서 키워드 설정'} />
      </div>
    );
  };
 

  const DisclaimSetting = () => (
    <div className="default-contents-border">
      <div className="default-contents-border-top">면책 조항</div>
      <div
        style={{
          boxSizing: 'border-box',
          padding: '10px 20px 10px 10px',
          display: 'grid',
          gridTemplateColumns: '2fr 8fr',
          gap: '20px',
        }}
      >
        <div>
          <div className="default-contents-border-title" style={{ marginBottom: '10px' }}>
            면책조항 내용
          </div>
        </div>
        <div>
          <textarea
            className="default-contents-textarea"
            style={{ height: '400px', width: '100%' }}
          ></textarea>
        </div>
      </div>
      <div className="default-contents-border-confirm">
        <button className="default-contents-border-confirm-button">취 소</button>
        <button
          className="default-contents-border-confirm-button"
          style={{ background: '#183b5b', color: '#fff' }}
        >
          저 장
        </button>
      </div>
    </div>
  );

  const ResumeKeywords =() => (
    <div className="default-contents-border">
      <div className="default-contents-border-top">이력서 키워드 설정</div>
      <div
        style={{
          boxSizing: 'border-box',
          padding: '10px 20px 10px 10px',
          display: 'grid',
          gridTemplateColumns: '2fr 8fr',
          gap: '20px',
        }}
      >
        <div>
          <div className="default-contents-border-title" style={{ marginBottom: '10px' }}>
            등록된 키워드
          </div>
        </div>
        <div>
          <div style={{ position: 'relative', marginTop: '10px', marginBottom: '10px' }}>
            <div>
              {keywords.map((keyword, index) => (
                <KeywordTag key={index}>
                  {keyword}
                  <span
                    style={{ marginLeft: '5px', cursor: 'pointer' }}
                    onClick={() => handleRemoveKeyword(index)}
                  >
                    X
                  </span>
                </KeywordTag>
              ))}
            </div>
          </div>
          <div style={{ position: 'relative', marginTop: '50px', marginBottom: '10px' }}>
            <input
              // ref={inputRef}
              className="default-input-line-tag"
              placeholder="키워드"
              value={inputValue}
              onChange={handleInputChange}
            />
            <div className="default-input-tag-edit" onClick={()=>{handleAddKeyword()}} > {/* onClick={handleAddKeyword} */}
              등록
            </div>
          </div>
          <KeywordLimit>{inputValue.length}/4</KeywordLimit>
        </div>
      </div>
      <div className="default-contents-border-confirm">
        <button className="default-contents-border-confirm-button">취 소</button>
        <button
          className="default-contents-border-confirm-button"
          style={{ background: '#183b5b', color: '#fff' }}
        >
          저 장
        </button>
      </div>
    </div>
  );

  const ContentSwitcher = () => {
    switch (clickedIndex) {
      case '면책조항':
        return <DisclaimSetting />;
      case '이력서 키워드 설정':
        return <ResumeKeywords />;
      default:
        return <DisclaimSetting />;
    }
  };

  

  return (
    <div className="Community">
      <div>
        <MyPageIndexBorder />
      </div>
      {
        clickedIndex === '면책조항'?
        <DisclaimSetting />
        :
        <div>
          {ResumeKeywords()}
        </div>

      }
    </div>
  );
};

export default JobandKeyWords;
