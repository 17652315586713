import React, { useState } from 'react'
import './Membership.css'

const Membership = () => {
  let [clickedIndex, setClickedIndex] = useState('사업자 회원');

  // 공통 인덱스
  const MyPageIndexBorder = () => {
    const IndexButton = ({name, url}) => {
        return(
          <div>
            {
              clickedIndex === name?
              <div className="default-index-border-button" style={{backgroundColor: "#EDF5FF"}} onClick={()=> {setClickedIndex(name)}}>
                  {name}
              </div>
              :
              <div className="default-index-border-button" onClick={()=> {setClickedIndex(name)}}>
                  {name}
              </div>
            }
          </div>
        )
    }
    return(
        <div className="default-index-border" style={{paddingBottom: "5px"}}>
            <IndexButton name={'사업자 회원'}/>
            <IndexButton name={'초이스톡 회원'}/>
            <IndexButton name={'일반 회원'}/>
            <IndexButton name={'여성 회원'}/>
            <IndexButton name={'추가하기'}/>
        </div>
    )
  }

  const GroupInfo = () => {
    return (
      <div className='default-contents-border'>
        <div className='default-contents-border-top'>회원 그룹 정보</div>
        <div style={{boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px"}}>
          <div>
            <div className="default-contents-border-title">
              회원 수
            </div>
            <div className="default-contents-border-title">
              그룹명
            </div>
            <div className="default-contents-border-title">
              그룹 유형
            </div>
            <div className="default-contents-border-title" style={{height:"160px",alignItems : "stretch", paddingTop:"10px"}}>
              이용 가능 게시판
            </div>
            <div className="default-contents-border-title">
              뱃지 태그
            </div>
          </div>
          <div>
            <div style={{position: "relative"}}>
              <input readOnly={true} className="default-input-line-tag" value={'40명'}/>
              <div className="default-input-tag-edit">
                  모아보기
              </div>
            </div>
            <div style={{position: "relative"}}>
              <input readOnly={true} className="default-input-line-tag" value={'사업자 회원'}/>
              <div className="default-input-tag-edit">
                  수정
              </div>
            </div>
            <div style={{position: "relative", display:"grid", gridTemplateColumns:"1fr 1fr 1fr"}}>
              <div className="default-contents-border-check-box-border">
                  <div style={{marginRight: "10px"}}>
                      사업자 유형
                  </div>
                  <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                  <div style={{marginRight: "10px"}}>
                      개인 가입 유형
                  </div>
                  <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-input-tag-edit">
                  수정
              </div>
            </div>
            <div style={{display:"grid", gridTemplateColumns: "1fr 1fr 1fr", columnGap : "20px"}}>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    뷰티테라스
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    뷰티라운지
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    카테고리1
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    카테고리2
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    카테고리3
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    카테고리4
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    카테고리2
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    카테고리3
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    카테고리4
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    카테고리2
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    카테고리3
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
              <div className="default-contents-border-check-box-border">
                <div style={{marginRight: "10px"}}>
                    카테고리4
                </div>
                <input type="checkbox" className="default-contents-border-check-box"/>
              </div>
            </div>
            <div style={{position: "relative"}}>
              <input readOnly={true} className="default-input-line-tag" value={'이미지 미첨부 시 뱃지 색상 코드'}/>
              <div className="default-input-tag-edit">
                  수정
              </div>
            </div>
            <div style={{position: "relative"}}>
              <input readOnly={true} className="default-input-line-tag" value={'이미지 미첨부 시 뱃지 텍스트'}/>
              <div className="default-input-tag-edit">
                  수정
              </div>
            </div>
            <div style={{position: "relative"}}>
              <input readOnly={true} className="default-input-line-tag" value={'이미지 경로://'}/>
              <div className="default-input-tag-edit">
                  <button className='membership-btn'>이미지 첨부</button>
              </div>
            </div>
          </div>
        </div>
        <div className='default-contents-border-confirm'>
          <button className='default-contents-border-confirm-button'>취 소</button>
          <button className='default-contents-border-confirm-button' style={{background:"#183b5b", color : "#fff"}}>저 장</button>
        </div>
      </div>
    )
  }

  const ContentSwitcher = () => {
    switch(clickedIndex) {
      case "사업자 회원" :
        return(
          <GroupInfo />
        )
      default : 
        return (
          <div></div>
        )
    }
  }






  
  return (
    <div className='Membership'>
      <div>
        <MyPageIndexBorder />
      </div>
      <ContentSwitcher />
    </div>
  )
}

export default Membership