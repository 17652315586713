/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import more from "./../../@images/more-24.svg";
import like from "./../../@images/sns-like-black-40.svg";
import likeActive from "./../../@images/like-active.svg";
import comment from "./../../@images/sns-comment-black-40.svg";
import link from "./../../@images/sns-link-black-40.svg";
import bookMark from "./../../@images/sns-bookmark-black-40.svg";
import bookMarkActive from "./../../@images/sns-bookmark-active.svg";
import arrow from "./../../@images/arrow-line-24.svg";
import emoji from "./../../@images/chat-emoji.svg";
import lock from "./../../@images/lock-40.svg";
import lockActive from "./../../@images/lock-active-24.svg";
import commentLike from "./../../@images/comment_like_24.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import parse from 'html-react-parser';
import { useNavigate, useParams } from "react-router-dom";
import profileIcon1 from "./../../@images/profile_icon1.svg";
import profileIcon2 from "./../../@images/profile_icon2.svg";
import profileIcon3 from "./../../@images/profile_icon3.svg";
import profileIcon4 from "./../../@images/profile_icon4.svg";
import profileIcon5 from "./../../@images/profile_icon5.svg";
import ReplyBox from "./reply-box";

let ContentsBoarder = styled.div`
    width: 60rem;
    box-sizing: border-box;
    padding: 3.2rem 3.2rem 1.6rem 3.2rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #d2d2d2;
    border-radius: 16px;
    margin-bottom: 1.6rem;
    position:relative;
    left:50%;
    transform: translateX(-50%);
`

let TopProfileBorder = styled.div`
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: left;
    align-itme: center;
    position: relative;
`

let TopProfileImageBorder = styled.div`
    border-radius: 3rem;
    width: 6rem;
    height: 6rem;
    overflow: hidden;
    margin-right: 1.6rem;
    position: relative;
`

let TopProfileImage = styled.img`
    min-width: 100%;
    min-height: 100%;
    background-color: #f5f5f5;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

let TopNickBorder = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.4rem;
`

let TopNickText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.2rem;
    color: #171717;
`

let TopTimeText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    color: #9e9e9e;
`

let TopDotHamburgerButton = styled.div`
    widht: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    position: absolute;
    top: 0rem;
    right: 0rem;
    cursor: pointer;
`

let PhotoContentsBorder = styled.div`
    width: 53.5rem;
    height: 53.5rem;
    border-radius: 0.4rem;
    overflow: hidden;
    position: relative;
`

let PhotoImage = styled.img`
    min-width: 100%;
    object-fit: contain;
    background-color: #f5f5f5;
    cursor: pointer;
    display: ${props=>props.isSelected? 'flex':'none'};
`

let PhotoLeftProgressBorder = styled.div`
    position: absolute;
    display: flex;
    column-gap: 0.8rem;
    bottom: 1rem;
    left: 50%;
    height: 0.8rem;
    width: auto;
    transform: translateX(-50%);
    z-index: 10;
`

let PhotoLeftProgressButton = styled.svg`
    width: 0.8rem;
    height: 0.8rem;
    opacity: ${props => props.isSelected? 1: 0.6};
    border-radius: 1rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #D2D2D2;
    background-color: white;
    cursor: pointer;
`

let StatusBorder = styled.div`
    width: 100%;
    height: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

let SnsIconBorder = styled.div`
    display: flex;
    gap: 0.4rem;
    align-items: center;
`

let SnsIcon = styled.img`
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    cursor: pointer;
`

let SnsIconText = styled.div`
    height: auto;
    width: 3rem;
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size: 1.4rem;
    color: #171717;
    text-align: left;
`

let SnsTextBorder = styled.div`
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding-bottom: 1.6rem;
    border-color: #d2d2d2;
    border-style: solid;
    border-width: 0 0 0.1rem 0;
`

let SnsTitleText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 600;
    font-size:1.4rem;
    color: #171717;
`

let SnsContentsText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.3rem;
    color: #171717;
    line-height: 1.5;
    word-wrap: break-word; /* 긴 단어를 줄바꿈합니다. */
    overflow-wrap: break-word; /* 긴 단어를 줄바꿈합니다. */
    white-space: normal; /* 기본 줄바꿈을 허용합니다. */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`

let SnsContentsMore = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9E9E9E;
    // margin-left: 0.4rem;
    cursor: pointer;
    white-space: nowrap; /* 줄바꿈 방지 */
`
// 코멘트
let CommentBorder = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: auto;
    border-radius: 0.8rem;
    border: none;
    background-color: #F7F7F7;
    padding: 1.6rem 2.4rem;
`

let CommentsDropBorder = styled.div`
    display: flex;
    justify-content: space-between;
`

let CommentsDrop = styled.div`
    cursor: pointer;
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: white;
    position: relative;
`

let CommentsNick = styled.input`
    width: 14rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: #E8E8E8;
    outline: none;
`

let CommentsPwd = styled.input`
    width: 18rem;
    height: 4rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 1.6rem;
    box-sizing: border-box;
    background-color: white;
    outline: none;
    dispay: flex;
`

let FilterIconArrow = styled.img`
    width: 3.2rem;
    height: 3.2rem;
    object-fit: cover;
`

let CommentsInputBorder= styled.div`
    width: 100%;
    min-height: 5.6rem;
    border-style: solid;
    border-width: 0.1rem;
    border-color: #E8E8E8;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0 1.6rem;
    box-sizing: border-box;
    background-color: white;
    position: relative;
`

let CommentsIconBorder = styled.div`
    display: flex;
    align-items: center;
    position: absolute;
    right: 1.6rem;
    top: 0.8rem;
`

let CommentsIcon = styled.img`
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    cursor: pointer;
`

let CommentsSubmitButton = styled.button`
    height: 3.6rem;
    background-color: #CD1B1B;
    box-sizing: border-box;
    padding: 0.8rem 1.2rem;
    border: none;
    border-radius: 0.4rem;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: white;
    cursor: pointer;
`

let CommentsInput = styled.textarea`
    box-sizing: border-box;
    width: 42.9rem;
    min-height: 5.6rem;
    resize: none;
    border: none;
    outline: none;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    box-sizing: border-box;
    overflow: hidden;
    padding: 1.6rem 0;
    line-height: 1.5;
`

// 댓글
let ReplyMainBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
`

let ReplyBorder = styled.div`
    width: 100%;
    min-height: 11.2rem;
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 0.8rem;
    box-sizing: border-box;
`

let ReplyProfileImage = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 1.2rem;
    background-color: black;
`

let ReplyContentsBorder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-contetn: right;
    box-sizing: border-box;
`

let ReplyNickText = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 500;
    font-size: 1.6rem;
    color: #171717;
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
`

let ReplyTimeText = styled.span`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    margin-left: 0.8rem;
`

let ReplyContentsText = styled.textarea`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.6rem;
    color: #444444;
    width: 100%;
    resize: none;
    outline: none;
    border: none;
    padding: 0;
    overflow: hidden;
`

let ReplyShowMore = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: #9e9e9e;
    cursor: pointer;
`

let ReplyToolBorder = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    gap: 0.8rem;
    justify-content: right;
    align-items: flex-start;
`

let ReplyIcon = styled.img`
    width: 2.4rem;
    height: 2.4rem;
    object-fit: cover;
    cursor: pointer;
`

let ReplyCount = styled.div`
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.2rem;
    color: #9e9e9e;
    width: 100%;
    text-align: center;
`

let ReReplyListBorder = styled.div`
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: right;
    align-items: flex-start;
    padding-left: 3.2rem;

`

let DropBorder = styled.div`
    width: 14rem;
    box-sizing: border-box;
    padding: 0 2rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    left: 0;
    z-index: 30;
`

let DropBorderItem = styled.div`
    font-size: 1.4rem;
    text-align: left;
    padding: 1rem 0;
    &:hover{
        color: red;
    }
`

let EmojisBorder = styled.div`
    
    height: 24rem;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #dedede;
    border-width: 0.1rem;
    background-color: white;
    position: absolute;
    top: 4.5rem;
    right: 0;
    z-index: 30;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    overflow-y: auto;
    overflow-x: hidden;
`

let EmojisItems = styled.div`
    box-sizing: border-box;
    padding: 1rem;
    font-size: 1.8rem;
    cursor: pointer;
    &:hover{
        background-color: #f2f2f2;
    }
`

let ShowmoreButton = styled.button`
    width: 100%;
    height: 3rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #a9a9a9;
    background: none;
    border: none;
    cursor: pointer;
`


let DropBorder01 = styled.div`
    position: absolute;
    width: 10rem;
    height: auto;
    max-height: 30rem;
    right: 0px;
    top: 2em;
    z-index: 30;
    background-color: white;
    border-radius: 0.4rem;
    border-style: solid;
    border-color: #E8E8E8;
    border-width: 0.1rem;
    text-align: left;
`

let DropDownItems01 = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: 'Pretendard-Regular';
    font-weight: 400;
    font-size: 1.4rem;
    color: grey;
    box-sizing: border-box;
    padding: 0 2rem;
    height: 3.4rem;
    &:hover{
        color: black;
    }
`

const SnsContents = () => {
    const midBack = useSelector((state) => state.midBack);
    const [commetnsValue, setCommetnsValue] = useState('');
    const textareaRef = useRef(null);
    const params = useParams();
    let [data, setdata] = useState();
    let [changeData, setChangeData] = useState(false);
    let [lounge_id, setLounge_id] = useState();
    let navigate = useNavigate();
    let [change, setChange] = useState(false)

    let [currentImage, setCurrentImage] = useState(0);
    let [contentsImage, setContentsImage] = useState(Array.isArray(data?.image_urls) ? data?.image_urls : []);

    // 코멘트 작성
    let [isRandom, setIsRandom] = useState('랜덤 닉네임');
    let [isDrop, setIsDrop] = useState(false);
    let [isSecret, setIsSecret] = useState(false);
    let [commentsNick, setCommentsNick] = useState(''); // 닉네임 서버용
    let randomFirstNick = ['파랑', '빨강', '초록', '검정', '노랑', '분홍']
    let randomSecondNick = ['곰', '토끼', '여우', '팬더', '강아지', '고양이']
    let [comments, setComments] = useState(''); // 코멘트 서버용
    let [isCommentsDrop, setIsCommentsDrop] = useState(false);
    let emojis= [
        '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
        '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
        '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
        '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
        '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
        '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
        '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
        '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
        '✅', '❎', '💯',
    ]

    let [commentsData, setCommentsData] = useState([]);

    // 콘텐츠 data?.data[0]?.content
    const [commentsFrom, setCommentsFrom] = useState('');
    const [isTruncated, setIsTruncated] = useState(true);

    useEffect(()=>{
        if(params.id){
            const fetchData = async () => {
                try {
                    const response = await axios.get(`https://api.midtrc.com/lounge/${params.id}`,{
                        headers: {
                            'access-token': midBack?.log_data?.access_token
                        }
                    })
                    setdata(response.data);
                    setLounge_id(response.data.id);
                    setCommentsFrom(response.data?.data[0]?.content)
                    let instReplyData = response.data?.lounge_comments;
                    
                    let structuredData = instReplyData.reduce((acc, comment) => {
                        if (comment.is_sub === false) {
                            // 최상위 댓글 추가
                            acc.push({ ...comment, replies: [] });
                        } else {
                            // 대댓글인 경우, 해당 상위 댓글에 연결
                            let parentComment = acc.find(c => c.id === comment.sub_comment_id);
                            if (parentComment) {
                                parentComment.replies.push(comment);
                            }
                        }
                        return acc;
                    }, []);
                    setCommentsData(structuredData)

                } catch (error) {
                    console.log(error)
                }
            }
            fetchData();
        }

    },[params.id, midBack?.log_data?.access_token, changeData])


    useEffect(() => {
        if (data?.image_urls && Array.isArray(data.image_urls)) {
          setContentsImage(data.image_urls);
        }
      }, [data]);


    const handleEdit = () => {
        navigate(`/community-edit/${data.id}`);
    };

    const isChange = (e) => {
        setChange(!change)
    }
    
    
    

    useEffect(()=>{nicknameHandler({type: '랜덤 닉네임', text: ''})},[])

    let commentsHandler = (e) => {
        setComments(e.target.value);
    }

    let commentsEmojisHandler = (e) => {
        setComments(prevComments => prevComments + e);
    }
    
    let dropRef01 = useRef();
    
    const dropRef01Handler = (event) => {
        if (dropRef01.current && !dropRef01.current.contains(event.target)) {
            setIsDrop(false);
        }else{
            setIsDrop(true);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', dropRef01Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef01Handler);
        };
    }, []);

    let dropRef02 = useRef();
    const dropRef02Handler = (event) => {
        if (dropRef02.current && !dropRef02.current.contains(event.target)) {
            setIsCommentsDrop(false);
        }else{
            setIsCommentsDrop(true);
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', dropRef02Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef02Handler);
        };
    }, []);


    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [comments]);


    const nicknameHandler = (e) => {
        if(e.type === '랜덤 닉네임'){
            const firstPart = randomFirstNick[Math.floor(Math.random() * randomFirstNick.length)];
            const secondPart = randomSecondNick[Math.floor(Math.random() * randomSecondNick.length)];
            setCommentsNick(firstPart+secondPart);
        }else if(e.type === '고정 닉네임'){
            setCommentsNick(midBack?.log_data?.nickname?midBack?.log_data?.nickname:'')
        }else{
            setCommentsNick(e.text)
        }
    }
    
    const formatTime = (postTime) => {
        const now = new Date();
        const postDate = new Date(postTime);
    
        const diffInSeconds = Math.floor((now - postDate) / 1000);
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
    
        if (diffInHours < 1) {
            return `${diffInMinutes}분`;
        } else if (diffInHours < 3) {
            return `${diffInHours}시간`;
        } else if (diffInDays < 1) {
            return '오늘';
        } else if (diffInDays < 2) {
            return '어제';
        } else {
            const year = postDate.getFullYear().toString().slice(2); // Get last two digits of the year
            const month = (postDate.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const day = postDate.getDate().toString().padStart(2, '0');
            return `${year}.${month}.${day}`;
        }
    }

    const TimeDisplay = ({ postTime }) => {
        const formattedTime = formatTime(postTime);
        return <div>{formattedTime}</div>;
    };

    const imageHandler = () => {
        let instCount = currentImage + 1;
        if(instCount > contentsImage?.length-1){
            setCurrentImage(0);
        }else{
            setCurrentImage(instCount);
        }
    }

    const likeHandler = () => {
        axios.put(`https://api.midtrc.com/lounge/${data?.id}/like`,{},{
            headers: {
                'access-token': midBack?.log_data?.access_token
            }
        }).then((res)=>{isChange(); console.log(res.data)}).catch((error)=>{console.log(error)});
    }

    const handleCopyUrl = (id) => {
        const url = `${window.location.origin}/community/${id}`;
        navigator.clipboard.writeText(url).then(() => {
          alert('URL copied to clipboard');
        });
    };

    const favoriteHandler = () => {
        axios.put(`https://api.midtrc.com/lounge/${data?.id}/favorite`,{},{
            headers: {
                'access-token': midBack?.log_data?.access_token
            }
        }).then((res)=>{isChange(); console.log(res.data)}).catch((error)=>{console.log(error)});
    }

    const ProfileImageHandler = () => {
        let instProfile = midBack?.log_data?.profile_url;
        if(instProfile === '01'){
            return profileIcon1;
        }else if(instProfile === '02'){
            return profileIcon2;
        }else if(instProfile === '03'){
            return profileIcon3;
        }else if(instProfile === '04'){
            return profileIcon4;
        }else if(instProfile === '05'){
            return profileIcon5;
        }else{
            return instProfile
        }
    }

    const toggleTruncate = () => {
        setIsTruncated(!isTruncated);
    };

    const getDisplayedText = () => {
        let instCount = 50;
        if (isTruncated && commentsFrom && commentsFrom.length > instCount) {
            return commentsFrom.slice(0, instCount) + '...';
        }
        return commentsFrom || "";  // commentsFrom이 undefined인 경우 빈 문자열 반환
    };


    let [drop, setDrop] = useState(false);
    let [complainOpen, setComplainOpen] = useState(false);
    let dropRef03 = useRef();
    const dropRef03Handler = (event) => {
        if (dropRef03.current && !dropRef03.current.contains(event.target)) {
            setDrop(false);
        }else{
            setDrop(true);
        }
    }
    // 도 선택 드랍다운 박스 useEffect
    useEffect(() => {
        document.addEventListener('mousedown', dropRef03Handler);
        return () => {
            document.removeEventListener('mousedown', dropRef03Handler);
        };
    }, []);

    const complainHandler = () => {
        if(complainOpen){
            setComplainOpen(false)
            document.body.style.overflow = 'auto';
        }else{
            setComplainOpen(true)
            document.body.style.overflow = 'hidden';
        }
    }

    return(
        <div  style={{paddingTop: "4rem"}}>
        <ContentsBoarder>

            {/* 닉네임, 시간 */}
            <TopProfileBorder style={{marginBottom: "1.6rem"}}>
                <TopProfileImageBorder>
                    <TopProfileImage src={ProfileImageHandler()} alt="profile"/>
                </TopProfileImageBorder>
                <TopNickBorder>
                    <TopNickText>
                        {data?.nickname}
                    </TopNickText>
                    <TopTimeText>
                        <TimeDisplay postTime={data?.updated_at}/>
                    </TopTimeText>
                </TopNickBorder>

                {/* <TopDotHamburgerButton src={more} alt="dot-burger"/> */}
                

            </TopProfileBorder>
            {/* 이미지 롤링 */}
            <PhotoContentsBorder style={{marginBottom: "1.6rem"}}>
  {
    Array.isArray(contentsImage) && contentsImage.map((a, i) => (
      <PhotoImage
        src={a}
        isSelected={currentImage === i}
        key={i}
        alt={`myAlt${i}`}
        onClick={() => {
          imageHandler();
        }}
      />
    ))
  }
  <PhotoLeftProgressBorder>
    {
      Array.isArray(contentsImage) && contentsImage.map((a, i) => (
        <PhotoLeftProgressButton
          key={i}
          isSelected={currentImage === i}
          onClick={() => {
            setCurrentImage(i);
          }}
        />
      ))
    }
  </PhotoLeftProgressBorder>
</PhotoContentsBorder>
            {/* 좋아요, 코멘트, 링크, 책깔피 */}
            <StatusBorder style={{marginBottom: "1.6rem"}}>
                <SnsIconBorder>
                    <SnsIcon onClick={()=>{likeHandler()}} src={data?.is_like?likeActive:like} alt="icon01"/>
                    <SnsIconText style={{marginRight: "0.4rem"}}>
                        {data?.like_count}
                    </SnsIconText>
                    <SnsIcon src={comment} alt="icon01"/>
                    <SnsIconText>
                        {data?.comment_count}
                    </SnsIconText>
                </SnsIconBorder>
                <SnsIconBorder>
                    <SnsIcon onClick={()=>{handleCopyUrl(data?.id)}} src={link} alt="icon01"/>
                    <SnsIcon onClick={()=>{favoriteHandler()}} src={data?.is_favorite?bookMarkActive:bookMark} alt="icon01"/>
                </SnsIconBorder>
            </StatusBorder>
            {/* 게시글 제목, 게시글 내용 요약 */}
            <SnsTextBorder style={{marginBottom: "1.6rem"}}>
                <SnsTitleText style={{marginBottom: "0.8rem"}}>
                    {data?.lounge_title}
                </SnsTitleText>
                <SnsContentsText>
                    {/* <div dangerouslySetInnerHTML={{ __html: getDisplayedText()}}/>  */}
                    {parse(getDisplayedText())}
                    {
                        isTruncated && commentsFrom.length > 50?
                        <SnsContentsMore onClick={()=>{toggleTruncate()}}>
                            더 보기
                        </SnsContentsMore>
                        :
                        null
                    }
                </SnsContentsText>
            </SnsTextBorder>
            {/* 댓글 작성 */}
            {/* {CommentSubmit()} */}
            {/* 댓글 대댓글 리스트 최초 1개~ 더보기 버튼 클릭 */}
            {
                commentsData?.map((a, i)=>(
                    <ReplyBox key={i} data={a} lounge_id={data?.id} isChange={isChange}/>
                ))
            }
            {/* {
                data?.lounge_comments?.length === 0 || data?.lounge_comments?.length === 1?
                <div />
                :
                <ShowmoreButton>
                    더 보기
                </ShowmoreButton>
            } */}
        </ContentsBoarder>
        </div>
    )
}

export default SnsContents;