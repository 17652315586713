import React from 'react'
import './AllianceManageInfo.css'
import imgex2 from '../img/imgex2.png'
import { Link } from 'react-router-dom'
import PageArrowL from '../img/PageArrowL.png'
const AllianceManageInfo = () => {
  return (
    <div className='AllianceManageInfo'>
      {/* 리스트로 돌아가기 */}
      <div className='history_back'>
        <Link to="#" className='history_back_cover'>
          <button>리스트로 돌아가기</button>
        </Link>
      </div>
      <div className='AllianceManageInfoCover'>
        <div className='AllianceManageInfoCoverArea'>
          {/* 탭 */}
          <div className='AllianceManageInfoCoverAreaTab'>
            {/* 왼쪽 */}
            <div className='AllianceManageInfoCoverAreaTabLeft'>입점 기준 안내<br />텍스트 박스</div>
            {/* 오른쪽 */}
            <div className='AllianceManageInfoCoverAreaTabRight'>
              <img src={imgex2} alt='imgex2' />
              <textarea></textarea>
            </div>
          </div>
          <div className='AllianceManageBtn'>
            <button>저 장</button>
            <button>취 소</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllianceManageInfo