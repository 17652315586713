import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import './Declaration.css';
import styled from 'styled-components';




const itemsPerPage = 10;
const pagesPerSet = 5;

const Declaration = () => {
  let navigate = useNavigate();
  let [clickedIndex, setClickedIndex] = useState('신고된 공고');
  let [complaints, setComplaints] = useState([]);
  let [currentPage, setCurrentPage] = useState(1);
  let [currentSet, setCurrentSet] = useState(1);

  const midBack = useSelector((state) => state.midBack);

  const getComplainType = (index) => {
    switch (index) {
      case '신고된 공고':
        return 'recruit';
      case '신고된 이력서':
        return 'resume';
      case '신고된 게시글':
        return 'lounge';
      case '신고된 채팅':
        return 'message_group';
      default:
        return '';
    }
  };

  const fetchData = async () => {
    try {
        const isBack = clickedIndex === '신고된 채팅' ? true : undefined; // 신고된 채팅일 경우에만 is_back을 true로 설정
        
        const response = await axios.get('https://api.midtrc.com/complain', {
            params: {
                complain_type: getComplainType(clickedIndex),
                is_back: isBack  // 조건에 따른 is_back 설정
            },
            headers: {
                'access-token': midBack?.log_data?.access_token
            }
        });
        
        // 데이터를 내림차순으로 정렬
        const sortedData = response.data.sort((a, b) => b.id - a.id);
        setComplaints(sortedData);
        console.log(sortedData);
    } catch (error) {
        console.error('Error fetching the complaints data:', error);
        if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
        } else if (error.request) {
            console.error('Request data:', error.request);
        } else {
            console.error('Error message:', error.message);
        }
    }
};

  useEffect(() => {
    fetchData();
  }, [midBack, clickedIndex]);

  const filterComplaints = (type) => {
    return complaints.filter(complaint => complaint.complain_type === type);
  }

  const MyPageIndexBorder = () => {
    const IndexButton = ({name}) => {
      return(
        <div>
          {clickedIndex === name
            ? <div className="default-index-border-button" style={{backgroundColor: "#EDF5FF"}} onClick={() => setClickedIndex(name)}>{name}</div>
            : <div className="default-index-border-button" onClick={() => setClickedIndex(name)}>{name}</div>
          }
        </div>
      )
    }

    return(
      <div className="default-index-border" style={{paddingBottom: "5px"}}>
        <IndexButton name={'신고된 공고'}/>
        <IndexButton name={'신고된 이력서'}/>
        <IndexButton name={'신고된 게시글'}/>
        <IndexButton name={'신고된 채팅'}/>
      </div>
    )
  }

  const DefaultContentsBorder = () => {
    const recruitComplaints = filterComplaints('recruit');
    const paginatedComplaints = recruitComplaints.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
      <div className="default-contents-border">
        <div className="default-contents-border-top" 
          style={{fontSize: "14px", height: "50px", display: "grid", gridTemplateColumns: "0.5fr 2fr 1fr 1fr 1.3fr 0.8fr"}}>
          <div style={{width: "100%", textAlign: "center"}}>번호</div>
          <div style={{width: "100%", textAlign: "center"}}>업체명</div>
          <div style={{width: "100%", textAlign: "center"}}>신고 내용</div>
          <div style={{width: "100%", textAlign: "center"}}>신고자</div>
          <div style={{width: "100%", textAlign: "center"}}>처리상태</div>
          <div style={{width: "100%", textAlign: "center"}}>상세보기</div>
        </div>
              {paginatedComplaints.map(complaint => (
        <div key={complaint.id} className="default-contents-border-top" 
          style={{fontSize: "14px", height: "50px", display: "grid", gridTemplateColumns: "0.5fr 2fr 1fr 1fr 1.3fr 0.8fr"}}>
          <div style={{width: "100%", textAlign: "center"}}>{complaint.id}</div>
          <div style={{width: "100%", textAlign: "center"}}>{complaint.client.nickname}</div>
          <div style={{width: "100%", textAlign: "center"}}>{complaint.complain_title}</div>
          <div style={{width: "100%", textAlign: "center"}}>{complaint.complain_client.nickname}</div>
          <div style={{width: "100%", textAlign: "center"}}>{complaint.status === 'waiting' ? '처리전' : '처리완료'}</div>
          <div onClick={() => navigate(`/declaration-detail/${complaint.id}`)} style={{width: "100%", textAlign: "center", color: "blue", cursor: "pointer"}}>상세보기</div>
        </div>
      ))}
        <DefaultPagenation totalItems={recruitComplaints.length} />
      </div>
    )
  }

  const DefaultContentsBorder2 = () => {
    const resumeComplaints = filterComplaints('resume');
    const paginatedComplaints = resumeComplaints.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
      <div className="default-contents-border">
        <div className="default-contents-border-top" 
          style={{fontSize: "14px", height: "50px", display: "grid", gridTemplateColumns: "0.5fr 2fr 1fr 1fr 0.8fr"}}>

          <div style={{width: "100%", textAlign: "center"}}>번호</div>
          <div style={{width: "100%", textAlign: "center"}}>신고 내용</div>
          <div style={{width: "100%", textAlign: "center"}}>신고자</div>
          <div style={{width: "100%", textAlign: "center"}}>처리상태</div>
          <div style={{width: "100%", textAlign: "center"}}>상세보기</div>
        </div>
        {paginatedComplaints.map(complaint => (
          <div key={complaint.id} className="default-contents-border-top" 
            style={{fontSize: "14px", height: "50px", display: "grid", gridTemplateColumns: "0.5fr 2fr 1fr 1fr 0.8fr"}}>

            <div style={{width: "100%", textAlign: "center"}}>{complaint.id}</div>
            <div style={{width: "100%", textAlign: "center"}}>{complaint.complain_title}</div>
            <div style={{width: "100%", textAlign: "center"}}>{complaint.complain_client.nickname}</div>
            <div style={{width: "100%", textAlign: "center"}}>{complaint.status === 'waiting' ? '처리전' : '처리완료'}</div>
            <div onClick={() => navigate(`/declaration-resume-detail/${complaint.id}`)} style={{width: "100%", textAlign: "center", color: "blue", cursor: "pointer"}}>상세보기</div>
          </div>
        ))}
        <DefaultPagenation totalItems={resumeComplaints.length} />
      </div>
    )
  }

  const DefaultContentsBorder3 = () => {
    const loungeComplaints = filterComplaints('lounge');
    const paginatedComplaints = loungeComplaints.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
      <div className="default-contents-border">
        <div className="default-contents-border-top" 
          style={{fontSize: "14px", height: "50px", display: "grid", gridTemplateColumns: "0.5fr 3fr 1fr 1fr 0.8fr"}}>

          <div style={{width: "100%", textAlign: "center"}}>번호</div>
          <div style={{width: "100%", textAlign: "center"}}>신고 내용</div>
          <div style={{width: "100%", textAlign: "center"}}>신고자</div>
          <div style={{width: "100%", textAlign: "center"}}>처리상태</div>
          <div style={{width: "100%", textAlign: "center"}}>상세보기</div>
        </div>
        {paginatedComplaints.map(complaint => (
          <div key={complaint.id} className="default-contents-border-top" 
            style={{fontSize: "14px", height: "50px", display: "grid", gridTemplateColumns: "0.5fr 3fr 1fr 1fr 0.8fr"}}>

            <div style={{width: "100%", textAlign: "center"}}>{complaint.id}</div>
            <div style={{width: "100%", textAlign: "center"}}>{complaint.complain_title}</div>
            <div style={{width: "100%", textAlign: "center"}}>{complaint.complain_client.nickname}</div>
            <div style={{width: "100%", textAlign: "center"}}>{complaint.status === 'waiting' ? '처리전' : '처리완료'}</div>
            <div onClick={() => navigate(`/declaration-post-detail/${complaint.id}`)} style={{width: "100%", textAlign: "center", color: "blue", cursor: "pointer"}}>상세보기</div>
          </div>
        ))}
        <DefaultPagenation totalItems={loungeComplaints.length} />
      </div>
    )
  }

  const DefaultContentsBorder4 = () => {
    const messageGroupComplaints = filterComplaints('message_group');
    const paginatedComplaints = messageGroupComplaints.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
      <div className="default-contents-border">
        <div className="default-contents-border-top" 
          style={{fontSize: "14px", height: "50px", display: "grid", gridTemplateColumns: "0.5fr 2fr 1fr 1fr 0.8fr"}}>

          <div style={{width: "100%", textAlign: "center"}}>번호</div>
          <div style={{width: "100%", textAlign: "center"}}>신고 내용</div>
          <div style={{width: "100%", textAlign: "center"}}>신고자</div>
          <div style={{width: "100%", textAlign: "center"}}>처리상태</div>
          <div style={{width: "100%", textAlign: "center"}}>상세보기</div>
        </div>
        {paginatedComplaints.map(complaint => (
          <div key={complaint.id} className="default-contents-border-top" 
            style={{fontSize: "14px", height: "50px", display: "grid", gridTemplateColumns: " 0.5fr 2fr 1fr 1fr 0.8fr"}}>
  
            <div style={{width: "100%", textAlign: "center"}}>{complaint.id}</div>
            <div style={{width: "100%", textAlign: "center"}}>{complaint.complain_title}</div>
            <div style={{width: "100%", textAlign: "center"}}>{complaint.complain_client.nickname}</div>
            <div style={{width: "100%", textAlign: "center"}}>{complaint.status === 'waiting' ? '처리전' : '처리완료'}</div>
            <div onClick={() => navigate(`/declaration-chat-detail/${complaint.id}`)} style={{width: "100%", textAlign: "center", color: "blue", cursor: "pointer"}}>상세보기</div>
          </div>
        ))}
        <DefaultPagenation totalItems={messageGroupComplaints.length} />
      </div>
    )
  }

  const ContentsSwitcher = () => {
    switch(clickedIndex){
      case '신고된 공고':
        return <DefaultContentsBorder />;
      case '신고된 이력서':
        return <DefaultContentsBorder2 />;
      case '신고된 게시글':
        return <DefaultContentsBorder3 />;
      case '신고된 채팅':
        return <DefaultContentsBorder4 />;
      default: 
        return <></>;
    }
  }

  const DefaultPagenation = ({ totalItems }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const startPage = (currentSet - 1) * pagesPerSet + 1;
    const endPage = Math.min(currentSet * pagesPerSet, totalPages);

    return (
      <div style={{ display: "flex", height: "50px", justifyContent: "center", alignItems: "center", gap: "10px" }}>
        {startPage > 1 && <div onClick={() => handlePrevSet()} className="default-contents-pagenation-button">{"<"}</div>}
        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
          <div
            key={page}
            onClick={() => handlePageChange(page)}
            className="default-contents-pagenation-button"
            style={{ color: page === currentPage ? 'black' : 'grey' }}
          >
            {page}
          </div>
        ))}
        {endPage < totalPages && <div onClick={() => handleNextSet()} className="default-contents-pagenation-button">{">"}</div>}
      </div>
    );
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleNextSet = () => {
    setCurrentSet(prevSet => prevSet + 1);
    setCurrentPage((currentSet * pagesPerSet) + 1);
  };

  const handlePrevSet = () => {
    setCurrentSet(prevSet => prevSet - 1);
    setCurrentPage(((currentSet - 2) * pagesPerSet) + 1);
  };

  return (
    <div className='Declaration'>
      <div>
        <MyPageIndexBorder />
      </div>
      <ContentsSwitcher />
    </div>
  )
}

export default Declaration;
