import React from 'react'
import './BestPickUpdate.css'
import plus2 from '../img/plus2.svg'
import imgex2 from '../img/imgex2.png'
const BestPickUpdate = () => {


  const DefaultContentsBorder = () => {
    return(
      <div className='TerraceUpdateCoverArea3'>
      <div>
        {/* 탭 리스트 */}
        <div className='TerraceUpdateTabList'>
        {/* 카테고리 선택 */}
          <div className='TerraceUpdateTab'>
            <div className='TerraceUpdateTabTop'>카테고리 선택</div>
            <div className='TerraceUpdateTabBot'>
              <button>카테고리</button>
              <button>카테고리</button>
              <button>카테고리</button>
              <button>카테고리</button>
            </div>
          </div>
          {/* 작성자 */}
          <div className='TerraceUpdateTab'>
            <div className='TerraceUpdateTabTop'>
              <div>작성자</div>
              <div className='default-contents-border-check-box-border'>
                <input type='checkbox' className='default-contents-border-check-box' style={{marginRight:"20px"}}/>
                <label>랜덤 닉네임</label>
              </div>
            </div>
            <div className='TerraceUpdateTabBot'>
              <input type='text' placeholder='랜덤 닉네임'/>
            </div>
          </div>
          {/* 글 제목 */}
          <div className='TerraceUpdateTab'>
            <div className='TerraceUpdateTabTop'>글 제목</div>
            <div className='TerraceUpdateTabBot'>
              <input type='text' placeholder='글 제목을 입력하세요'/>
              <div>50/0</div>
            </div>
          </div>
          {/* 내용 */}
          <div className='TerraceUpdateTab'>
            <div className='TerraceUpdateTabTop'>내용</div>
            <div className='TerraceUpdateTabBot'>
              <img src={imgex2} alt='imgex2' />
              <textarea placeholder='' className='default-contents-textarea'></textarea>
            </div>
          </div>
          {/* 이미지 동영상 첨부 */}
          <div className='TerraceUpdateTab'>
            <div className='TerraceUpdateTabTop'>이미지 & 동영상 첨부 (5/0)</div>
            <div className='TerraceUpdateTabBot'>
              <div className='ImgBox'>
                <img src={plus2} alt='plus' />
              </div>
              <div className='ImgBox'>
                <img src={plus2} alt='plus' />
              </div>
              <div className='ImgBox'>
                <img src={plus2} alt='plus' />
              </div>
            </div>
          </div>
        </div>
        <div className='default-contents-border-confirm'>
          <button className='default-contents-border-confirm-button'>취 소</button>
          <button className='default-contents-border-confirm-button' style={{background:"#ff0000",color:"#fff"}}>삭 제</button>
          <button className='default-contents-border-confirm-button' style={{background:"#183b5b",color:"#fff"}}>저 장</button>
        </div>
      </div>
    </div>
    )
}



  return(
    <div>
      <DefaultContentsBorder />
    </div>
  )

}

export default BestPickUpdate