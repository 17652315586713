import React, { useEffect, useState } from 'react';
import './ResumeDetail.css';
import profile from '../img/profile.png';
import OpenEyes from '../img/choice-talk/OpenEyes.png';
import CloseEyes from '../img/choice-talk/invisible_0.png';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ResumeDetailPage from './@resume-detail';

const Resumemyintro = styled.div`
  width: 100%;
  height: 300px;
  background: #f5f5f5;
`;

const Resumemylocation = styled.div`
  border: 1px solid #dedede;
  height: 50px;
  width: 130px;
  border-radius: 20px;
  text-align: center;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Resumekeyword = styled.div`
  border: 1px solid #dedede;
  height: 50px;
  width: 130px;
  border-radius: 20px;
  text-align: center;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ResumeCareer = styled.div`
  font-size: 14px;
  color: #a9a9a9;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dedede;
  height: 40px;
`;

const ResumeDetail = () => {
  const { resumeid } = useParams(); // URL 파라미터에서 resumeId를 가져옴
  const [resumeData, setResumeData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const midBack = useSelector((state) => state.midBack);

  const getFormattedGender = (gender) => {
    return gender === 'male' ? '남' : '여';
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseResume = await axios.get(`https://api.midtrc.com/resume/${resumeid}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setResumeData(responseResume.data);

        const responseClient = await axios.get(`https://api.midtrc.com/client/${responseResume.data.client_id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setClientData(responseClient.data);
      } catch (error) {
        console.error('Error fetching the data:', error);
      }
    };

    fetchData();
  }, [resumeid, midBack]);

  if (!resumeData || !clientData) return <div>Loading...</div>;

  const getStatusImage = (status) => {
    if (['active', 'represent'].includes(status)) {
      return OpenEyes;
    } else {
      return CloseEyes;
    }
  };

  const handleStatusClick = async () => {
    // 현재 상태에 따라 바뀔 상태를 결정
    const newStatus = resumeData.status === 'active' || resumeData.status === 'represent' ? 'paused' : 'active';

    try {
      // PUT 요청으로 상태 변경
      await axios.put(
        `https://api.midtrc.com/resume/${resumeid}/status`,
        {
          before: resumeData.status,
          after: newStatus
        },
        {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        }
      );

      // 상태 업데이트
      const responseResume = await axios.get(`https://api.midtrc.com/resume/${resumeid}`, {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });

      setResumeData(responseResume.data); // 상태 업데이트 후 데이터 다시 불러옴
    } catch (error) {
      console.error('Error updating the status:', error);
    }
  };

  return (
    <div>
      <div className="default-contents-border" style={{ marginBottom: '20px' }}>
        <div className="default-contents-border-top">작성자</div>
        <div style={{ boxSizing: 'border-box', padding: '10px 20px 10px 10px', display: 'grid', gridTemplateColumns: '1fr 4.5fr 4.5fr', gap: '20px' }}>
          <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <div>
              <img src={clientData.profile_url || profile} alt="profile" />
            </div>
            <div className="default-contents-border-title" style={{ justifyContent: 'center' }}>
              {clientData.nickname}
            </div>
          </div>
          <div>
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 8fr', gap: '20px' }}>
              <div className="default-contents-border-title">가입유형</div>
              <div style={{ position: 'relative' }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData.client_type === 'user' ? '일반회원' : '사업자회원'} />
              </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 8fr', gap: '20px' }}>
              <div className="default-contents-border-title">이름</div>
              <div style={{ position: 'relative' }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData.name} />
              </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 8fr', gap: '20px' }}>
              <div className="default-contents-border-title">연락처</div>
              <div style={{ position: 'relative' }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData.phone_num} />
              </div>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 8fr', gap: '20px' }}>
              <div className="default-contents-border-title">이메일</div>
              <div style={{ position: 'relative' }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData.email} />
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'grid', gridTemplateColumns: '2fr 8fr', gap: '20px' }}>
              <div className="default-contents-border-title">성별/나이</div>
              <div style={{ position: 'relative' }}>
                <input readOnly={true} className="default-input-line-tag" value={`${getFormattedGender(clientData.gender)}/${resumeData.age} 세`} />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div
        className="default-contents-border"
        style={{ height: '50px', marginBottom: '20px', display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="default-contents-border-title" style={{ justifyContent: 'left', width: 'auto', marginRight: '20px' }}>
            이력서 상태
          </div>
          <img
            src={getStatusImage(resumeData.status)}
            alt="Status"
            style={{ height: '20px', padding: '4px 0 0 0', cursor: 'pointer' }}
            onClick={handleStatusClick}
          />
        </div>
      </div>

      <div className="ResumeDetailCoverArea3">
        <ResumeDetailPage />
      </div>
    </div>
  );
};

export default ResumeDetail;
