import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import './Ceo.css';

const Ceo = () => {
  let [clickedIndex, setClickedIndex] = useState('최고 관리자 정보');
  let [adminData, setAdminData] = useState(null);
  let [managerData, setManagerData] = useState([]);

  const midBack = useSelector((state) => state.midBack);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.midtrc.com/client', {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        const data = response.data;
        const adminInfo = data.find(user => user.client_type === 'admin');
        const managerList = data.filter(user => user.client_type === 'manager' && user.delete_yn === false);
        setAdminData(adminInfo);
        setManagerData(managerList);

      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [midBack]);

  const MyPageIndexBorder = () => {
    const IndexButton = ({ name }) => {
      return (
        <div>
          {
            clickedIndex === name ?
              <div className="default-index-border-button" style={{ backgroundColor: "#EDF5FF" }} onClick={() => { setClickedIndex(name) }}>
                {name}
              </div>
              :
              <div className="default-index-border-button" onClick={() => { setClickedIndex(name) }}>
                {name}
              </div>
          }
        </div>
      );
    }
    return (
      <div className="default-index-border" style={{ paddingBottom: "5px" }}>
        <IndexButton name={'최고 관리자 정보'} />
        <IndexButton name={'매니저 관리'} />
      </div>
    );
  }

  const MyPageContentsBorder = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [email, setEmail] = useState(adminData?.email || '');
    const [phoneNum, setPhoneNum] = useState(adminData?.phone_num || '');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordMatchMessage, setPasswordMatchMessage] = useState('');
  
    const handleadminEditClick = async () => {
      if (!isEditing) {
        setIsEditing(true);
        return;
      }
  
      if (password !== confirmPassword) {
        setPasswordMatchMessage('비밀번호가 일치하지 않습니다.');
        return;
      }
  
      try {
        const token = midBack?.log_data?.access_token;
        if(password === ''){
          let req_body = { req_admin: {
            email: email,
            phone_num: phoneNum,
            // pwd: password
          }}
          const jsonRequestData = JSON.stringify(req_body);
          const formData = new FormData();
          formData.append('req_body', jsonRequestData);
      
            const response = await axios.put(`https://api.midtrc.com/client/${adminData?.id}?client_type=admin`, formData, {
              headers: {
                'access-token': token
              }
            });
      
            if (response.status === 200) {
              alert('관리자 정보가 성공적으로 수정되었습니다.');
              setIsEditing(false);
            } else {
              console.error('수정 요청 실패:', response);
            }
        }else{
          let req_body = { req_admin: {
            email: email,
            phone_num: phoneNum,
            pwd: password
          }}

          const jsonRequestData = JSON.stringify(req_body);
          const formData = new FormData();
          formData.append('req_body', jsonRequestData);
      
            const response = await axios.put(`https://api.midtrc.com/client/${adminData?.id}?client_type=admin`, formData, {
              headers: {
                'access-token': token
              }
            });
      
            if (response.status === 200) {
              alert('관리자 정보가 성공적으로 수정되었습니다.');
              setIsEditing(false);
            } else {
              console.error('수정 요청 실패:', response);
            }
        }
        
      } catch (error) {
        console.error('관리자 정보 수정 중 오류 발생:', error);
        alert('수정 중 오류가 발생했습니다. 다시 시도해주세요.');
      }
    };
  
    const handlePasswordMatch = () => {
      if (password !== confirmPassword) {
        setPasswordMatchMessage('비밀번호가 일치하지 않습니다.');
      } else {
        setPasswordMatchMessage('비밀번호가 일치합니다.');
      }
    };
  
    return (
      <div className="default-contents-border">
        <div className="default-contents-border-top">
          최고 관리자 계정관리
        </div>
  
        <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
          <div>
            <div className="default-contents-border-title">아이디</div>
            <div className="default-contents-border-title">이메일</div>
            <div className="default-contents-border-title">최종 로그인</div>
            <div className="default-contents-border-title">수정일</div>
            <div className="default-contents-border-title">권한</div>
            <div className="default-contents-border-title">연락처</div>
            <div className="default-contents-border-title">비밀번호</div>
            <div className="default-contents-border-title">비밀번호 확인</div>
          </div>
          <div>
            <input readOnly={true} className="default-input-tag" value={adminData?.login_id || ''} />
            <div style={{ position: "relative" }}>
              <input 
                readOnly={!isEditing} 
                className="default-input-line-tag" 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
              />
            </div>
            <input readOnly={true} className="default-input-tag" value={adminData?.latest_login_at || ''} />
            <input readOnly={true} className="default-input-tag" value={adminData?.updated_at || ''} />
            <input readOnly={true} className="default-input-tag" value={'최고 관리자'} />
            <div style={{ position: "relative" }}>
              <input 
                readOnly={!isEditing} 
                className="default-input-line-tag" 
                value={phoneNum} 
                onChange={(e) => setPhoneNum(e.target.value)} 
              />
            </div>
            <div style={{ position: "relative" }}>
              <input 
                type="password" 
                readOnly={!isEditing} 
                className="default-input-line-tag" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
            </div>
            <div style={{ position: "relative" }}>
              <input 
                type="password" 
                readOnly={!isEditing} 
                className="default-input-line-tag" 
                value={confirmPassword} 
                onChange={(e) => setConfirmPassword(e.target.value)} 
                onBlur={handlePasswordMatch} 
              />
              <div className="default-input-tag-edit">
                {passwordMatchMessage}
              </div>
            </div>
          </div>
        </div>
        <div className="default-contents-border-confirm">
          <button 
            className="default-contents-border-confirm-button" 
            style={{ backgroundColor: "#183B5B", color: "white" }} 
            onClick={handleadminEditClick}
          >
            {isEditing ? '저장' : '편집'}
          </button>
        </div>
      </div>
    );
  };
  

  const ManagerContentsBorder = () => {
    const [loginId, setLoginId] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');  // name 필드를 위해 변수 추가
    const [phoneNum, setPhoneNum] = useState('');
    const [department, setDepartment] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordMatchMessage, setPasswordMatchMessage] = useState('');

    const handlePasswordMatch = () => {
      if (password !== confirmPassword) {
        setPasswordMatchMessage('비밀번호를 확인해주세요');
      } else {
        setPasswordMatchMessage('비밀번호를 사용하실 수 있습니다');
      }
    };

 

 

    const handleSubmit = async () => {
      if (password !== confirmPassword) {
        setPasswordMatchMessage('비밀번호를 확인해주세요');
        return;
      }
    
      try {
        const token = midBack?.log_data?.access_token; // Redux 상태에서 인증 토큰 가져오기
        const response = await axios.post('https://api.midtrc.com/auth/signup-manager', {
          login_id: loginId,
          email: email,
          pwd: password,
          name: name,
          phone_num: phoneNum,
          department: department,

        }, {
          headers: {
            'Authorization': `Bearer ${token}` // 토큰을 Authorization 헤더에 포함
          }
        });
    
        if (response.status === 200) {
          alert('매니저가 성공적으로 생성되었습니다.');
          window.location.reload(); 
        }
      } catch (error) {
        console.error('매니저 생성 중 오류 발생:', error);
        if (error.response.status === 401) {
          alert('인증 오류가 발생했습니다. 다시 로그인 해주세요.');
        }
      }
    };




    return (
      <div className="default-contents-border" style={{ marginBottom: "20px", height:"450px"}}>
      <div className="default-contents-border-top">
        매니저 추가
      </div>

      <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
        <div>
          <div className="default-contents-border-title">
            아이디
          </div>
          <div className="default-contents-border-title">
            이메일
          </div>
          <div className="default-contents-border-title">
            이름
          </div>
          <div className="default-contents-border-title">
            연락처
          </div>
          <div className="default-contents-border-title">
            부서
          </div>
          <div className="default-contents-border-title">
            비밀번호
          </div>
          <div className="default-contents-border-title">
            비밀번호 확인
          </div>

        </div>
        <div>
          <div style={{ position: "relative" }}>
            <input
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
              className="default-input-line-tag"
              placeholder="아이디를 입력하세요"
            />
          </div>
          <div style={{ position: "relative" }}>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="default-input-line-tag"
              placeholder="이메일 주소를 입력하세요"
            />
          </div>
          <div style={{ position: "relative" }}>
            <input
              value={name}  // 올바른 변수명 사용
              onChange={(e) => setName(e.target.value)}  // 올바른 state 업데이트
              className="default-input-line-tag"
              placeholder="이름을 입력하세요"
            />
          </div>
          <div style={{ position: "relative" }}>
            <input
              value={phoneNum}
              onChange={(e) => setPhoneNum(e.target.value)}
              className="default-input-line-tag"
              placeholder="'-'를 제외한 번호를 기입하세요"
            />
          </div>
          <div style={{ position: "relative" }}>
            <input
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              className="default-input-line-tag"
              placeholder="부서명을 입력하세요"
            />
          </div>
          <div style={{ position: "relative" }}>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="default-input-line-tag"
              placeholder="비밀번호를 입력하세요"
            />
          </div>
          <div style={{ position: "relative" }}>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="default-input-line-tag"
              placeholder="비밀번호를 한번 더 입력하세요"
              onBlur={handlePasswordMatch}
            />
            <div className="default-input-tag-edit">
              {passwordMatchMessage}
            </div>
          </div>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
           
          </div>
        </div>
      </div>
      <div className="default-contents-border-confirm">

        <button
          className="default-contents-border-confirm-button"
          style={{ backgroundColor: "#183B5B", color: "white" }}
          onClick={handleSubmit}
        >
          생성
        </button>
      </div>
    </div>
  );
}

const ManagerEditContentsBorder = ({ manager }) => {
  console.log("Manager Object:", manager);
  console.log("Manager ID:", manager.id);

  const [name, setName] = useState(manager.name);
  const [email, setEmail] = useState(manager.email);
  const [phoneNum, setPhoneNum] = useState(manager.phone_num);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [department, setDepartment] = useState(manager.department);

  const [passwordMatchMessage, setPasswordMatchMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const midBack = useSelector((state) => state.midBack);
  const token = midBack?.log_data?.access_token;



  const handlePasswordMatch = () => {
    if (password !== confirmPassword) {
      setPasswordMatchMessage('비밀번호가 일치하지 않습니다.');
    } else {
      setPasswordMatchMessage('비밀번호가 일치합니다.');
    }
  };

  const handleDelete = async () => {
    const isConfirmed = window.confirm('정말 삭제하시겠습니까?');
    if (isConfirmed) {
      setIsLoading(true);
      try {
        const response = await axios.delete(`https://api.midtrc.com/client/${manager.id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        console.log(response);
        if (response.status === 200) {
          alert('매니저가 성공적으로 삭제되었습니다.');
          window.location.reload(); 
        } else {
          console.error('삭제 요청 실패:', response);
        }
      } catch (error) {
        console.error('매니저 삭제 중 오류 발생:', error);
        if (error.response?.status === 401) {
          alert('인증 오류가 발생했습니다. 다시 로그인 해주세요.');
        } else {
          alert('삭제 중 오류가 발생했습니다. 다시 시도해주세요.');
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleEditClick = async () => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }
  
    try{
  
      let req_body = {req_manager: {
        "name": name,
        "email": email,
        "phone_num": phoneNum,
        "department": department,
        // pwd: "12345",
        // "permission_edit": false,
        // "permission_notice": false,
        // "permission_mainpage": false,
        // "permission_choice": false,
        // "permission_message": false,
        // "permission_recruit": false
      }}

      const jsonRequestData = JSON.stringify(req_body);
      const formData = new FormData();
      formData.append('req_body',jsonRequestData);

      
  
      const response = await axios.put(`https://api.midtrc.com/client/${manager.id}?client_type=manager`, formData, {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });
  
      if (response.status === 200) {
        alert('매니저 정보가 성공적으로 수정되었습니다.');
        setIsEditing(false);
      } else {
        console.error('수정 요청 실패:', response);
      }
    } catch (error) {
      console.error('매니저 정보 수정 중 오류 발생:', error);
      alert('수정 중 오류가 발생했습니다. 다시 시도해주세요.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="default-contents-border" style={{ marginBottom: "20px" }}>
      <div className="default-contents-border-top">
        {name}
      </div>

      <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
        <div>
          <div className="default-contents-border-title">아이디</div>
          <div className="default-contents-border-title">이메일</div>
          <div className="default-contents-border-title">이름</div>
          <div className="default-contents-border-title">연락처</div>
          <div className="default-contents-border-title">부서</div>
          <div className="default-contents-border-title">최종 로그인</div>
          <div className="default-contents-border-title">수정일</div>
          
        </div>
        <div>
          <div style={{ position: "relative" }}>
            <input readOnly={true} className="default-input-line-tag" value={manager.login_id} />
          </div>
          <div style={{ position: "relative" }}>
            <input 
              readOnly={!isEditing} 
              className="default-input-line-tag" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
            />
          </div>
          <div style={{ position: "relative" }}>
            <input 
              readOnly={!isEditing} 
              className="default-input-line-tag" 
              value={name} 
              onChange={(e) => setName(e.target.value)} 
            />
          </div>
          <div style={{ position: "relative" }}>
            <input 
              readOnly={!isEditing} 
              className="default-input-line-tag" 
              value={phoneNum} 
              onChange={(e) => setPhoneNum(e.target.value)} 
            />
          </div>
          <div style={{ position: "relative" }}>
            <input 
              readOnly={!isEditing} 
              className="default-input-line-tag" 
              value={department} 
              onChange={(e) => setDepartment(e.target.value)} 
            />
          </div>
          <input readOnly={true} className="default-input-tag" value={manager.latest_login_at} />
          <input readOnly={true} className="default-input-tag" value={manager.updated_at} />

          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
  
           
           
          </div>
        </div>
      </div>
      <div className="default-contents-border-confirm">
        <button
          className="default-contents-border-confirm-button"
          onClick={handleDelete}
          disabled={isLoading}
        >
          삭제
        </button>

        <button 
          className="default-contents-border-confirm-button" 
          onClick={handleEditClick} 
          style={{ backgroundColor: "#183B5B", color: "white" }}
          disabled={isLoading}
        >
          {isEditing ? '저장' : '편집'}
        </button>
      </div>
    </div>
  );
};


  const ContentsSwitcher = () => {
    switch (clickedIndex) {
      case '최고 관리자 정보':
        return <MyPageContentsBorder />;
      case '매니저 관리':
        return <ManagerContentsBorder />;
      default:
        return null;
    }
  };

  return (
    <div className='my-page-border'>
      <div>
        <MyPageIndexBorder />
      </div>
      <ContentsSwitcher />
      {
        clickedIndex === '매니저 관리' &&
        <div className='my-page-manager-edit-border'>
          {managerData.map((manager, index) => (
            <ManagerEditContentsBorder key={index} manager={manager} />
          ))}
        </div>
      }
    </div>
  )
}

export default Ceo;
