import React from 'react'
import './ManagerDetail.css'
import SubBox1 from '../Ceo/SubBox1'
import ManagerDetailInfo from './ManagerDetailInfo'

const ManagerDetail =()=> {
  return (
    <>
      <SubBox1 />
      <ManagerDetailInfo />
    </>
  )
}

export default ManagerDetail