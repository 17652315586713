import React from 'react';
import './BoardManageDetail.css'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import CommunityPostA from './ManagerboardPost/community-post-a';

const BoardManageDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams();
  const midBack = useSelector((state) => state.midBack); // Redux에서 access token을 가져옵니다.

  const handleDelete = async () => {
    const confirmDelete = window.confirm('정말 게시글을 삭제하시겠습니까?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`https://api.midtrc.com/notice/${id}`, {
        headers: {
          'access-token': midBack?.log_data?.access_token
        }
      });
      alert('게시글이 성공적으로 삭제되었습니다.');
      navigate('/board-management');
    } catch (error) {
      console.error('Error deleting board:', error);
      alert('게시글 삭제 중 오류가 발생했습니다.');
    }
  };

  const DefaultContentsBorder = () => {
    return (
      <div className='default-contents-border'>
        <CommunityPostA />
        {/* 버튼 리스트 */}
        <div className='default-contents-border-confirm'>
          <button className='default-contents-border-confirm-button' onClick={() => {navigate(`/board-management`)}}>취 소</button>
          <button 
            className='default-contents-border-confirm-button' 
            style={{background:"#ff0000", color:"#fff"}} 
            onClick={handleDelete} // 삭제 핸들러 연결
          >
            삭 제
          </button>
          <button onClick={() => {navigate(`/boardmanage-update/${id}`)}} className='default-contents-border-confirm-button' style={{background:"#183b5b", color:"#fff"}}>수 정</button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <DefaultContentsBorder />
    </div>
  )
}

export default BoardManageDetail;
