import React, { useState, useRef } from 'react';
import './MessageAddModal.css';
import closeButton from '../img/close-button.png';
import axios from 'axios';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

let ModalContentBorder = styled.div`
    position:relative;
`;

const MessageAddModalPlus = ({ modalManager }) => {
  const midBack = useSelector((state) => state.midBack);
  const [selectedOption, setSelectedOption] = useState('전체 회원'); // 초기값을 '전체 회원'으로 설정
  const [groupType, setGroupType] = useState('manage'); // 초기값을 '관리자 메시지'로 설정
  const [contactLoginId, setContactLoginId] = useState(''); // 특정 회원 아이디 입력 상태 추가
  const [messageDetail, setMessageDetail] = useState(''); // 메시지 내용을 상태로 관리
  const contactLoginIdRef = useRef(contactLoginId); // useRef를 사용하여 입력값을 추적
  const messageDetailRef = useRef(''); // 메시지 내용도 useRef로 관리

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleGroupTypeChange = (event) => {
    const selectedValue = event.target.value;
    setGroupType(
      selectedValue === '관리자 메시지' ? 'manage' :
      selectedValue === '이벤트 메시지' ? 'event' : 'general'
    );
  };

  const handleLoginIdChange = (event) => {
    contactLoginIdRef.current = event.target.value; // 입력값을 useRef에 저장
  };

  const handleBlur = () => {
    setContactLoginId(contactLoginIdRef.current); // 포커스가 벗어날 때 상태 업데이트
  };

  const handleMessageDetailChange = (event) => {
    messageDetailRef.current = event.target.value; // 메시지 내용을 useRef에 저장
  };

  const handleMessageBlur = () => {
    setMessageDetail(messageDetailRef.current); // 포커스가 벗어날 때 메시지 상태 업데이트
  };

  const findClientId = async () => {
    
    try {
      const res = await axios.get('https://api.midtrc.com/client',{
        headers: {
          'access-token': midBack?.log_data?.access_token || '', // 토큰이 없을 경우 기본값 처리
        }
      })
      const instData = res.data;
      const filterData = instData.filter((obj)=>obj.login_id === contactLoginId);
      console.log(filterData[0]?.id)
      const payload = {
        contact_client_id: filterData[0]?.id,
        group_type: groupType, // 그룹 타입 설정
        contact_type: selectedOption === '전체 회원' ? 'all' :
                      selectedOption === '일반' ? 'general' :
                      selectedOption === '사업자' ? 'business' :
                      selectedOption === '여성' ? 'female' :
                      selectedOption === '남성' ? 'male' : 'direct',
        content: messageDetailRef.current // 메시지 내용 전송
      };
  
      try {
        await axios.post('https://api.midtrc.com/managed_message', payload, {
          headers: {
            'access-token': midBack?.log_data?.access_token || '', // 토큰이 없을 경우 기본값 처리
          }
        });
        alert('메시지가 성공적으로 전송되었습니다.');
        modalManager(null); // 모달 닫기
      } catch (error) {
        console.error('Error sending message:', error);
        alert('메시지 전송 중 오류가 발생했습니다.');
        if (error.response && error.response.status === 403) {
          // 만약 토큰 문제가 있다면 처리 (예: 토큰 갱신 로직)
          alert('권한 문제가 발생했습니다. 다시 로그인해주세요.');
        }
      }
      
      
    } catch (error) {
      console.log(error)
    }
  }

  const handleSendMessage = async () => {
    if(selectedOption === '특정회원'){
      findClientId()
    }else{
      const payload = {
        
        contact_client_id: contactLoginId ? contactLoginId : 0,
        group_type: groupType, // 그룹 타입 설정
        contact_type: selectedOption === '전체 회원' ? 'all' :
                      selectedOption === '일반' ? 'general' :
                      selectedOption === '사업자' ? 'business' :
                      selectedOption === '여성' ? 'female' :
                      selectedOption === '남성' ? 'male' : 'direct',
        content: messageDetailRef.current // 메시지 내용 전송
      };
  
      try {
        await axios.post('https://api.midtrc.com/managed_message', payload, {
          headers: {
            'access-token': midBack?.log_data?.access_token || '', // 토큰이 없을 경우 기본값 처리
          }
        });
        alert('메시지가 성공적으로 전송되었습니다.');
        modalManager(null); // 모달 닫기
      } catch (error) {
        console.error('Error sending message:', error);
        alert('메시지 전송 중 오류가 발생했습니다.');
        if (error.response && error.response.status === 403) {
          // 만약 토큰 문제가 있다면 처리 (예: 토큰 갱신 로직)
          alert('권한 문제가 발생했습니다. 다시 로그인해주세요.');
        }
      }
    }



    
  };

  const ChoiceModalContent = () => {
    return (
      <div style={{ marginLeft: "-150px" }}>
        <div style={{ boxSizing: "border-box", padding: "20px 10px 10px 10px", display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
          <div>
            <div className='default-contents-border-title'>
              메시지 타입
            </div>
            <div className='default-contents-border-title'>
              발송대상
            </div>
            {selectedOption === '특정회원' && (
              <div className='default-contents-border-title'>
                특정회원 아이디
              </div>
            )}
            <div className='default-contents-border-title'>
              메시지 내용
            </div>
          </div>
          <div>
          <div>
              <select
                className='default-contents-border-select'
                value={groupType === 'manage' ? '관리자 메시지' : groupType === 'event' ? '이벤트 메시지' : '일반'}
                onChange={handleGroupTypeChange}
              >
                <option value="관리자 메시지">관리자 메시지</option>
      
                <option value="이벤트 메시지">이벤트 메시지</option>
              </select>
            </div>
            <div>
              <select className='default-contents-border-select' value={selectedOption} onChange={handleOptionChange}>
                <option value="전체 회원">전체 회원</option>
                <option value="일반">일반</option>
                <option value="사업자">사업자</option>
                <option value="여성">여성</option>
                <option value="남성">남성</option>
                <option value="특정회원">특정회원</option> {/* 특정회원 옵션 추가 */}
              </select>
            </div>
            {selectedOption === '특정회원' && (
              <div>
                <input
                  className="default-input-tag2"
                  defaultValue={contactLoginId} // useRef로 값을 추적하므로 defaultValue 사용
                  onChange={handleLoginIdChange}
                  onBlur={handleBlur} // 포커스가 벗어날 때 상태 업데이트
                  placeholder="특정회원 아이디를 입력하세요"
                />
              </div>
            )}
            <div style={{ marginTop: "10px" }}>
              <textarea
                className='default-contents-textarea'
                style={{ height: "400px" }}
                defaultValue={messageDetailRef.current} // 메시지 내용을 useRef로 관리
                onChange={handleMessageDetailChange}
                onBlur={handleMessageBlur} // 포커스가 벗어날 때 메시지 상태 업데이트
              ></textarea>
            </div>
          </div>
        </div>
        <div className='default-contents-border-confirm'>
          <button className='default-contents-border-confirm-button' onClick={() => { modalManager(null); }}>취 소</button>
          <button className='default-contents-border-confirm-button' style={{ background: "#183b5b", color: "#fff" }} onClick={handleSendMessage}>전 송</button>
        </div>
      </div>
    );
  };

  return (
    <div className="default-modal-border" style={{ height: "100vh" }} onClick={(e) => { e.stopPropagation(); modalManager(null); }}>
      <div className="members-detail-modal-border" onClick={(e) => { e.stopPropagation(); }} style={{ background: "#fff" }}>
        <div className="default-contents-border-top" style={{ width: "100%", justifyContent: "space-between", minHeight: "40px", backgroundColor: "white", borderRadius: "4px 4px 0px 0px" }}>
          <div>
            메시지 내용
          </div>
          <img
            onClick={() => { modalManager(null); }}
            src={closeButton} alt="close-box" style={{ width: "14px", height: "14px", cursor: "pointer" }}
          />
        </div>
        <div className="members-detail-modal-contents-border" style={{ background: "#fff" }}>
          <div>
            <ChoiceModalContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageAddModalPlus;
