import React from 'react'
import './AllianceDetail.css'
import closeButton from '../img/close-button.png'

const AllianceDetail = ({modalManager}) => {

  const AllianceDetailContent = () => {
    return(
      <div className='AllianceDetailInfoCover'>
      <div className='AllianceDetailInfoArea'>
        {/* 첫번째 탭 */}
        <div className='AllianceDetailInfoAreaTab'>
          <div className='default-contents-border-title' style={{justifyContent:"center"}}>업체명</div>
          <input readOnly={false} type="text" className="default-input-tag" value={''}/>
        </div>
        {/* 두번째 탭 */}
        <div className='AllianceDetailInfoAreaTab'>
          <div className='default-contents-border-title' style={{justifyContent:"center"}}>담당자 이름</div>
          <input readOnly={false} type="text" className="default-input-tag" value={''}/>
        </div>
        {/* 세번째 탭 */}
        <div className='AllianceDetailInfoAreaTab'>
          <div className='default-contents-border-title' style={{justifyContent:"center"}}>문의 내용</div>
          <textarea className='default-contents-textarea'></textarea>
        </div>
        {/* 네번째 탭 */}
        <div className='AllianceDetailInfoAreaTab'>
          <div className='default-contents-border-title' style={{justifyContent:"center"}}>담당자 연락처</div>
          <input readOnly={false} type="text" className="default-input-tag" value={''}/>
        </div>
      </div>
    </div>
    )
  }

  return(
    <div className="default-modal-border" style={{height: "100vh"}} onClick={(e)=>{e.stopPropagation(); modalManager(null)}}>
    <div className="members-detail-modal-border"  onClick={(e)=>{e.stopPropagation()}} style={{background:"#fff" , width:"50%",height:"50vh"}}>
        <div className="default-contents-border-top" style={{width: "100%", justifyContent: "space-between", minHeight: "40px", backgroundColor: "white", borderRadius: "4px 4px 0px 0px"}}>
            <div>
                제휴/입점 문의
            </div>
            <img 
            onClick={()=>{modalManager(null)}}
            src={closeButton} alt="close-box" style={{width: "14px", height: "14px", cursor: "pointer"}}
            />
        </div>
        <div className="members-detail-modal-contents-border" style={{background:"#fff"}}>
            <div>
              <AllianceDetailContent />
            </div>
        </div>
    </div>
  </div>
  )


}

export default AllianceDetail