import React from 'react'
import './SubBox1.css'
import { useNavigate } from 'react-router-dom'
import setting from '../img/side-bar/00-setting.png'
import setting2 from '../img/setting2.png'
import plusImg1 from '../img/plusImg1.png'

const SubBox1 = () => {



  let navigate = useNavigate(null);
  return (
    <div className="SubBox1">
      <div className="SubBox1_inner">
        {/* 최고 관리자 정보 탭 */}
        <div className="SubBox1Tab SubBox1Tab_1 selected" onClick={()=>{navigate('/ceo')}}>
          <div className="SubBox1Tab_img"><img src={setting} alt="setting" /></div>
          <p className="SubBox1_info">최고 관리자 정보</p>
        </div>
        {/* 매니저 관리 탭 */}
        <div className="SubBox1Tab SubBox1Tab_2" onClick={()=>{navigate('/management')}}>
          <div className='SubBox1Tab_2_cover'>
            <div className="SubBox1Tab_img"><img src={setting2} alt="setting2" /></div>
            <p className="SubBox1_info">매니저 관리</p>
          </div>
          <div className='more_icon'>
            <img src={plusImg1} alt='plusImg1' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubBox1