import React, { useEffect, useState } from 'react';
import './TerraceDetail.css';
import { useNavigate, useParams } from 'react-router-dom';
import profile from '../img/profile.png';
import visible_0 from '../img/choice-talk/visible_0.png';
import invisible_0 from '../img/choice-talk/invisible_0.png';
import imgex4 from '../img/terrace-detail/imgex4.png';
import good from '../img/terrace-detail/good.png';
import comment from '../img/terrace-detail/comment.png';

import axios from 'axios';
import { useSelector } from 'react-redux';

import SnsContentsPage from './6_community/sns-contents';
import NormalContentsPage from './6_community/community-post';
import SnsContentsBoard from './6_community/sns-contents-board'

const TerraceDetail = () => {
  let navigate = useNavigate();
  const { id } = useParams();  // URL에서 게시글 ID 가져오기
  const midBack = useSelector((state) => state.midBack);
  const [terraceData, setTerraceData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [visibility, setVisibility] = useState(visible_0);

  const toggleVisibility = () => {
    setVisibility(prevVisibility => (prevVisibility === visible_0 ? invisible_0 : visible_0));
  };

  useEffect(() => {
    const fetchTerraceDetail = async () => {
      try {
        const response = await axios.get(`https://api.midtrc.com/lounge/${id}`, {  // 원래 사용했던 API 경로 유지
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setTerraceData(response.data);

        const clientResponse = await axios.get(`https://api.midtrc.com/client/${response.data.client_id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setClientData(clientResponse.data);

      } catch (error) {
        console.error('Error fetching terrace detail:', error);
      }
    };

    if (midBack) {
      fetchTerraceDetail();
    }
  }, [midBack, id]);

  const getFormattedGender = (gender) => {
    return gender === 'male' ? '남' : '여';
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('정말 게시글을 삭제하시겠습니까?');
    if (confirmDelete) {
      try {
        await axios.delete(`https://api.midtrc.com/lounge/${id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        alert('게시글이 삭제되었습니다.');
        navigate('/terrace'); // 삭제 후 /terrace로 이동
      } catch (error) {
        console.error('Error deleting terrace:', error);
        alert('게시글 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const MyPageContentsBorder = () => {
    return (
      <div className="default-contents-border" style={{ marginBottom: "20px" }}>
        <div className="default-contents-border-top">
          작성자
        </div>
        <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "1fr 4.5fr 4.5fr", gap: "20px" }}>
          <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div><img src={clientData?.profile_url || profile} alt='profile' /></div>
            <div className='default-contents-border-title' style={{ justifyContent: "center" }}>{clientData?.nickname || '없음'}</div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                가입유형
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.client_type === 'user' ? '일반회원' : '사업자회원'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이름
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.name || '없음'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                연락처
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.phone_num || '없음'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이메일
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.email || '없음'} />
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
            <div className="default-contents-border-title">성별</div>
              <div style={{ position: "relative" }}>
              <input readOnly={true} className="default-input-line-tag" value={getFormattedGender(clientData?.gender)} />
              </div>
            </div>
            <div className='default-contents-border-confirm' style={{ padding: "0", marginTop: "70px" }}>
              <button className='default-contents-border-confirm-button'>더 보기</button>
            </div>
          </div>
        </div>
      </div>
    )
  };

  const TerractDetailMidTab = () => {
    return (
      <div className="default-contents-border" style={{ height: "50px", marginBottom: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px", marginBottom: "20px" }}>
        {/* 라운지 타입 선택 */}
        <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
          <div className="default-contents-border-title" style={{ justifyContent: "left", width: "auto", marginRight: "20px" }}>
            카테고리
          </div>
          <select 
            value={terraceData?.lounge_type} 
            className="default-contents-border-select" 
            style={{ borderBottom: "1px solid #dedede", textAlign: "center" }}
            disabled  // 수정 불가
          >
            <option value="sns">라운지 - SNS형</option>
            <option value="general">라운지 - 일반형</option>
          </select>
        </div>
  
        {/* 베스트픽 - 포토 */}
        <div className="default-contents-border-check-box-border" style={{ marginLeft: "20px", marginRight: "20px" }}>
          <div style={{ marginRight: "10px" }}>
            베스트픽 - 포토
          </div>
          <input 
            type="checkbox" 
            className="default-contents-border-check-box" 
            checked={terraceData?.status === 'best_pick_photo'} 
            disabled  // 수정 불가
          />
        </div>
  
        {/* 베스트픽 */}
        <div className="default-contents-border-check-box-border" style={{ marginRight: "20px" }}>
          <div style={{ marginRight: "10px" }}>
            베스트픽
          </div>
          <input 
            type="checkbox" 
            className="default-contents-border-check-box" 
            checked={terraceData?.status === 'best_pick'} 
            disabled  // 수정 불가
          />
        </div>
  
        {/* 핫이슈 */}
        <div className="default-contents-border-check-box-border" style={{ marginRight: "20px" }}>
          <div style={{ marginRight: "10px" }}>
            핫이슈
          </div>
          <input 
            type="checkbox" 
            className="default-contents-border-check-box" 
            checked={terraceData?.hot_issue} 
            disabled  // 수정 불가
          />
        </div>
  
        {/* 노출 상태 */}
        <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
          <div className="default-contents-border-title" style={{ justifyContent: "left", width: "auto", marginRight: "20px" }}>
            노출 상태
          </div>
          <img 
            src={terraceData?.status === 'paused' ? invisible_0 : visibility} 
            alt='visibility' 
            style={{ height: "20px", cursor: "not-allowed" }}  // 수정 불가
          />
        </div>
      </div>
    );
  };

  const DefaultContentsBorder = () => {
    return (
      <div className='default-contents-border'>
                  {/* 라운지 타입에 따른 컴포넌트 렌더링 */}
        {terraceData?.lounge_type === 'sns' ? (
          <SnsContentsPage />
        ) : terraceData?.lounge_type === 'general' ? (
          <NormalContentsPage />
        ) : null}
       
          {/* 버튼 리스트 */}
          <div className='default-contents-border-confirm'>
          <button className='default-contents-border-confirm-button' onClick={() => {navigate(`/terrace`)}}>취 소</button>
          <button 
            className='default-contents-border-confirm-button' 
            style={{background:"#ff0000", color:"#fff"}} 
            onClick={handleDelete}  // 삭제 버튼 클릭 시 삭제 함수 호출
          >
            삭 제
          </button>
            <button onClick={() => { navigate(`/terrace-update/${id}`) }} className='default-contents-border-confirm-button' style={{ background: "#183b5b", color: "#fff" }}>수 정</button>
          </div>
        </div>
    )
  }

  return (
    <div>
      <MyPageContentsBorder />
      <TerractDetailMidTab />
      <DefaultContentsBorder />
    </div>
  )
}

export default TerraceDetail;
