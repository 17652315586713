import React, { useEffect, useState } from 'react';
import './AnnoucementDetail.css';
import styled from "styled-components";
import { useParams, useNavigate } from 'react-router-dom';

import JobEditorReader from "./job-editor-reader";
import profile from '../img/profile.png';
import axios from 'axios';
import { useSelector } from 'react-redux';
import JobDetailPage from './@job-detail';

let MainRecruitImageContainer = styled.div`
  width: 100%;
  height: 300px;
  background: #f5f5f5;
`;

let SubRecruitImageContainer = styled.div`
  width: 100%;
  height: 300px;
  background: #f5f5f5;
  margin-top: 20px;
`;

let RecruitContentsBorder = styled.div`
  width: 100%;
  margin-top: 20px;
  background: #f5f5f5;
`;

let RecruitWorkSpaceBorder = styled.div`
  width: 100%;
  margin-top: 20px;
  background: #f5f5f5;
`;

let RecruitWorkSpaceDetailBorder = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
`;

let RecruitWorkSpaceDetail = styled.div`
  color: black;
`;

const AnnoucementDetail = () => {
  const { recruitid } = useParams();
  const navigate = useNavigate();
  const midBack = useSelector((state) => state.midBack);
  const [recruitData, setRecruitData] = useState(null);
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    const fetchRecruitDetail = async () => {
      try {
        const response = await axios.get(`https://api.midtrc.com/recruit/${recruitid}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setRecruitData(response.data);

        const clientResponse = await axios.get(`https://api.midtrc.com/client/${response.data.client_id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setClientData(clientResponse.data);

      } catch (error) {
        console.error('Error fetching recruit detail:', error);
      }
    };

    if (midBack) {
      fetchRecruitDetail();
    }
  }, [midBack, recruitid]);

  const MyPageContentsBorder = () => {
    return (
      <div className="default-contents-border" style={{ marginBottom: "20px" }}>
        <div className="default-contents-border-top">
          작성자
        </div>
        <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "1fr 4.5fr 4.5fr", gap: "20px" }}>
          <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div><img src={clientData?.profile_url || profile} alt='profile' /></div>
            <div className='default-contents-border-title' style={{ justifyContent: "center" }}>{clientData?.nickname || '없음'}</div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                가입유형
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} onChange={()=>{}} className="default-input-line-tag" value={clientData?.client_type === 'user' ? '일반회원' : '사업자회원'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이름
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} onChange={()=>{}} className="default-input-line-tag" value={clientData?.name || '없음'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                연락처
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} onChange={()=>{}} className="default-input-line-tag" value={clientData?.phone_num || '없음'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이메일
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} onChange={()=>{}} className="default-input-line-tag" value={clientData?.email || '없음'} />
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                업종
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} onChange={()=>{}} className="default-input-line-tag" value={clientData?.business_industry || '없음'} />
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  };

  const AnnoucementMidTab = () => {
    return (
      <div className="default-contents-border" style={{ height: "50px", marginBottom: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px", marginBottom: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="default-contents-border-title" style={{ justifyContent: "left", width: "auto", marginRight: "20px" }}>
            공고분류
          </div>
          <select className="default-contents-border-select" style={{ borderBottom: "1px solid #dedede", textAlign: "center" }} onChange={()=>{}} value={recruitData?.midnight_yn ? '미드나잇 알바' : recruitData?.recruit_type || '없음'}>
            <option value="midnight">미드나잇 알바</option>
            <option value="short_term">단기 알바</option>
            <option value="always">상시 채용</option>
            <option value="other">기타</option>
          </select>
        </div>
        <div className="default-contents-border-check-box-border" style={{ marginLeft: "20px", marginRight: "20px" }}>
          <div style={{ marginRight: "10px" }}>
            메인 노출하기
          </div>
          <input onChange={()=>{}} type="checkbox" className="default-contents-border-check-box" checked={recruitData?.popular_yn || false} />
        </div>
      </div>
    )
  };

  const DefaultContentsBorder = () => {
   
    return (
      <div className="default-contents-border" style={{ padding: "20px 10px 10px 10px" }}>
        <JobDetailPage/>
      </div>
    );
  };

  return (
    <div>
      <MyPageContentsBorder />
      <AnnoucementMidTab />
      <DefaultContentsBorder />
        

      
    </div>
  );
};

export default AnnoucementDetail;
