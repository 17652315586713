import React, { useEffect, useRef, useState } from 'react'
import './ChatDetailModal.css'
import imgex7 from '../img/imgex7.png'
import closeButton from '../img/close-button.png'
import styled from 'styled-components'
import OpenEyes from '../img/choice-talk/visible_0.png'
import CloseEyes from '../img/choice-talk/invisible_0.png'
import { useSelector } from 'react-redux'
import axios from 'axios'
import useWebSocket from 'react-use-websocket'

let ChoiceTopBorder = styled.div`
  width: 100%;
  min-height: 50px;
  max-height: auto;
  border-style: solid;
  border-color: #dedede;
  border-width: 0 0 1px 0;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
  font-size: 14px;
`

let ChoiceButton =styled.button`
    color: white;
    background-color: #dedede;
    padding: 0 10px;
    height: 40px;
    cursor: pointer;
    border-radius: 4px;
    width: 80%;
    &:hover{
        background-color: #444444;
    }
`

const ChatDetailModal = ({modalManager, data}) => {
  const midBack = useSelector((state) => state.midBack);
  let [expend, setExpend] = useState(false);
  let [messageData, setMessageData] = useState([]);
  let [explain, setExplain] = useState(false);
  let limitCount = 10;
  let [offsetCount, setOffsetCount] = useState(0);
  const [initialScroll, setInitialScroll] = useState(true);
  console.log(data)// 그룹 아이디

  // 오프셋 초기화
  useEffect(()=>{
    setOffsetCount(0)
    setInitialScroll(true);
  },[data])

  
  // 메시지 가져오기
  useEffect(() => {
    const fetchMessages = async () => {
        try {
            let response = await axios.get(`https://api.midtrc.com/talk/back?group_id=${data}&limit=${limitCount}&offset=${offsetCount}`, {
                headers: {
                    'access-token': midBack?.log_data?.access_token
                }
            });
            let newMessages = response.data;
            newMessages.reverse();
            newMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

            if (offsetCount === 0) {
                // `data`가 변경된 경우, 메시지를 초기화하고 새로 추가
                setMessageData(newMessages);
            } else {
                // 스크롤 상단에 도달한 경우, 기존 메시지에 새로운 메시지를 앞에 추가
                setMessageData((prevData) => {
                    const existingIds = new Set(prevData.map(message => message.id));
                    const filteredMessages = newMessages.filter(message => !existingIds.has(message.id));
                    return [...filteredMessages,...prevData];
                });
            }
            
        } catch (error) {
            console.log(error);
        }
    };

    if (data !== 0) {
        fetchMessages();
    }
    
  }, [offsetCount]);
  
  
  const accessToken = midBack?.log_data?.access_token;

  const socketUrl = `wss://api.midtrc.com/ws/${data}/client/${midBack?.log_data?.id}/talk?access_token=${accessToken}`;

  const {
    sendMessage,
    lastMessage,
    readyState,
  } = useWebSocket(socketUrl, {
    onOpen: () => {},
    onMessage: (message) => {
        const data = message.data;

        const group_name = data.split('group_name: ')[1]?.split(',')[0].trim();
        const user_name = data.split('user_name: ')[1]?.split(',')[0].trim();
        const content = data.split('content: ')[1]?.split(',')[0].trim();
        const original_created_at = data.split('created_at: ')[1]?.trim();

        if (content && user_name && group_name && original_created_at) {
            const createdAtDate = new Date(original_created_at);

            // 9시간 추가 (UTC+9)
            createdAtDate.setHours(createdAtDate.getHours() + 9);
            
            // 원하는 형식 (2024-08-24T11:35:44+00:00)으로 변환
            const formatted_created_at = createdAtDate.toISOString().replace('.000Z', '+00:00');

            const messageForm = {
                content: content,
                created_at: formatted_created_at,
                group_name: group_name,
                id: 'websocket',
                status: 'active',
                updated_at: formatted_created_at,
                user_name: user_name
            };

            setMessageData(prev => [...prev, messageForm]);
        }
      },
    onError: (error) => console.error('WebSocket error:', error),
    onClose: () => {},
  });

  useEffect(() => {
    if (readyState === WebSocket.OPEN) {
    // console.log('WebSocket is open');
    // 필요한 경우 메시지를 보낼 수 있습니다.
    // sendMessage('Your message here');
    }
  }, [readyState]);


  // 스크롤 최하단으로 이동
  const chatScrollRef = useRef();
  useEffect(() => {
      if (chatScrollRef.current && initialScroll) {
          chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
      }
  }, [ messageData, initialScroll]);

  // 스크롤 최상단 감지 및 offsetCount 증가
  const handleScroll = () => {
    setOffsetCount((prevCount) => prevCount + limitCount);
    setInitialScroll(false);
  };

  
  const ChoiceList = ({data}) => {
      let [isActive, setIsActive] = useState(data?.status);

      const talkStatusHandler = () => {
        let requestBody = {
            "before": isActive,
            "after": isActive === 'active'? 'paused':'active'
        }
        axios.put(`https://api.midtrc.com/talk/${data?.id}/status`,requestBody,{
            headers: {
                'access-token': midBack?.log_data?.access_token,
            }
        }).then((res)=>{modalManager(null); window.location.reload();}).then((error)=>{console.log(error)})
      }
      
      const Truncate = (str, maxLength = 30) => {
          if (str.length > maxLength) {
              return str.slice(0, maxLength) + '...';
          }
          return str;
      };

      const formatDateAndAdjustTime = (dateString) => {
          // 주어진 날짜 문자열을 Date 객체로 변환
          const date = new Date(dateString);
      
          // 9시간을 더함 (밀리초 단위)
          date.setHours(date.getHours());
      
          // 년, 월, 일, 시간, 분을 각각 추출
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
      
          // 원하는 형식으로 변환
          return `${year}.${month}.${day} ${hours}:${minutes}`;
      };

      return(
          <ChoiceTopBorder style={{display: 'grid', gridTemplateColumns: "1fr 3fr 1fr"}}>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%', color: "#a9a9a9"}}>
                  {formatDateAndAdjustTime(data?.created_at)}
              </div>
              <div style={{display: 'flex', justifyContent: 'left', width: '100%', color: "#a9a9a9"}}>
                  {data?.content}
              </div>
              <div style={{display: 'flex', justifyContent: 'center', width: '100%', color: "#a9a9a9"}}>
                <img onClick={()=>{talkStatusHandler(!isActive)}} style={{width: '20px', cursor: "pointer"}} src={isActive ==='active'?OpenEyes:CloseEyes} alt="eye"/>
              </div>
          </ChoiceTopBorder>
      )
  }  


    const ChatContent = () => {
      return(
        <div>
          <div style={{padding:"20px 10px 10px 10px", textAlign:"center"}}>
            {
              messageData?.map((a, i)=>(
                <ChoiceList data={a} key={i}/>
              ))
            }
          </div>
        </div>
      )
    }


    return(
      <div className="default-modal-border" style={{height: "100vh"}} onClick={(e)=>{e.stopPropagation(); modalManager(null)}}>
          <div className="members-detail-modal-border"  onClick={(e)=>{e.stopPropagation()}} style={{background:"#fff", width:"50%"}}>
              <div className="default-contents-border-top" style={{width: "100%", justifyContent: "space-between", minHeight: "40px", backgroundColor: "white", borderRadius: "4px 4px 0px 0px"}}>
                  <div>
                      {messageData[0]?.group_name}
                  </div>
                  <img 
                  onClick={()=>{modalManager(null)}}
                  src={closeButton} alt="close-box" style={{width: "14px", height: "14px", cursor: "pointer"}}
                  />
              </div>
              <div className="members-detail-modal-contents-border" ref={chatScrollRef} style={{background:"#fff"}}>
                  <div>
                    <ChatContent />
                  </div>
              </div>
              <button style={{minHeight: "50px", cursor: 'pointer'}} onClick={()=>{handleScroll()}}>
                더보기
              </button>
          </div>
        </div>
    )



  // return (
  //   <div className='ChatDetailModal'>
  //     <div className='ChatDetailModalCover'>
  //       <div className='ChatDetailModalArea'>
  //         <h2>가게명/지역명</h2>
  //         <div>
  //           <img src={imgex7} alt='imgex7' />
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default ChatDetailModal