import React, { useEffect, useState } from 'react';
import './TerraceUpdate.css'
import profile from '../img/profile.png'
import plus2 from '../img/plus2.svg'
import imgex2 from '../img/imgex2.png'
import visible_0 from '../img/choice-talk/visible_0.png';
import invisible_0 from '../img/choice-talk/invisible_0.png';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import ContentEditPage from './6_community/community-edit';

const TerraceUpdate = () => {
  let navigate = useNavigate();
  const { id } = useParams();  // URL에서 게시글 ID 가져오기
  const midBack = useSelector((state) => state.midBack);
  const [terraceData, setTerraceData] = useState(null);
  const [clientData, setClientData] = useState(null);
  const [visibility, setVisibility] = useState(visible_0);
  const [loungeType, setLoungeType] = useState('');
  const [status, setStatus] = useState('');
  const [hotIssue, setHotIssue] = useState(false);

  const toggleVisibility = () => {
    setVisibility(prevVisibility => (prevVisibility === visible_0 ? invisible_0 : visible_0));
  };

  useEffect(() => {
    const fetchTerraceDetail = async () => {
      try {
        const response = await axios.get(`https://api.midtrc.com/lounge/${id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        console.log('Terrace data:', response.data);  // 데이터 확인용 콘솔 로그
        setTerraceData(response.data);
        setLoungeType(response.data.lounge_type);
        setStatus(response.data.status);
        setHotIssue(response.data.hot_issue);
  
        const clientResponse = await axios.get(`https://api.midtrc.com/client/${response.data.client_id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setClientData(clientResponse.data);
      } catch (error) {
        console.error('Error fetching terrace detail:', error);
      }
    };
  
    if (midBack) {
      fetchTerraceDetail();
    }
  }, [midBack, id]);

  const handleLoungeTypeChange = (e) => {
    setLoungeType(e.target.value);
  };

  const handleStatusChange = (newStatus) => {
    if (newStatus === 'best_pick_photo') {
      setStatus('best_pick_photo');
    } else if (newStatus === 'best_pick') {
      setStatus('best_pick');
    } else if (newStatus === 'paused') {
      setStatus('paused');
    } else {
      setStatus('active');
    }
  };

  const handleHotIssueChange = () => {
    setHotIssue(!hotIssue);
  };

  const handleSave = async () => {
    try {
      // Lounge type 업데이트
      if (loungeType !== terraceData.lounge_type) {
        await axios.put(`https://api.midtrc.com/lounge/${id}/type`, {
          before: terraceData.lounge_type,
          after: loungeType,
        }, {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          }
        });
      }
  
      // Status 업데이트
      if (status !== terraceData.status) {
        await axios.put(`https://api.midtrc.com/lounge/${id}/status`, {
          before: terraceData.status,
          after: status,
        }, {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          }
        });
      }
  
      // Hot issue 업데이트
      if (hotIssue !== terraceData.hot_issue) {
        await axios.put(`https://api.midtrc.com/lounge/${id}`, {
          hot_issue: hotIssue,
        }, {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          }
        });
      }
  
      // 저장이 완료된 후 페이지 이동
      navigate(`/terrace-detail/${id}`);
    } catch (error) {
      console.error('Error during saving:', error.response?.data || error.message);
    }
  };

  const getFormattedGender = (gender) => {
    return gender === 'male' ? '남' : '여';
  };

  const MyPageContentsBorder = () => {
    return (
      <div className="default-contents-border" style={{ marginBottom: "20px" }}>
        <div className="default-contents-border-top">
          작성자
        </div>
        <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "1fr 4.5fr 4.5fr", gap: "20px" }}>
          <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div><img src={clientData?.profile_url || profile} alt='profile' /></div>
            <div className='default-contents-border-title' style={{ justifyContent: "center" }}>{clientData?.nickname || '없음'}</div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                가입유형
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.client_type === 'user' ? '일반회원' : '사업자회원'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이름
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.name || '없음'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                연락처
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.phone_num || '없음'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이메일
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} className="default-input-line-tag" value={clientData?.email || '없음'} />
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
            <div className="default-contents-border-title">성별</div>
              <div style={{ position: "relative" }}>
              <input readOnly={true} className="default-input-line-tag" value={getFormattedGender(clientData?.gender)} />
              </div>
            </div>
            <div className='default-contents-border-confirm' style={{ padding: "0", marginTop: "70px" }}>
              <button className='default-contents-border-confirm-button'>더 보기</button>
            </div>
          </div>
        </div>
      </div>
    )
  };

  const TerraceUpdateMidTab = () => {
    return (
      <div className="default-contents-border" style={{ height: "50px", marginBottom: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px" }}>
        {/* 라운지 타입 선택 */}
        <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
          <div className="default-contents-border-title" style={{ justifyContent: "left", width: "auto", marginRight: "20px" }}>
            카테고리
          </div>
          <select
            value={loungeType}
            className="default-contents-border-select"
            style={{ borderBottom: "1px solid #dedede", textAlign: "center" }}
            onChange={handleLoungeTypeChange}
          >
            <option value="sns">라운지 - SNS형</option>
            <option value="general">라운지 - 일반형</option>
          </select>
        </div>
  
        {/* 베스트픽 - 포토 */}
        <div className="default-contents-border-check-box-border" style={{ marginLeft: "20px", marginRight: "20px" }}>
          <div style={{ marginRight: "10px" }}>
            베스트픽 - 포토
          </div>
          <input
            type="checkbox"
            className="default-contents-border-check-box"
            checked={status === 'best_pick_photo'}
            onChange={() => handleStatusChange('best_pick_photo')}
          />
        </div>
  
        {/* 베스트픽 */}
        <div className="default-contents-border-check-box-border" style={{ marginRight: "20px" }}>
          <div style={{ marginRight: "10px" }}>
            베스트픽
          </div>
          <input
            type="checkbox"
            className="default-contents-border-check-box"
            checked={status === 'best_pick'}
            onChange={() => handleStatusChange('best_pick')}
          />
        </div>
  
        {/* 핫이슈 */}
        <div className="default-contents-border-check-box-border" style={{ marginRight: "20px" }}>
          <div style={{ marginRight: "10px" }}>
            핫이슈
          </div>
          <input
            type="checkbox"
            className="default-contents-border-check-box"
            checked={hotIssue}
            onChange={handleHotIssueChange}
          />
        </div>
  
        {/* 노출 상태 */}
        <div style={{ display: "flex", alignItems: "center", marginRight: "20px" }}>
          <div className="default-contents-border-title" style={{ justifyContent: "left", width: "auto", marginRight: "20px" }}>
            노출 상태
          </div>
          <img
            src={status === 'paused' ? invisible_0 : visible_0}
            alt='visibility'
            style={{ height: "20px", cursor: "pointer" }}
            onClick={() => handleStatusChange('paused')}
          />
        </div>
      </div>
    );
  };

  const DefaultContentsBorder = () => {
    return (
      <div className='TerraceUpdateCoverArea3'>
        <ContentEditPage />

        <div className='default-contents-border-confirm'>
          <button className='default-contents-border-confirm-button' onClick={() => { navigate(`/terrace-detail/${id}`) }}>취 소</button>
          <button onClick={handleSave} className='default-contents-border-confirm-button' style={{ background: "#183b5b", color: "#fff" }}>저 장</button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <MyPageContentsBorder />
      <TerraceUpdateMidTab />
      <DefaultContentsBorder />
    </div>
  );
}

export default TerraceUpdate;
