import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import logoimg from '../img/logo-banner/Isolation_Mode.svg';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/slice/login-slice';
import styled from 'styled-components';


let NavBorder = styled.div`
  width: 100%;
  height: 60px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 30;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 5px 10px;
`

let NavLogo = styled.img`
  cursor: pointer;
  height: 30px;
`

let NavButton = styled.button`
  width: 150px;
  height: 40px;
  border-radius: 4px;
  border-color: #dedede;
  border-width: 1px;
  border-style: solid;
  font-family: 'Noto Sans KR',sans-serif;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  &:hover{
  background-color: #444444;
  color: white;
  }
`

let LogoText = styled.div`
  font-family: 'Noto Sans KR',sans-serif;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 2px;
  color: #444444;
`

const Header = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout()); // 로그아웃 액션 디스패치
    navigate('/login'); // 로그인 페이지로 리디렉션
  };

  return (
      <NavBorder>
        <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
          <NavLogo alt='logo' src={logoimg}/>
          <LogoText>
            미드나잇 테라스
          </LogoText>
        </div>
        <NavButton onClick={handleLogout}>
          나가기
        </NavButton>
      </NavBorder>
  );
}

export default Header;
