import React from 'react'
import './ManagerDetailInfo.css'
import { Link } from 'react-router-dom'
import profile from '../img/profile.png'

const ManagerDetailInfo = () => {


  const MInfosodyd = () => {
    return(
      <div className='m_info_sodyd'>
    <div className='m_info_sodydemf'>
      <div className="m_sodyd_title">이메일</div>
      <input type='text' placeholder='abc@naver.com' readOnly/>
      <div className="m_sodyd_update"><Link to="#">수정</Link></div>
    </div>
    <div className='m_info_sodydemf'>
      <div className="m_sodyd_title">이름</div>
      <input type='text' placeholder='박지성' readOnly/>
      <div className="m_sodyd_update"><Link to="#">수정</Link></div>
    </div>
    <div className='m_info_sodydemf'>
      <div className="m_sodyd_title">연락처</div>
      <input type='text' placeholder='01099999999' readOnly/>
      <div className="m_sodyd_update"><Link to="#">수정</Link></div>
    </div>
    <div className='m_info_sodydemf'>
      <div className="m_sodyd_title">부서</div>
      <input type='text' placeholder='운영팀' readOnly/>
      <div className="m_sodyd_update"><Link to="#">수정</Link></div>
    </div>
    <div className='m_info_sodydemf'>
      <div className="m_sodyd_title">최종 로그인</div>
      <input type='text' placeholder='2023.10.28 17:23:22 (192.168.0.19)' readOnly/>
    </div>
    <div className='m_info_sodydemf'>
      <div className="m_sodyd_title">수정일</div>
      <input type='text' placeholder='2023.10.28 17:23:22 (192.168.0.19)' readOnly/>
    </div>
    <div className='m_info_sodydemf'>
      <div className="m_sodyd_title">비밀번호</div>
      <input type='text' placeholder='********' readOnly/>
    </div>
    <div className='m_info_sodydemf'>
      <div className="m_sodyd_title">비밀번호 확인</div>
      <input type='text' placeholder='********' readOnly/>
    </div>
    <div className='m_info_sodydemf'>
      <div className='m_sodyd_title'>권한</div>
      <div>
        <div className='check_list check_list_pl'>
          <label htmlFor='all_management'>모든 권한</label>
          <input type='checkbox' id='all_management' className='chk_list'/>
        </div>

        <div className='check_list_2'>
          <div className='check_list check_list_pl'>
            <label htmlFor='member_management'>회원 조회<span>(1:1문의 처리)</span></label>
            <input type='checkbox' id='member_management' className='chk_list'/>
          </div>
          <div className='check_list check_poa'>
            <label htmlFor='buy_management'>회원 편집</label>
            <input type='checkbox' id='buy_management' className='chk_list'/>
          </div>
        </div>

        <div className='check_list_2'>
          <div className='check_list'>
            <label htmlFor='input_management'>관리자 게시판 관리</label>
            <input type='checkbox' id='input_management' className='chk_list'/>
          </div>
          <div className='check_list check_poa'>
            <label htmlFor='inquiry_management'>메인페이지 컨텐츠 관리</label>
            <input type='checkbox' id='inquiry_management' className='chk_list'/>
          </div>
        </div>

        <div className='check_list_2'>
          <div className='check_list'>
            <label htmlFor='input_management'>초이스톡 관리</label>
            <input type='checkbox' id='input_management' className='chk_list'/>
          </div>
          <div className='check_list check_poa'>
            <label htmlFor='inquiry_management'>게시판 관리</label>
            <input type='checkbox' id='inquiry_management' className='chk_list'/>
          </div>
        </div>

        <div className='check_list check_list_no_mb'>
          <label htmlFor='post_management'>이력서/채용 공고 관리</label>
          <input type='checkbox' id='post_management' className='chk_list'/>
        </div>
      </div>
    </div>

    <div className='m_btn_list'>
      <button>저 장</button>
      <button>취 소</button>
    </div>

      </div>
    )
  }

  return (
    <div className='m_info'>
      {/* 타이틀 */}
      <h2 className='m_info_title'>계정관리</h2>
      {/* 프로필이미지 */}
      <div className="m_info_content">
        <div className="m_info_img">
          <img src={profile} alt="profile" />
        </div>
        <p className="m_info_name">Admin</p>
        <p className="m_name_line"></p>
      </div>
      {/* 내용 */}
      <div className='m_info_detail'>
        <MInfosodyd />
      </div>
    </div>
  )
}

export default ManagerDetailInfo