import React from 'react'
import './MManagement.css'
import { Link, useNavigate } from 'react-router-dom'
import search from '../img/search.png'
import PageArrowL from '../img/PageArrowL.png'
import PageArrowR from '../img/PageArrowR.png'

const MManagement = () => {
  let navigate = useNavigate(null);
  return (
    <div className='MManagement'>
      <div className='MManagement_cover'>
        {/* 검색 영역 */}
        <div className='MManagement_top'>
          <img src={search} alt='search' />
          <input type='text' className='manager_search' placeholder='검색'/>
          <div className='export_btn'>
            <button>내보내기</button>
          </div>
        </div>
        {/* 중간 영역 */}
        <div className='MManagement_mid'>
          <div className='MManagement_mid_left'>
            <button>전체선택</button>
            <p>2명</p>
          </div>
          <div className='MManagement_mid_right'>
            <button>삭제</button>
            <button>추가</button>
          </div>
        </div>
        {/* 테이블 */}
        <div className='MManagement_bot'>
          <table>
            <thead>
              <tr>
                <th><input type='checkbox' /></th>
                <th>번호</th>
                <th>이메일</th>
                <th>이름</th>
                <th>부서</th>
                <th>권한</th>
                <th>최종 로그인</th>
                <th>상세보기</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><input type='checkbox' /></td>
                <td>1</td>
                <td>park@naver.com</td>
                <td>박지성</td>
                <td>운영팀</td>
                <td>회원 보기/편집, 관리자게시판 편집,(일부)게시판 편집</td>
                <td>2023.09.17</td>
                <td onClick={()=>{navigate('/manager-detail')}}>상세보기</td>
              </tr>
              <tr>
                <td><input type='checkbox' /></td>
                <td>2</td>
                <td>son@naver.com</td>
                <td>손흥민</td>
                <td>CS팀</td>
                <td>회원 조회</td>
                <td>2023.09.17</td>
                <td onClick={()=>{navigate('/manager-detail')}}>상세보기</td>
              </tr>
            </tbody>
          </table>
          <div className='pagenation'>
            <ul>
              <li><Link to="#"><img src={PageArrowL} alt='PageArrowL' /></Link></li>
              <li><Link to="#">1</Link></li>
              <li><Link to="#">2</Link></li>
              <li><Link to="#">3</Link></li>
              <li><Link to="#">4</Link></li>
              <li><Link to="#">5</Link></li>
              <li><Link to="#"><img src={PageArrowR} alt='PageArrowR' /></Link></li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  )
}

export default MManagement