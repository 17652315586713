import React, { useEffect, useState } from 'react'
import './DashBoard.css'
import profile from '../img/profile.png'
import imgex6 from '../img/dash-board/imgex6.png'
import ChartApi from './ChartApi'
import axios from 'axios'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

const DashBoard = () => {
  const midBack = useSelector((state) => state.midBack);
  const navigate = useNavigate();
  const [todayCount, setTodayCount] = useState(0);
  const [dateCount, setDateCount] = useState();
  let [dayByDay,setDayByDay] = useState();
  let [todayData, setTodayData] = useState();

  let [todayCounsel, setTodayCounsel] = useState();
  let [todayLounge, setTodayLounge] = useState();

  // 방문자 현황
  useEffect(()=>{
    axios.get(`https://api.midtrc.com/visit`).then((res)=>{
     
      let instData = res.data;

      setDateCount(instData);


      let today = new Date().toISOString().split('T')[0]; // 오늘 날짜를 'YYYY-MM-DD' 형식으로 가져오기

      let todayData = instData.find(item => item.visit_date === today); // 오늘 날짜에 해당하는 데이터를 찾기
      if (todayData) {
        setTodayCount(todayData.count); // 오늘 날짜의 count 값을 state에 저장
      } else {
        setTodayCount(0); // 오늘 날짜의 데이터가 없을 경우 count를 0으로 설정
      }

    }).catch((error)=>{})
  },[]);


  // 일자별 요약 
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 오늘 날짜를 YYYY-MM-DD 형식으로 가져오기
        const endDate = dayjs().format('YYYY-MM-DD');
        // 7일 전 날짜를 YYYY-MM-DD 형식으로 가져오기
        const startDate = dayjs().subtract(6, 'day').format('YYYY-MM-DD');

        let res = await axios.get(`https://api.midtrc.com/dashboard/summary?start_date=${startDate}&end_date=${endDate}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          }
        });

        let instData = res.data.reverse();
        setDayByDay(instData)
        setTodayData(instData[0])
        console.log(instData[0]);

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [midBack]);

  // 신규문의
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 오늘 날짜를 YYYY-MM-DD 형식으로 가져오기
        const today = dayjs().format('YYYY-MM-DD');

        let res = await axios.get(`https://api.midtrc.com/counsel`, {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          }
        });

        // 데이터를 역순으로 정렬
        let instData = res.data.reverse();

        // 오늘 날짜와 created_at이 일치하는 항목만 필터링
        let filteredData = instData.filter(item => dayjs(item.created_at).format('YYYY-MM-DD') === today);

        setTodayCounsel(filteredData);

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [midBack]);

  // 오늘 게시글
  useEffect(() => {
    const fetchData = async () => {
      try {
        // 오늘 날짜를 YYYY-MM-DD 형식으로 가져오기
        const today = dayjs().format('YYYY-MM-DD');

        let res = await axios.get(`https://api.midtrc.com/lounge`, {
          headers: {
            'access-token': midBack?.log_data?.access_token,
          }
        });

        // 데이터를 역순으로 정렬
        let instData = res.data.reverse();

        // 오늘 날짜와 created_at이 일치하는 항목만 필터링
        let filteredData = instData.filter(item => dayjs(item.created_at).format('YYYY-MM-DD') === today);

        setTodayLounge(filteredData);

      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [midBack]);




  const Area1 = () => {
    return (
      <div className='DashBoardCoverArea'>
      {/* Todo 제목 */}
      <div>
        <div className='DashBoardCoverAreaTitle'>
          <h2 className='default-contents-border-top'>오늘 현황</h2>
          {/* <button><span>0</span></button> */}
        </div>
      </div>
      {/* 신규 문의 신고글 */}
      <div>
        <div className='DashBoardCoverAreaBot'>
          방문자 수<span>{todayData?.summary_count?.visit}</span>
        </div>
        <div className='DashBoardCoverAreaBot' onClick={()=>{navigate('/declaration')}}>
          신고글<span>{todayData?.summary_count?.complain}</span>
        </div>
        <div className='DashBoardCoverAreaBot' onClick={()=>{navigate('/one-inquiry')}}>
          문의<span>{todayCounsel?.length}</span> 
          {/* {todayData?.summary_count?.counsel}  */}
        </div>
      </div>
      </div>
    )
  }

  const Area2 = () => {
    return(
      <div className='DashBoardCoverArea2'>
        <div>
          <div className='DashBoardCoverArea2Title'>
            <h2 className='default-contents-border-top'>방문자 현황</h2>
            <div>
              <button>{todayCount}</button>
              <p>방문자</p>
            </div>
          </div>
        </div>
        <div className='charts'>
          <ChartApi date={dateCount}/>
        </div>
      </div>
    )
  }

  const Area3 = () => {
    return(
      <div className='DashBoardCoverArea3'>
        {/* 타이틀 */}
        <div>
          <div className='DashBoardCoverArea3Title'>
            <h2 className='default-contents-border-top'>일자별 요약</h2>
            <p>월별 통계</p>
          </div>
        </div>
        {/* 컨텐츠 테이블 */}
        <div className='DashBoardCoverArea3Content'>
          <table>
            <thead>
              <tr>
                <th>일자</th>
                <th>방문자 수</th>
                <th>가입 수</th>
                <th>게시글</th>
                <th>공고</th>
                <th>이력서</th>
              </tr>
            </thead>
            <tbody>
              {
                dayByDay?.map((a, i)=>(
                  <tr key={i}>
                    <td>{a.summary_date}</td>
                    <td>{a.summary_count.visit}</td>
                    <td>{a.summary_count.sign_up}</td>
                    <td>{a.summary_count.lounge}</td>
                    <td>{a.summary_count.recruit}</td>
                    <td>{a.summary_count.resume}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  const Area4 = () => {

    return(
      <div className='DashBoardCoverArea4'>
        {/* 상단 */}
        <div>
          <div className='DashBoardCoverArea4Title'>
            <h2 className='default-contents-border-top'>로그인 계정</h2>
            {/* <p>로그아웃</p> */}
          </div>
        </div>
        <div style={{padding:"20px 10px 10px 10px"}}>
          {/* 중간 */}
          <div style={{textAlign:"center",marginBottom:"20px"}}>
            <img src={profile} alt='profile' />
            <p style={{fontSize:"14px", color:"#a9a9a9", marginBottom:"20px"}}>매니저</p>
            <p>{midBack?.log_data?.email}</p>
          </div>
            {/* 첫번째 탭 */}
            <div className='DashBoardCoverArea4Tab'>
              <div className='default-contents-border-title'>이름</div>
              <div className='DashBoardCoverArea4TabRight'>
                <div style={{position: "relative"}}>
                  <input readOnly={true} type="text" className="default-input-line-tag" value={midBack?.log_data?.name}/>
                  {/* <div className="default-input-tag-edit">
                      수정
                  </div> */}
                </div>
              </div>
            </div>
            {/* 두번째 탭 */}
            <div className='DashBoardCoverArea4Tab'>
              <div className='default-contents-border-title'>연락처</div>
              <div className='DashBoardCoverArea4TabRight'>
                <div style={{position: "relative"}}>
                  <input readOnly={true} type="text" className="default-input-line-tag" value={midBack?.log_data?.phone_num}/>
                  {/* <div className="default-input-tag-edit">
                      수정
                  </div> */}
                </div>
              </div>
            </div>
            {/* 세번째 탭 */}
            <div className='DashBoardCoverArea4Tab'>
              <div className='default-contents-border-title'>권한</div>
              <div className='DashBoardCoverArea4TabRight'>
                <div style={{position: "relative"}}>
                  <input readOnly={true} type="text" className="default-input-line-tag" value={midBack?.log_data?.client_type === 'admin'? '최고 관리자':'메니져'}/>
                  {/* <div className="default-input-tag-edit">
                      수정
                  </div> */}
                </div>
              </div>
            </div>
        </div>
      </div>
    )
  }

  const Area5 = () => {

    const List = ({data}) => {
      const truncatedContent = data?.content?.length > 10 ? data?.content?.substring(0, 10) + '...' : data?.content;
      const formattedDate = dayjs(data?.created_at).add(9, 'hour').format('YYYY.MM.DD HH:mm:ss');

      return(
        <div className='DashBoardCoverArea5TabContent' style={{cursor: 'pointer'}}>
          <div>
            <p>[1:1문의 게시판] {truncatedContent}</p>
            <div>
              <span>{data.login_id}</span>
              <span>/</span>
              <span>{formattedDate}</span>
            </div>
          </div>
        </div>
      )
    }

    return(
      <div className='DashBoardCoverArea5'>
            {/* 제목 */}
            <div>
              <div className='DashBoardCoverArea5Top'>
                <div>
                  <h2 className='default-contents-border-top'>오늘 문의</h2>
                  <button><span>{todayCounsel?.length}</span></button>
                </div>
                <p onClick={()=>{navigate('/one-inquiry')}}>전체보기</p>
              </div>
            </div>
            {/* 컨텐츠 */}
            <div className='DashBoardCoverArea5Tab'>
              <div style={{padding:"20px 10px 10px 10px"}}>
                {/* 컨텐츠 내용 첫번쨰 탭 */}
                {
                  todayCounsel?.map((a, i)=>(
                    <List key={i} data={a} />
                  ))
                }
              </div>
            </div>
      </div>
    )
  }

  const Area6 = () => {

    const ListLounge = ({data}) => {
      const truncatedContent = data?.lounge_title?.length > 10 ? data?.lounge_title.substring(0, 10) + '...' : data?.lounge_title;
      const formattedDate = dayjs(data?.created_at).add(9, 'hour').format('YYYY.MM.DD HH:mm:ss');

      return(
        <div className='DashBoardCoverArea6TabContent'>
          <div>
            <p>[라운지] {truncatedContent}</p>
            <div>
              <span>{data?.nickname}</span>
              <span>/</span>
              <span>{formattedDate}</span>
            </div>
          </div>
        </div>
      )
    }


    return(
      <div className='DashBoardCoverArea6'>
            {/* 제목 */}
            <div>
              <div className='DashBoardCoverArea6Top'>
                <div>
                  <h2 className='default-contents-border-top'>오늘 게시글</h2>
                  <button><span>{todayLounge?.length}</span></button>
                </div>
                <p onClick={()=>{navigate('/community')}}>전체보기</p>
              </div>
            </div>
            {/* 컨텐츠 */}
            <div className='DashBoardCoverArea6Tab'>
              <div style={{padding:"20px 10px 10px 10px"}}>
                {/* 컨텐츠 내용 첫번쨰 탭 */}
                {
                  todayLounge?.map((a, i)=>(
                    <ListLounge key={i} data={a}/>
                  ))
                }
              </div>
            </div>
      </div>
    )
  }

  const Area7 = () => {
    return(
      <div className='DashBoardCoverArea7'>
            {/* 제목 */}
            <div>
              <div className='DashBoardCoverArea7Top'>
                <div>
                  <h2 className='default-contents-border-top'>오늘 신고</h2>
                  <button><span>0</span></button>
                </div>
                <p>전체보기</p>
              </div>
            </div>
            {/* 컨텐츠 */}
            <div className='DashBoardCoverArea7Tab'>
              <div style={{padding:"20px 10px 10px 10px"}}>
                {/* 컨텐츠 내용 첫번쨰 탭 */}
                <div className='DashBoardCoverArea7TabContent'>
                  <div>
                    <p>[신고] 신고 내용 표기</p>
                    <div>
                      <span>작성자 ID</span>
                      <span>/</span>
                      <span>2023-11-23 12:39</span>
                    </div>
                  </div>
                </div>
                {/* 컨텐츠 내용 두번쨰 탭 */}
                <div className='DashBoardCoverArea7TabContent'>
                  <div>
                    <p>[신고] 신고 내용이 표기됩니다</p>
                    <div>
                      <span>작성자 ID</span>
                      <span>/</span>
                      <span>2023-11-23 12:39</span>
                    </div>
                  </div>
                </div>
                {/* 컨텐츠 내용 세번쨰 탭 */}
                <div className='DashBoardCoverArea7TabContent'>
                  <div>
                    <p>[신고] 신고 내용이 표기됩니다</p>
                    <div>
                      <span>작성자 ID</span>
                      <span>/</span>
                      <span>2023-11-23 12:39</span>
                    </div>
                  </div>
                </div>
                {/* 컨텐츠 내용 네번쨰 탭 */}
                <div className='DashBoardCoverArea7TabContent'>
                  <div>
                    <p>[신고] 신고 내용이 표기됩니다</p>
                    <div>
                      <span>작성자 ID</span>
                      <span>/</span>
                      <span>2023-11-23 12:39</span>
                    </div>
                  </div>
                </div>
                {/* 컨텐츠 내용 다섯번쨰 탭 */}
                <div className='DashBoardCoverArea7TabContent'>
                  <div>
                    <p>[신고] 신고 내용이 표기됩니다</p>
                    <div>
                      <span>작성자 ID</span>
                      <span>/</span>
                      <span>2023-11-23 12:39</span>
                    </div>
                  </div>
                </div>
                {/* 컨텐츠 내용 여섯번쨰 탭 */}
                <div className='DashBoardCoverArea7TabContent'>
                  <div>
                    <p>[신고] 신고 내용이 표기됩니다</p>
                    <div>
                      <span>작성자 ID</span>
                      <span>/</span>
                      <span>2023-11-23 12:39</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    )
  }

  const Area8 = () => {
    return(
      <div className='DashBoardCoverArea8'>
            {/* 제목 */}
            <div>
              <div className='DashBoardCoverArea8Top'>
                <div>
                  <h2 className='default-contents-border-top'>신규 공고</h2>
                  <button><span>0</span></button>
                </div>
                <p>전체보기</p>
              </div>
            </div>
            {/* 컨텐츠 */}
            <div className='DashBoardCoverArea8Tab'>
              <div style={{padding:"20px 10px 10px 10px"}}>
                {/* 컨텐츠 내용 첫번쨰 탭 */}
                <div className='DashBoardCoverArea8TabContent'>
                  <div>
                    <div>
                      <img src={imgex6} alt='imgex6' />
                    </div>
                    <div>
                      <p>[공고] 공고 제목이 표기됩니다</p>
                      <div>
                        <span>사업자명</span>
                        <span>/</span>
                        <span>2023-11-23 12:39</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 컨텐츠 내용 두번쨰 탭 */}
                <div className='DashBoardCoverArea8TabContent'>
                  <div>
                    <div>
                      <img src={imgex6} alt='imgex6' />
                    </div>
                    <div>
                      <p>[공고] 공고 제목이 표기됩니다</p>
                      <div>
                        <span>사업자명</span>
                        <span>/</span>
                        <span>2023-11-23 12:39</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 컨텐츠 내용 세번쨰 탭 */}
                <div className='DashBoardCoverArea8TabContent'>
                  <div>
                    <div>
                      <img src={imgex6} alt='imgex6' />
                    </div>
                    <div>
                      <p>[공고] 공고 제목이 표기됩니다</p>
                      <div>
                        <span>사업자명</span>
                        <span>/</span>
                        <span>2023-11-23 12:39</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 컨텐츠 내용 네번쨰 탭 */}
                <div className='DashBoardCoverArea8TabContent'>
                  <div>
                    <div>
                      <img src={imgex6} alt='imgex6' />
                    </div>
                    <div>
                      <p>[공고] 공고 제목이 표기됩니다</p>
                      <div>
                        <span>사업자명</span>
                        <span>/</span>
                        <span>2023-11-23 12:39</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 컨텐츠 내용 다섯번쨰 탭 */}
                <div className='DashBoardCoverArea8TabContent'>
                  <div>
                    <div>
                      <img src={imgex6} alt='imgex6' />
                    </div>
                    <div>
                      <p>[공고] 공고 제목이 표기됩니다</p>
                      <div>
                        <span>사업자명</span>
                        <span>/</span>
                        <span>2023-11-23 12:39</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 컨텐츠 내용 여섯번쨰 탭 */}
                <div className='DashBoardCoverArea8TabContent'>
                  <div>
                    <div>
                      <img src={imgex6} alt='imgex6' />
                    </div>
                    <div>
                      <p>[공고] 공고 제목이 표기됩니다</p>
                      <div>
                        <span>사업자명</span>
                        <span>/</span>
                        <span>2023-11-23 12:39</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
    )
  }


  return(
    <div style={{display:"grid", gridTemplateColumns:"7fr 3fr" , gap:"20px"}}>
      <div>
        {/* 첫번쨰 */}
        <Area1 />
        <div style={{display:"grid" , gridTemplateColumns:"1fr 1fr", gap:"20px"}}>
          {/* 두번쨰 */}
          <Area2 />
          {/* 세번쨰 */}
          <Area3 />
          {/* 다섯번쨰 */}
          <Area5 />
          {/* 여섯번쨰 */}
          <Area6 />
          {/* 일곱번쨰 */}
          {/* <Area7 /> */}
          {/* 여덟번쨰 */}
          {/* <Area8 /> */}
        </div>
      </div>
      <div>
        {/* 네번쨰 */}
        <Area4 />
      </div>
    </div>
  )



}

export default DashBoard