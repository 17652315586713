import React from 'react'
import './DeclarationPost.css'
import DeclarationSubBox from './DeclarationSubBox'
import DeclarationPostInfo from './DeclarationPostInfo'

const DeclarationPost = () => {
  return (
    <div className='DeclarationPost'>
      <div className='DeclarationPostCover'>
        <DeclarationSubBox />
        <DeclarationPostInfo />
      </div>
    </div>
  )
}

export default DeclarationPost