import React, { useEffect, useState } from 'react';
import './AnnoucementUpdate.css';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import profile from '../img/profile.png';
import axios from 'axios';
import JobUpdatePage from "./@job-edit";

const AnnoucementUpdate = () => {
  let navigate = useNavigate();
  const { recruitid } = useParams();
  const midBack = useSelector((state) => state.midBack);
  const [recruitData, setRecruitData] = useState(null);
  const [clientData, setClientData] = useState(null);

  useEffect(() => {
    const fetchRecruitDetail = async () => {
      try {
        const response = await axios.get(`https://api.midtrc.com/recruit/${recruitid}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setRecruitData(response.data);

        const clientResponse = await axios.get(`https://api.midtrc.com/client/${response.data.client_id}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        setClientData(clientResponse.data);

      } catch (error) {
        console.error('Error fetching recruit detail:', error);
      }
    };

    if (midBack) {
      fetchRecruitDetail();
    }
  }, [midBack, recruitid]);

  const handleDelete = async () => {
    if (window.confirm('정말 공고를 삭제하시겠습니까?')) {
      try {
        await axios.delete(`https://api.midtrc.com/recruit/${recruitid}`, {
          headers: {
            'access-token': midBack?.log_data?.access_token
          }
        });
        alert('공고가 삭제되었습니다.');
        navigate('/announcement');
      } catch (error) {
        console.error('Error deleting recruit:', error);
        alert('공고 삭제 중 오류가 발생했습니다.');
      }
    }
  };

  const MyPageContentsBorder = () => {
    return (
      <div className="default-contents-border" style={{ marginBottom: "20px" }}>
        <div className="default-contents-border-top">
          작성자
        </div>
        <div style={{ boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "1fr 4.5fr 4.5fr", gap: "20px" }}>
          <div style={{ textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <div><img src={clientData?.profile_url || profile} alt='profile' /></div>
            <div className='default-contents-border-title' style={{ justifyContent: "center" }}>{clientData?.nickname || '없음'}</div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                가입유형
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} onChange={() => { }} className="default-input-line-tag" value={clientData?.client_type === 'user' ? '일반회원' : '사업자회원'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이름
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} onChange={() => { }} className="default-input-line-tag" value={clientData?.name || '없음'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                연락처
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} onChange={() => { }} className="default-input-line-tag" value={clientData?.phone_num || '없음'} />
              </div>
            </div>
            <div style={{ display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                이메일
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} onChange={() => { }} className="default-input-line-tag" value={clientData?.email || '없음'} />
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: "grid", gridTemplateColumns: "1fr 9fr", gap: "20px" }}>
              <div className="default-contents-border-title">
                업종
              </div>
              <div style={{ position: "relative" }}>
                <input readOnly={true} onChange={() => { }} className="default-input-line-tag" value={clientData?.business_industry || '없음'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };

  const AnnoucementMidTab = () => {
    return (
      <div className="default-contents-border" style={{ height: "50px", marginBottom: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", paddingRight: "10px", marginBottom: "20px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="default-contents-border-title" style={{ justifyContent: "left", width: "auto", marginRight: "20px" }}>
            공고분류
          </div>
          <select className="default-contents-border-select" style={{ borderBottom: "1px solid #dedede", marginRight: "20px", textAlign: "center" }}>
            <option>단기 알바</option>
            <option>상시채용</option>
          </select>
        </div>
        <div className="default-contents-border-title" style={{ justifyContent: "left", width: "auto", marginLeft: "20px", marginRight: "20px" }}>
          미드나잇알바
        </div>
        <select className="default-contents-border-select" style={{ borderBottom: "1px solid #dedede", textAlign: "center" }}>
          <option>활성</option>
          <option>비활성</option>
        </select>

        <div className="default-contents-border-title" style={{ justifyContent: "left", width: "auto", marginLeft: "20px", marginRight: "20px" }}>
          인기공고
        </div>
        <select className="default-contents-border-select" style={{ borderBottom: "1px solid #dedede", textAlign: "center" }}>
          <option>활성</option>
          <option>비활성</option>
        </select>
      </div>
    )
  }

  const DefaultContentsBorder = () => {
    return (
      <div className='default-contents-border' style={{ padding: "20px 10px 10px 10px" }}>
        <JobUpdatePage />
        <div className='default-contents-border-confirm'>
          <button className='default-contents-border-confirm-button' onClick={() => navigate(`/announcement-detail/${recruitid}`)}>취 소</button>
          <button className='default-contents-border-confirm-button' style={{ background: "#ff0000", color: "#fff" }} onClick={handleDelete}>삭 제</button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <MyPageContentsBorder />
      <DefaultContentsBorder />
    </div>
  )
}

export default AnnoucementUpdate;
