import React, { useState } from 'react'
import './BestPick.css'
import { useNavigate } from 'react-router-dom'
import imgex3 from '../img/annoucement/imgex3.png'

const BestPick = () => {
  let [clickedIndex, setClickedIndex] = useState('베스트 PICK');
  let navigate = useNavigate(null)

    // 공통 인덱스
    const MyPageIndexBorder = () => {
    
      const IndexButton = ({name, url}) => {
          return(
            <div>
              {
                clickedIndex === name?
                <div className="default-index-border-button" style={{backgroundColor: "#EDF5FF"}} onClick={()=> {setClickedIndex(name); navigate(url)}}>
                    {name}
                </div>
                :
                <div className="default-index-border-button" onClick={()=> {setClickedIndex(name); navigate(url)}}>
                    {name}
                </div>
              }
            </div>
          )
      }
      return(
          <div className="default-index-border" style={{paddingBottom: "5px"}}>
              <IndexButton name={'테라스 라운지'} url={'/community'}/>
              <IndexButton name={'일반 게시판'}/>
              <IndexButton name={'베스트 PICK'} url={'/best-pick'}/>
          </div>
      )
    }


  // 베스트 픽
  const DefaultPagenation = () => {
    return(
      <div style={{display: "flex", height: "50px", justifyContent: "center", alignItems: "center", gap: "10px"}}>
          <div>
              
          </div>
          <div className="default-contents-pagenation-button">
              1
          </div>
          <div className="default-contents-pagenation-button">
              2
          </div>
          <div className="default-contents-pagenation-button">
              3
          </div>
          <div className="default-contents-pagenation-button">
              4
          </div>
          <div>
              
          </div>
      </div>
    )
  }

    const BestPickList = () => {
    return(
      <div className="default-contents-border-top" 
            style={{fontSize: "14px", 
            color: "black",
            height: "50px",
            display: "grid",
            gridTemplateColumns: "0.3fr 0.5fr 2fr 4fr 1fr 1fr 1fr"
            }}>
              <input type="checkbox" className="default-contents-border-check-box"/>
              <div style={{width: "100%", textAlign: "center"}}>
                  번호
              </div>
              <div style={{width: "100%", textAlign: "center"}}>
                  <img src={imgex3} alt='imgex3' style={{height:"46px", paddingTop:"4px"}} />
              </div>
              <div style={{width: "100%", textAlign: "center"}}>
                  제목표기
              </div>
              <div style={{width: "100%", textAlign: "center"}}>
                  베스트픽
              </div>
              <div style={{width: "100%", textAlign: "center"}}>
                  가입일자
              </div>
              <div onClick={()=>{navigate('update')}} style={{width: "100%", textAlign: "center", color: "blue", cursor: "pointer"}}>
                  상세보기
              </div>
      </div>
    )
  }

  const DefaultContentsBorder = () => {
    return (
      <div className="default-contents-border">
        <div className="default-contents-border-top" 
        style={{fontSize: "14px", 
        height: "50px",
        display: "grid",
        gridTemplateColumns: "0.3fr 0.5fr 2fr 4fr 1fr 1fr 1fr"
        }}>
            <input type="checkbox" className="default-contents-border-check-box"/>
            <div style={{width: "100%", textAlign: "center"}}>
                번호
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                이미지
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                텍스트 내용
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                분류
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                작성 일자
            </div>
            <div style={{width: "100%", textAlign: "center"}}>
                상세보기
            </div>
        </div>
        <BestPickList />
        <BestPickList />
        <BestPickList />
        <BestPickList />
        <BestPickList />
        <BestPickList />
        <BestPickList />
        <BestPickList />
        <BestPickList />
        <BestPickList />
        <BestPickList />
        <BestPickList />
        <DefaultPagenation />
    </div>
    )
  }


  return(
    <div style={{display:"grid", gridTemplateColumns:"2fr 8fr",gap:"20px"}}>
      <div>
        <MyPageIndexBorder />
      </div>
      <div>
        <div style={{display: "grid", gridTemplateColumns: "9fr 1fr", gap: "20px", marginBottom: "20px"}}>
          <div className="default-contents-border" style={{height: "50px", position: "relative"}}>
              <input placeholder="검색어를 입력하세요" style={{background: "none", outline: "none", border: "none", width: "100%", height: "100%", fontWeight: "400", fontFamily: "'Noto Sans KR', sans-serif", fontSize: "14px", boxSizing: "border-box", paddingLeft:"10px"}}/>
              <button className="members-contents-button" style={{position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)"}}>
                  검색
              </button>
          </div>
          <div className="members-contents-top-button">
              내보내기
          </div>
        </div>
        <div className="default-contents-border" style={{height: "50px", marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center",paddingLeft: "10px", paddingRight: "10px"}}>
            <div style={{display: "flex", alignItems: "center"}}>
                <button className="members-contents-button">
                    전체선택
                </button>
                <div style={{fontSize: "14px", fontWeight: "600", color: "#a9a9a9", marginLeft: "20px"}}>
                    115건
                </div>
                <input readOnly={true} className="default-input-tag" value={'베스트픽'} style={{width:"auto",marginLeft:"20px"}} />
            </div>
            <div style={{display: "flex", gap: "10px"}} >
                <button className="members-contents-button" style={{backgroundColor: "red", color: "white"}}>
                    삭제
                </button>
                <button className="members-contents-button" style={{backgroundColor: "#183B5B", color: "white"}}>
                    추가
                </button>
            </div>
        </div>
        <DefaultContentsBorder />
      </div>
    </div>
  )


}

export default BestPick