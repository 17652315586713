import React from 'react'
import './DeclarationChat.css'
import DeclarationSubBox from './DeclarationSubBox'
import DeclarationChatInfo from './DeclarationChatInfo'

const DeclarationChat = () => {
  return (
    <div className='DeclarationChat'>
      <div className='DeclarationChatCover'>
        <DeclarationSubBox />
        <DeclarationChatInfo />
      </div>
    </div>
  )
}

export default DeclarationChat