import React, { useState, useEffect } from 'react';
import './Login.css';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Logined } from '../redux/slice/login-slice';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [loginId, setLoginId] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const midBack = useSelector((state) => state.midBack);

  useEffect(() => {
    // 로그인 상태를 확인하여 리디렉션
    if (midBack?.log_data) {
      navigate('/');
    }
  }, [midBack, navigate]);

  const handleLogin = async () => {
    const requestBody = {
      signup_type: "general",
      login_id: loginId,
      pwd: password,
      latest_device: "string",
      latest_browser: "string"
    };

    try {
      const res = await axios.post('https://api.midtrc.com/auth/login/back', requestBody);
      const accessToken = res.data.access_token;
      dispatch(Logined({ ...res.data, access_token: accessToken }));
      alert('로그인 성공');
      navigate('/');
    } catch (error) {
      console.error('로그인 에러:', error);
      alert('로그인 실패: 아이디나 비밀번호를 다시 확인하세요.');
    }
  };

  return (
    <div className='Login'>
      <div className='LoginCover'>
        <h2>관리자 로그인</h2>
        <div className='LoginCoverTab'>
          <div className='LoginCoverTabLeft'>아이디</div>
          <div className='LoginCoverTabRight'>
            <input
              type='text'
              placeholder='아이디를 입력하세요'
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
            />
          </div>
        </div>
        <div className='LoginCoverTab'>
          <div className='LoginCoverTabLeft'>비밀번호</div>
          <div className='LoginCoverTabRight'>
            <input
              type='password'
              placeholder='비밀번호를 입력하세요'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className='LoginCoverBtn'>
          <button onClick={handleLogin}>로그인</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
