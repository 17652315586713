import React, { useState } from 'react'
import './SideGnb.css'
import { useNavigate } from 'react-router-dom'
import RightArrow2 from '../img/RightArrow2.png'
import AdminprofileIcon from '../img/side-bar/default-profile.png'
import DashboardIcon from '../img/side-bar/01-sell-list.png'
import MenuIcon from '../img/side-bar/02-all-list.png'

const SideGnb = () => {
  let navigate = useNavigate();
  let [isClicked, setIsClicked] = useState('대쉬보드');

  let [title00, setTitle00] = useState(true);
  let [title01, setTitle01] = useState(true);
  let [title02, setTitle02] = useState(true);
  let [title03, setTitle03] = useState(true);
  let [title04, setTitle04] = useState(true);
  let [title05, setTitle05] = useState(true);
  let [title06, setTitle06] = useState(true);
  let [title07, setTitle07] = useState(true);

  const SidebarButton = ({ url, icon, name, sideIcon }) => {
    switch (isClicked) {
      case name:
        return (
          <div className="side-bar-button" style={{ backgroundColor: "#EDF5FF" }} onClick={() => { setIsClicked(name); navigate(url) }}>
            <div className='side-bar-button-tab'>
              {/* <img className='side-bar-button-tab-img' src={icon} alt="icon" /> */}
              <div>
                {name}
              </div>
            </div>
            {
              sideIcon ?
                <img src={RightArrow2} alt="side-icon" style={{ width: "30px", height: "30px" }} />
                :
                <div />
            }
          </div>
        )
      default: //클릭 안됐을 때
        return (
          <div className="side-bar-button" onClick={() => { setIsClicked(name); navigate(url) }}>
            <div className='side-bar-button-tab'>
              {/* <img className='side-bar-button-tab-img' src={icon} alt="icon" /> */}
              <div>
                {name}
              </div>
            </div>
            {
              sideIcon ?
                <img src={RightArrow2} alt="side-icon" style={{ width: "30px", height: "30px" }} />
                :
                <div />
            }
          </div>
        )
    }
  }

  return (
    <div className='side-bar-border'>
      <SidebarButton url={'/ceo'} icon={AdminprofileIcon} name={'관리자'} sideIcon={false} />
      <SidebarButton url={'/'} icon={DashboardIcon} name={'대쉬보드'} sideIcon={false} />
      <div className='side-bar-tab' style={{ maxHeight: title00 ? "300px" : "20px" }}>
        <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px", cursor: "pointer", paddingRight: "2px" }} onClick={() => { setTitle00(!title00) }}>
          <div className='side-bar-inner'>
            메인페이지 관리
          </div>
          <img src={RightArrow2} alt='RightArrow2' style={{ width: "7px", height: "9px", transform: title00 ? "rotate(90deg)" : "rotate(0deg)" }} />
        </section>
        <SidebarButton url={'/logo-banner'} icon={MenuIcon} name={'로고/메인배너'} sideIcon={false} />
      
      </div>
      <div className='side-bar-tab' style={{ maxHeight: title01 ? "300px" : "20px" }}>
        <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px", cursor: "pointer", paddingRight: "2px" }} onClick={() => { setTitle01(!title01) }}>
          <div className='side-bar-inner'>
            회원 관리
          </div>
          <img src={RightArrow2} alt='RightArrow2' style={{ width: "7px", height: "9px", transform: title01 ? "rotate(90deg)" : "rotate(0deg)" }} />
        </section>
        <SidebarButton url={'/member-check'} icon={MenuIcon} name={'회원 조회 및 편집'} sideIcon={false} />
    
        <SidebarButton url={'/one-inquiry'} icon={MenuIcon} name={'1:1 문의 관리'} sideIcon={false} />
      </div>
      <div className='side-bar-tab' style={{ maxHeight: title02 ? "300px" : "20px" }}>
        <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px", cursor: "pointer", paddingRight: "2px" }} onClick={() => { setTitle02(!title02) }}>
          <div className='side-bar-inner'>
            초이스톡 관리
          </div>
          <img src={RightArrow2} alt='RightArrow2' style={{ width: "7px", height: "9px", transform: title02 ? "rotate(90deg)" : "rotate(0deg)" }} />
        </section>
        <SidebarButton url={'/choice-talk'} icon={MenuIcon} name={'초이스톡 현황 관리'} sideIcon={false} />
      </div>
      <div className='side-bar-tab' style={{ maxHeight: title03 ? "300px" : "20px" }}>
        <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px", cursor: "pointer", paddingRight: "2px" }} onClick={() => { setTitle03(!title03) }}>
          <div className='side-bar-inner'>
            커뮤니티 관리
          </div>
          <img src={RightArrow2} alt='RightArrow2' style={{ width: "7px", height: "9px", transform: title03 ? "rotate(90deg)" : "rotate(0deg)" }} />
        </section>
        <SidebarButton url={'/terrace'} icon={MenuIcon} name={'라운지'} sideIcon={false} />
      </div>
      <div className='side-bar-tab' style={{ maxHeight: title04 ? "300px" : "20px" }}>
        <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px", cursor: "pointer", paddingRight: "2px" }} onClick={() => { setTitle04(!title04) }}>
          <div className='side-bar-inner'>
            공고/이력서 관리
          </div>
          <img src={RightArrow2} alt='RightArrow2' style={{ width: "7px", height: "9px", transform: title04 ? "rotate(90deg)" : "rotate(0deg)" }} />
        </section>

        <SidebarButton url={'/announcement'} icon={MenuIcon} name={'공고 관리'} sideIcon={false} />
        <SidebarButton url={'/resume'} icon={MenuIcon} name={'이력서 관리'} sideIcon={false} />
      </div>

      <div className='side-bar-tab' style={{ maxHeight: title04 ? "300px" : "20px" }}>
        <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px", cursor: "pointer", paddingRight: "2px" }} onClick={() => { setTitle04(!title04) }}>
          <div className='side-bar-inner'>
            메시지 관리
          </div>
          <img src={RightArrow2} alt='RightArrow2' style={{ width: "7px", height: "9px", transform: title04 ? "rotate(90deg)" : "rotate(0deg)" }} />
        </section>
        <SidebarButton url={'/message'} icon={MenuIcon} name={'메시지함'} sideIcon={false} />
      </div>

      <div className='side-bar-tab' style={{ maxHeight: title05 ? "300px" : "20px" }}>
        <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px", cursor: "pointer", paddingRight: "2px" }} onClick={() => { setTitle05(!title05) }}>
          <div className='side-bar-inner'>
            관리자 게시판
          </div>
          <img src={RightArrow2} alt='RightArrow2' style={{ width: "7px", height: "9px", transform: title05 ? "rotate(90deg)" : "rotate(0deg)" }} />
        </section>
        <SidebarButton url={'/board-management'} icon={MenuIcon} name={'관리자 게시판 관리'} sideIcon={false} />
      </div>
      <div className='side-bar-tab' style={{ maxHeight: title06 ? "300px" : "20px" }}>
        <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px", cursor: "pointer", paddingRight: "2px" }} onClick={() => { setTitle06(!title06) }}>
          <div className='side-bar-inner'>
            일반 관리
          </div>
          <img src={RightArrow2} alt='RightArrow2' style={{ width: "7px", height: "9px", transform: title06 ? "rotate(90deg)" : "rotate(0deg)" }} />
        </section>
        <SidebarButton url={'/declaration'} icon={MenuIcon} name={'신고 내역'} sideIcon={false} />
        {/* <SidebarButton url={'/alliance'} icon={MenuIcon} name={'제휴/입점문의 입력폼'} sideIcon={false} /> */}
   
      </div>
      <div className='side-bar-tab' style={{ maxHeight: title07 ? "300px" : "20px" }}>
        <section style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "10px", cursor: "pointer", paddingRight: "2px" }} onClick={() => { setTitle07(!title07) }}>
          <div className='side-bar-inner'>
            환경 설정
          </div>
          <img src={RightArrow2} alt='RightArrow2' style={{ width: "7px", height: "9px", transform: title07 ? "rotate(90deg)" : "rotate(0deg)" }} />
        </section>
        <SidebarButton icon={MenuIcon} name={'기능 설정(준비중)'} sideIcon={false} />
        <SidebarButton icon={MenuIcon} name={'알림 설정(준비중)'} sideIcon={false} />
      </div>
    </div>
  )
}

export default SideGnb
