/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import arrow from "./../../@images/arrow-line-24.svg";
import AddPhoto from "./../../@images/add-photo.svg";
import CommunityEditor from "./community-editor"; // This can be used for content editing
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

let CommuBorder = styled.div`
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  padding-top: -2rem;
  color: black;
  box-sizing: border-box;
  padding-bottom: 7.2rem;
  background-color: #fff;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;

let CommuContentBorder = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: 2rem;
`;

let CommuTitle = styled.div`
  font-family: "Pretendard-Regular";
  font-weight: 600;
  font-size: 1.8rem;
  color: #171717;
  margin-top: 2rem;
  width: 100%;
`;

let EditorBorder = styled.div`
  width: 72.2rem;
  box-sizing: border-box;
`;

let ContentBorder = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 0.4rem;
  border-color: #e8e8e8;
  border-width: 0.1rem;
  border-style: solid;
  box-sizing: border-box;
  padding: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

let ContentTitle = styled.div`
  font-family: "Pretendard-Regular";
  font-weight: 500;
  font-size: 1.4rem;
  color: #171717;
  margin-bottom: 0.8rem;
`;

let NickDrop = styled.div`
  width: 19.2rem;
  height: 5.6rem;
  border-style: solid;
  border-color: #d2d2d2;
  border-width: 0.1rem;
  border-radius: 0.4rem;
  padding: 1.6rem;
  box-sizing: border-box;
  cursor: pointer;
  font-family: "Pretendard-Regular";
  font-weight: 500;
  font-size: 1.6rem;
  color: #6a6a6a;
  position: relative;
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  gap: 0.8rem;
`;

let FilterIconArrow = styled.img`
  width: 3.2rem;
  height: 3.2rem;
  object-fit: cover;
  position: absolute;
  top: 50%;
  right: 0.6rem;
  transform: translateY(-50%) rotate(${(props) => props.rotate}deg);
`;

let DropBorder = styled.div`
  position: absolute;
  width: 19.2rem;
  height: auto;
  max-height: 30rem;
  left: 0px;
  top: 5.8rem;
  z-index: 30;
  background-color: white;
  border-radius: 0.4rem;
  border-style: solid;
  border-color: #e8e8e8;
  border-width: 0.1rem;
  overflow: auto;
  text-align: left;
  &::-webkit-scrollbar {
    width: 12px; /* 너비 설정 */
  }
  &::-webkit-scrollbar-thumb {
    background: #cbced5;
    border-radius: 6px;
  }
`;

let DropDownItems = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: left;
  font-family: "Pretendard-Regular";
  font-weight: 400;
  font-size: 1.4rem;
  color: grey;
  box-sizing: border-box;
  padding: 0 2rem;
  height: 3.4rem;
  &:hover {
    color: black;
  }
`;

let ImageGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1.6rem;
  width: 100%;
  height: 12rem;
`;

let ImageBorder = styled.div`
  width: 100%;
  height: 100%;
  border-style: solid;
  border-color: #d2d2d2;
  border-width: 0.1rem;
  border-radius: 0.4rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

let ContentInput = styled.input`
  width: 100%;
  height: 5.6rem;
  box-sizing: border-box;
  border-style: solid;
  border-color: #d2d2d2;
  border-width: 0.1rem;
  border-radius: 0.4rem;
  outline: none;
  padding: 0 1.6rem;
  font-size: 1.6rem;
  font-family: "Pretendard-Regular";
`;

let AddDetailSubmitBorder = styled.div`
  width: 72.2rem;
  background-color: white;
  box-sizing: border-box;
  padding: 3.2rem;
  text-align: left;
  border-color: #e8e8e8;
  border-radius: 0.4rem;
  border-width: 0.1rem;
  border-style: solid;
`;

let ButtonBorder = styled.div`
  width: 15rem;
  box-sizing: border-box;
  position: relative;
  margin-top: 2rem;
  margin-left: auto;
`;

let SubmitButton = styled.button`
  position: relative;
  width: 100%;
  height: 4rem;
  background-color: #cd1b1b;
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  color: white;
  font-size: 1.4rem;
  font-weight: 500;
`;

const ManageBoardAdd = () => {
  const midBack = useSelector((state) => state.midBack);
  let [contentType, setContentType] = useState("notice");
  let [clickedDrop, setClickedDrop] = useState(false);
  let [nickName, setNickName] = useState("");
  let [contentsImage, setContentsImage] = useState([]);
  let [title, setTitle] = useState("");
  let [content, setContent] = useState("");
  let imageRef = useRef(null);
  let navigate = useNavigate();

  const nicknameHandler = (e) => {
    setNickName(e.target.value);
  };

  const dropDownSelectHandler = (type) => {
    setContentType(type);
    setClickedDrop(false);
  };

  const dropRef01 = useRef();
  const dropRef01Handler = (event) => {
    if (dropRef01.current && !dropRef01.current.contains(event.target)) {
      setClickedDrop(false);
    } else {
      setClickedDrop(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", dropRef01Handler);
    return () => {
      document.removeEventListener("mousedown", dropRef01Handler);
    };
  }, []);

  const delImageHandler = (e) => {
    if (e) {
      let instArray = contentsImage.filter((obj) => obj !== e);
      setContentsImage(instArray);
    }
  };

  const ImageHandler = ({ data }) => {
    return (
      <ImageBorder onClick={() => delImageHandler(data)}>
        <img
          src={data.inst}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          alt="profile"
        />
      </ImageBorder>
    );
  };

  let contentsImageHandler = (e) => {
    let instArray = [...contentsImage];
    instArray.push(e);
    setContentsImage(instArray);
  };

  const imageAddHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        contentsImageHandler({ server: file, inst: reader.result });
      };
      reader.readAsDataURL(file);
    }
    imageRef.current.value = null;
  };

  const titleHandler = (e) => {
    setTitle(e.target.value);
  };

  const submitHandler = () => {
    const requestBody = {
      client_id: midBack?.log_data?.id,
      notice_type: contentType,
      nickname: nickName,
      notice_title: title,
      data: [{ content }],
      categories: [], // Add categories as needed
    };

    const jsonRequestData = JSON.stringify(requestBody);
    const formData = new FormData();
    formData.append("req_body", jsonRequestData);

    if (contentsImage?.length > 0) {
      contentsImage.forEach((image) => {
        if (image.server) {
          formData.append("images", image.server);
        }
      });
    }

    axios
      .post(`https://api.midtrc.com/notice`, formData, {
        headers: {
          "access-token": midBack?.log_data?.access_token,
        },
      })
      .then((res) => {
        navigate(`/boardmanage-detail/${res.data.id}`);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <CommuBorder>
        <input
          type="file"
          onChange={imageAddHandler}
          style={{ display: "none" }}
          ref={imageRef}
        />
        <CommuContentBorder>
          <EditorBorder>
            <ContentBorder style={{ marginBottom: "0.6rem" }}>
              <ContentTitle>카테고리 설정</ContentTitle>
              <InputGroup>
                <NickDrop ref={dropRef01}>
                  <div>
                    {contentType === "notice"
                      ? "공지사항"
                      : contentType === "event"
                      ? "이벤트"
                      : "자주 묻는 질문"}
                  </div>
                  <FilterIconArrow
                    src={arrow}
                    rotate={clickedDrop ? 180 : 0}
                    alt="arrow"
                  />
                  {clickedDrop && (
                    <DropBorder>
                      <DropDownItems
                        onClick={() => dropDownSelectHandler("notice")}
                      >
                        공지사항
                      </DropDownItems>
                      <DropDownItems
                        onClick={() => dropDownSelectHandler("event")}
                      >
                        이벤트
                      </DropDownItems>
                      <DropDownItems
                        onClick={() => dropDownSelectHandler("question")}
                      >
                        자주 묻는 질문
                      </DropDownItems>
                    </DropBorder>
                  )}
                </NickDrop>
              </InputGroup>
            </ContentBorder>

            <AddDetailSubmitBorder style={{ marginTop: "0.8rem" }}>
              <ContentTitle>제목</ContentTitle>
              <ContentInput onChange={titleHandler} value={title} />
              <ContentTitle style={{ marginTop: "3.2rem" }}>내용</ContentTitle>
              <CommunityEditor data={setContent} />
              <ButtonBorder>
                <SubmitButton onClick={submitHandler}>게시글 작성</SubmitButton>
              </ButtonBorder>
            </AddDetailSubmitBorder>
          </EditorBorder>
        </CommuContentBorder>
      </CommuBorder>
    </div>
  );
};

export default ManageBoardAdd;
