import React, { useState } from 'react'
import './ChoiceModal.css'
import imgex2 from '../img/imgex2.png'
import closeButton from '../img/close-button.png'
import axios from 'axios'
import { useSelector } from 'react-redux'

const ChoiceModal = ({modalManager,data}) => {
  const midBack = useSelector((state) => state.midBack);
  let [content, setContent] = useState('')
  
  const submitMessage = () => {
    let reqeusetBody = {
      "group_name": data?.name,
      "user_name": data?.client_nickname,
      "content": content
    }
    
    axios.post(`https://api.midtrc.com/talk`,reqeusetBody,{
      headers: {
        'access-token': midBack?.log_data?.access_token,
      }
    }).then((res)=>{
      modalManager(null)
      window.location.reload();
    }).catch((error)=>{
      console.log(error)
    })
  }

  const contentsHandler = (e) => {
    setContent(e.target.value)
  }


  const ChoiceModalContent = () => {
    return(
        <div >
          <div style={{boxSizing: "border-box", padding: "20px 10px 10px 10px", display: "grid", gridTemplateColumns: "1fr", gap: "20px"}}>
            {/* <div>
              <div className='default-contents-border-title'>
                주소
              </div>
              <div className='default-contents-border-title'>
                기간
              </div>
              <div className='default-contents-border-title'>
                채팅창 수동 입력 텍스트 박스
              </div>
            </div> */}
            {/* <div>
              <div>
                <select className='default-contents-border-select'>
                  <option>서울</option>
                </select>
              </div>
              <div>
                <input readOnly={true} className="default-input-tag" value={'admin@admin.com'}/>
              </div>
              <div style={{marginTop:"10px"}}>
                <textarea className='default-contents-textarea' style={{height:"400px"}}></textarea>
              </div>
            </div> */}
            <textarea value={content} onChange={contentsHandler} className='default-contents-textarea' style={{height:"200px"}}></textarea>
          </div>
          <div className='default-contents-border-confirm'>
            <button className='default-contents-border-confirm-button'>취 소</button>
            <button onClick={()=>{submitMessage()}} className='default-contents-border-confirm-button' style={{background:"#183b5b",color:"#fff"}}>전 송</button>
          </div>
        </div>
    )
  }

  return (
    <div className="default-modal-border" style={{height: "100vh"}} onClick={(e)=>{e.stopPropagation(); modalManager(null)}}>
      <div className="members-detail-modal-border"  onClick={(e)=>{e.stopPropagation()}} style={{background:"#fff", width: "40%"}}>
          <div className="default-contents-border-top" style={{width: "100%", justifyContent: "space-between", minHeight: "40px", backgroundColor: "white", borderRadius: "4px 4px 0px 0px"}}>
              <div>
                  {data?.choice_title+' 수동 메시지'}
              </div>
              <img 
              onClick={()=>{modalManager(null)}}
              src={closeButton} alt="close-box" style={{width: "14px", height: "14px", cursor: "pointer"}}
              />
          </div>
          <div className="members-detail-modal-contents-border" style={{background:"#fff"}}>
              <div>
                {ChoiceModalContent()}
              </div>
          </div>
      </div>
    </div>
  ) 

  // return (
  //   // 전체 모달창
  //   <div className='ChoiceModal'>
  //     {/* 내용이 들어가는 모달창 */}
  //     <div className='ChoiceModalCover'>
  //       <div className='ChoiceModalCoverArea'>
  //         {/* 첫번째 탭 */}
  //         <select id='AreaName'>
  //           <option value="가게명/지역명">가게명/지역명</option>
  //         </select>
  //         {/* 두번째 탭 */}
  //         <div className='ChoiceModalTab'>
  //           <div className='ChoiceModalTabLeft'>기간</div>
  //           <div className='ChoiceModalTabRight'>
  //             <input type='text' placeholder='2023-12-20 15:18' />
  //           </div>
  //         </div>
  //         {/* 세번째 탭 */}
  //         <div className='ChoiceModalTab'>
  //           <div className='ChoiceModalTabLeft'>채팅창 수동 입력<br />텍스트 박스</div>
  //           <div className='ChoiceModalTabRight'>
  //             <div>
  //               <img src={imgex2} alt='imgex2' />
  //               <textarea></textarea>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='ChoiceModalBtn'>
  //           <button>전 송</button>
  //           <button>취 소</button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}

export default ChoiceModal