import React, { useState } from 'react'
import './Community.css'
import { useNavigate } from 'react-router-dom';

const Community = () => {
  let [clickedIndex, setClickedIndex] = useState('테라스 라운지');
  let navigate = useNavigate(null)

  // 공통 인덱스
  const MyPageIndexBorder = () => {
    
    const IndexButton = ({name, url}) => {
        return(
          <div>
            {
              clickedIndex === name?
              <div className="default-index-border-button" style={{backgroundColor: "#EDF5FF"}} onClick={()=> {setClickedIndex(name); navigate(url)}}>
                  {name}
              </div>
              :
              <div className="default-index-border-button" onClick={()=> {setClickedIndex(name); navigate(url)}}>
                  {name}
              </div>
            }
          </div>
        )
    }
    return(
        <div className="default-index-border" style={{paddingBottom: "5px"}}>
            <IndexButton name={'베스트픽'}/>
            <IndexButton name={'핫이슈'}/>
            <IndexButton name={'라운지-SNS형'}/>
            <IndexButton name={'라운지-자유게시판'}/>
        </div>
    )
  }

  // 스위치
  const ContentSwitcher = () => {
    switch(clickedIndex) {
      case "베스트픽" :
        return(
          <TerraceInfo />
        )
      case "일반 게시판" :
      return(
        <TerraceInfo />
      )
      case "라운지-SNS형" :
        return(
          <TerraceInfo />
        )
        case "라운지-자유게시판" :
          return(
            <TerraceInfo />
          )
      default : 
        return (
          <TerraceInfo />
      )
    }
  }

  // 테라스 라운지
  const TerraceInfo = () => {
    return (
      <div className='default-contents-border'>
      <div className='default-contents-border-top'>게시판 관리</div>
      <div style={{boxSizing: "border-box", padding: "10px 20px 10px 10px", display: "grid", gridTemplateColumns: "2fr 8fr", gap: "20px"}}>
        <div>
          <div className="default-contents-border-title" style={{marginBottom:"10px"}}>
            게시판 이름
          </div>

          <div className="default-contents-border-title">
            읽기/쓰기 권한
          </div>
        </div>
        <div>
          <div style={{position: "relative",marginBottom:"10px"}}>
            <input readOnly={true} className="default-input-line-tag" value={'테라스 라운지'}/>
            <div className="default-input-tag-edit">
                수정
            </div>
          </div>
 
          <div style={{display: "grid", gridTemplateColumns: "2.5fr 3.75fr 3.75fr", columnGap: "20px"}}>
            <div className="default-contents-border-title">
              읽기
            </div>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    모든회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div/>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    비회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    일반회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    사업자회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    여성회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-title">
              댓글 쓰기
            </div>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    모든회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div/>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    비회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    일반회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    사업자회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    여성회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-title">
              게시글 쓰기
            </div>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    모든회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div/>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    비회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    일반회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div/>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    사업자회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
            <div className="default-contents-border-check-box-border" style={{justifyContent:"right"}}>
                <div style={{color: "#a9a9a9", marginRight: "10px", fontWeight: "600"}}>
                    여성회원
                </div>
                <input className="default-contents-border-check-box" type="checkbox" />
            </div>
        </div>
        </div>
      </div>
      <div className='default-contents-border-confirm'>
        <button className='default-contents-border-confirm-button'>취 소</button>
        <button className='default-contents-border-confirm-button' style={{background:"#183b5b", color : "#fff"}}>저 장</button>
      </div>
      </div>
    )
  }










  return (
    <div className='Community'>
      <div>
        <MyPageIndexBorder />
      </div>
      <ContentSwitcher />
    </div>
  )


}

export default Community